import classNames from 'classnames';
import { ReactNode } from 'react';
import { Icon, IconType } from '../Icon';
import { StyledLink } from '../StyledLink';
type Props = {
  icon?: IconType;
  iconClassName?: string;
  left: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  tall?: boolean;
  className?: string;
  horizontalPadding?: boolean;
} & ({
  href?: string;
  hrefTarget?: '_blank';
} | {
  onClick?: () => void;
});
export const BaseRow = ({
  icon,
  iconClassName,
  left,
  center,
  right,
  className,
  horizontalPadding,
  ...props
}: Props) => {
  const href = 'href' in props && props.href;
  const onClick = 'onClick' in props && props.onClick;
  const styledIcon = icon && <div className={classNames('shrink-0 self-center', iconClassName ? '' : 'shrink-0 md:rounded-[0px] md:bg-transparent md:p-0')}>
      <Icon name={icon} className={classNames('stroke-subtle', iconClassName)} />
    </div>;
  const inner = (() => {
    if (center && right) {
      // all 3
      return <div className="flex items-center gap-3">
          {styledIcon}
          <div className="flex grow flex-wrap items-center gap-x-2 md:flex-nowrap">
            {/* {Left} content needs its basis to be set on a universal width so that it can align to other types of components */}
            <div className={classNames('shrink-0 basis-full md:basis-[22vw]', !icon && 'mr-5')}>
              {left}
            </div>
            <div className="min-w-0 grow basis-full break-all md:basis-auto">
              {center}
            </div>
          </div>
          <div className="flex shrink-0 justify-end">{right}</div>
        </div>;
    } else if (center) {
      // left and center only
      return <div className="flex items-center gap-3">
          {styledIcon}
          <div className="flex grow flex-wrap items-center gap-x-2 md:flex-nowrap">
            {/* {Left} content needs its basis to be set on a universal width so that it can align to other types of components */}
            <div className={classNames('shrink-0 basis-full md:basis-[22vw]', !icon && 'mr-5')}>
              {left}
            </div>
            <div className="min-w-0 grow basis-full break-all md:basis-auto">
              {center}
            </div>
          </div>
        </div>;
    } else if (right) {
      // left and right only
      return <div className="flex items-center gap-3">
          {styledIcon}
          <div className="grow self-stretch">{left}</div>
          <div className="shrink-0">{right}</div>
        </div>;
    } else {
      // left only
      return <div className="flex items-center gap-3">
          {styledIcon}
          <div className="grow self-stretch">{left}</div>
        </div>;
    }
  })();
  const innerWithPadding = <div className={classNames(horizontalPadding ? 'px-3' : 'px-0 md:px-[2px]', 'py-2.5 md:py-2')}>
      {inner}
    </div>;
  const contents = () => {
    if (href) {
      return <StyledLink href={href} target={props.hrefTarget} className="cursor-pointer" suppressFocus>
          {innerWithPadding}
        </StyledLink>;
    } else if (onClick) {
      return <a className="cursor-pointer" onClick={onClick}>
          {innerWithPadding}
        </a>;
    } else {
      return innerWithPadding;
    }
  };
  return <li className={classNames('border-main group block border-t first:border-none last:border-y', (href || onClick) && 'focus-within:bg-main-hover hover:bg-main-hover', className)} data-sentry-component="BaseRow" data-sentry-source-file="BaseRow.tsx">
      {contents()}
    </li>;
};