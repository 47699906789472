import { ROUTES, buildPath } from 'src/lib/routes';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { useState, useEffect } from 'react';
import { obligationDocumentList, ObligationDocumentListResponse } from 'src/build/operations';
export const ObligationDocuments = () => {
  const [data, setData] = useState<ObligationDocumentListResponse['data']['documents']>([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await obligationDocumentList();
      setData(response.data.data.documents);
    };
    fetchData();
  }, []);
  return <PageLayout headline="Obligation Documents" data-sentry-element="PageLayout" data-sentry-component="ObligationDocuments" data-sentry-source-file="obligation-documents.tsx">
      <Table data={data.map(item => ({
      id: item.document_name,
      ...item
    }))} columns={[{
      key: 'document_name',
      label: 'Document Name',
      render: item => <a href={buildPath(ROUTES.OBLIGATION_DOCUMENTS_DETAILS, {
        documentName: item.document_name
      })}>
                {item.document_name}
              </a>
    }, {
      key: 'document_partner',
      label: 'Document Partner'
    }, {
      key: 'document_link',
      label: 'Document Link',
      render: record => <a href={record.document_link} target="_blank" rel="noopener noreferrer">
                {record.document_link}
              </a>
    }]} data-sentry-element="Table" data-sentry-source-file="obligation-documents.tsx" />
    </PageLayout>;
};