import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { ProgramListResponseDataItem } from 'src/build/operations';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { formatBank } from 'src/lib/formatEnums';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<ProgramListResponseDataItem> & {
  showGroup?: boolean;
};
export const ProgramsTable = (props: Props) => {
  const {
    getRowProps,
    showGroup,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<ProgramListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      expand: 1,
      contents: program => ({
        text: program.name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    } as const, showGroup && {
      header: 'Group',
      contents: program => {
        return {
          text: program.group_name || program.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: program.group_id
          })
        };
      }
    }, {
      header: 'Bank',
      contents: program => ({
        text: formatBank(program.bank)
      })
    }, {
      header: 'Compliance role',
      contents: program => ({
        text: humanize(program.increase_compliance_role)
      })
    }, {
      header: 'Balances',
      align: 'right',
      contents: program => ({
        text: formatAmount(program.sum_balances, 'USD'),
        textWeight: 'bold',
        textColor: 'primary'
      })
    }];
  }, [showGroup]);
  const defaultRowProps = (datum: ProgramListResponseDataItem) => ({
    href: buildPath(ROUTES.PROGRAMS_DETAIL, {
      programId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<ProgramListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ProgramsTable" data-sentry-source-file="ProgramsTable.tsx" />;
};