import { Icon } from './Icon';
import { Body, Heading } from './Text';
import { Illustration, IllustrationType } from './Illustration';
export type InstructionProps = {
  heading?: string;
  text: string;
  details: string[];
  illustration: IllustrationType;
};
export const Instructions = ({
  heading = 'Instructions',
  text,
  details,
  illustration
}: InstructionProps) => <div className="space-y-4" data-sentry-component="Instructions" data-sentry-source-file="Instructions.tsx">
    <Heading contents={heading} data-sentry-element="Heading" data-sentry-source-file="Instructions.tsx" />
    <div className="max-w-[800px] space-y-5 sm:flex sm:space-x-5 sm:space-y-0">
      <div className="border-main bg-strong flex items-center justify-center rounded border p-4">
        <Illustration name={illustration} data-sentry-element="Illustration" data-sentry-source-file="Instructions.tsx" />
      </div>
      <div className="grow">
        <Body color="secondary" data-sentry-element="Body" data-sentry-source-file="Instructions.tsx">{text}</Body>
        <Body weight="medium" className="mt-4" data-sentry-element="Body" data-sentry-source-file="Instructions.tsx">
          Requirements
        </Body>
        <div className="mt-1">
          {details.map((detail, index) => <div key={index} className="relative mt-1 flex">
              <Icon name="tick" className="stroke-strong relative top-0.5 mr-2" />
              <Body color="secondary">{detail}</Body>
            </div>)}
        </div>
      </div>
    </div>
  </div>;