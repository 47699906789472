import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useBankProgramListInfinite } from 'src/hooks/reactQuery';
import { BankProgramsTable } from '../tables/BankProgramsTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
export const BankProgramListPage = () => {
  const bankprogramList = useBankProgramListInfinite({});
  return <PageLayout headline={'Bank programs'} data-sentry-element="PageLayout" data-sentry-component="BankProgramListPage" data-sentry-source-file="BankProgramListPage.tsx">
      <TableStateWrapper table={BankProgramsTable} style="primary" {...bankprogramList} emptyTitle="No bank programs" emptySubtitle="There are no bank programs to display" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="BankProgramListPage.tsx" />
    </PageLayout>;
};