import React from 'react';
import { useOperatorManualQueueItemListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { OperatorManualQueueItemListStatusItem } from 'src/build/operations';
export const CheckTransferPayeeNamesToProcess = () => {
  const queueItems = useOperatorManualQueueItemListInfinite({
    queue: ['check_transfer_payee_name'],
    status: [OperatorManualQueueItemListStatusItem.pending_actioning]
  });
  const data = queueItems.data?.pages.flatMap(page => page.data) ?? [];
  return <PageLayout headline="Check Transfer Mismatched payee names" data-sentry-element="PageLayout" data-sentry-component="CheckTransferPayeeNamesToProcess" data-sentry-source-file="check-transfer-payee-names-to-process.tsx">
      <div>
        <em>
          {data.length} pending review check{' '}
          {data.length === 1 ? 'name' : 'names'}
          {data.length === 0 && ' 🎉'}
        </em>
        <ItemList items={data.map(item => ({
        id: item.id,
        text: item.id,
        detail: item.created_at,
        rightDetail: item.record_id || undefined,
        href: item.link || undefined,
        rightText: 'Check name'
      }))} data-sentry-element="ItemList" data-sentry-source-file="check-transfer-payee-names-to-process.tsx" />
      </div>
    </PageLayout>;
};