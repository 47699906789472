import { useEntityGet, useConsumerOnboardingSurveyGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount } from 'src/build/operations';
import { formatAddress, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
const booleanQuestion = (label: string, value: boolean): ListItem => value ? {
  label,
  value: 'Yes',
  badgeColor: 'yellow'
} : {
  label,
  value: 'No'
};
const formatRange = (range: ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount): string => ({
  zero: '$0',
  zero_to_ten_thousand_dollars: '$0 - $10,000',
  ten_thousand_to_fifty_thousand_dollars: '$10,000 - $50,000',
  fifty_thousand_to_one_hundred_thousand_dollars: '$50,000 - $100,000',
  more_than_one_hundred_thousand_dollars: 'More than $100,000'
})[range];
export const ConsumerOnboardingSurveyDetailPage = () => {
  const {
    id
  } = useTypedParams(ROUTES.CONSUMER_ONBOARDING_SURVEYS_DETAILS);
  const {
    data: survey
  } = useConsumerOnboardingSurveyGet(id);
  const {
    data: entity
  } = useEntityGet(survey?.entity_id || '', {
    enabled: !!survey?.entity_id
  });
  const programRow = useProgramPropertyRow(survey?.program_id);
  const groupRow = useGroupPropertyRow(survey?.group_id);
  if (!survey) {
    return <Loading />;
  }
  const address = entity?.setup_submission_structure.natural_person?.submitted_address;
  return <PageLayout headline={`Onboarding Survey - ${survey.entity_name}`} data-sentry-element="PageLayout" data-sentry-component="ConsumerOnboardingSurveyDetailPage" data-sentry-source-file="ConsumerOnboardingSurveyDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<PropertyList title="Details" items={compact<ListItem>([{
      label: 'ID',
      value: survey.id
    }, survey.archived_at && {
      label: 'Status',
      value: 'Archived',
      badgeColor: 'red'
    }, groupRow, programRow, {
      label: 'Entity',
      value: survey.entity_name,
      href: buildPath(ROUTES.ENTITY, {
        entityId: survey.entity_id
      })
    }, {
      label: 'Occupation',
      value: survey.occupation
    }, {
      label: 'Location',
      value: address ? formatAddress(address) : '-'
    }, {
      label: 'Created At',
      value: formatISO8601Timestamp(survey.created_at, 'medium')
    }, survey.archived_at && {
      label: 'Archived At',
      value: formatISO8601Timestamp(survey.archived_at, 'medium')
    }])} layoutHorizontallyWhenPossible={false} />} left={<>
            <PropertyList title="Expected activity" items={[{
        label: 'Monthly ACH sent amount',
        value: formatRange(survey.monthly_ach_originated_amount)
      }, {
        label: 'Monthly ACH received amount',
        value: formatRange(survey.monthly_ach_received_amount)
      }, {
        label: 'Monthly wire sent amount',
        value: formatRange(survey.monthly_wire_originated_amount)
      }, {
        label: 'Monthly wire received amount',
        value: formatRange(survey.monthly_wire_received_amount)
      }]} layoutHorizontallyWhenPossible={false} />
            <PropertyList title="Deposit source" items={[booleanQuestion('Bank drafts', survey.deposit_source_is_bank_drafts), booleanQuestion('Employment income', survey.deposit_source_is_employment_income), booleanQuestion('Gifts', survey.deposit_source_is_gifts), booleanQuestion('Grants or scholarships', survey.deposit_source_is_grants_or_scholarships), booleanQuestion('Investment income', survey.deposit_source_is_investment_income), booleanQuestion('Loans', survey.deposit_source_is_loans), booleanQuestion('Retirement or pension income', survey.deposit_source_is_retirement_or_pension_income), booleanQuestion('Trust payments', survey.deposit_source_is_trust_payments), booleanQuestion('Other', survey.deposit_source_is_other)]} layoutHorizontallyWhenPossible={false} />
            <PropertyList title="Professional services provider / Politically exposed person" items={[booleanQuestion('Is the entity a professional services provider?', survey.is_professional_service_provider), booleanQuestion('Is the entity a politically exposed person?', survey.is_politically_exposed_or_senior_political_figure), booleanQuestion('Is the entity affiliated with a politically exposed person?', survey.is_affiliated_with_politically_exposed_person), {
        label: 'PEP explanation note',
        value: survey.politically_exposed_person_details || 'Not submitted.'
      }]} layoutHorizontallyWhenPossible={false} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="ConsumerOnboardingSurveyDetailPage.tsx" />
    </PageLayout>;
};