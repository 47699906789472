import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { FraudKnownBadSubmissionsSearchResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath } from 'src/lib/routes';
import { ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<FraudKnownBadSubmissionsSearchResponseDataItem>;
export const FraudKnownBadSubmissionsTable = (props: Props) => {
  const {
    ...rest
  } = props;
  const columns = useMemo<TableColumns<FraudKnownBadSubmissionsSearchResponseDataItem>>(() => {
    return [{
      header: 'Created',
      contents: known_bad_submission => ({
        text: formatISO8601Timestamp(known_bad_submission.created_at, 'month-day-year-hour-minute-second', 'UTC')
      })
    }, {
      header: 'Identifier',
      contents: known_bad_submission => ({
        text: known_bad_submission.id,
        href: buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: known_bad_submission.id
        })
      })
    }, {
      header: 'Group',
      expand: 1,
      contents: known_bad_submission => {
        const group = known_bad_submission.group;
        return {
          text: group?.name ?? '',
          href: group?.id ? buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: group.id
          }) : undefined
        };
      }
    }, {
      header: 'Entity',
      expand: 1,
      contents: known_bad_submission => {
        const entity = known_bad_submission.entity;
        return {
          text: entity?.name ?? '',
          href: entity?.id ? buildPath(ROUTES.ENTITY, {
            entityId: entity.id
          }) : undefined
        };
      }
    }];
  }, []);
  return <Table<FraudKnownBadSubmissionsSearchResponseDataItem> columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="FraudKnownBadSubmissionsTable" data-sentry-source-file="FraudKnownBadSubmissionsTable.tsx" />;
};