import React, { useCallback, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useOperatorConfirmOneTimePasswordPost, useOperatorOneTimePasswordProvisioningURIGet } from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';
import { Button } from './deprecated/Button';
const LINK_CLASSES = 'font-medium text-info hover:underline';
export const ConfirmOneTimePassword = () => {
  const {
    data: oneTimePasswordProvisioningURI
  } = useOperatorOneTimePasswordProvisioningURIGet({});
  const confirmOTPPost = useOperatorConfirmOneTimePasswordPost();
  const [otp, setOtp] = useState('');
  const submit = useCallback(() => {
    confirmOTPPost.mutate([{
      one_time_password: otp.replace(/[^0-9]/g, '')
    }]);
  }, [otp, confirmOTPPost]);
  return <div className="flex h-screen w-full items-center justify-center" id="confirmOneTimePassword" data-sentry-component="ConfirmOneTimePassword" data-sentry-source-file="confirm-one-time-password.tsx">
      <div className="w-full max-w-md space-y-4 text-center">
        <h1 className="text-xl">Confirm One Time Password</h1>
        <p>Create an account to continue</p>

        <p className="text-base">
          Increase uses time-based one-time passwords. You can generate these
          using apps like{' '}
          <a className={LINK_CLASSES} href="https://duo.com/">
            Duo
          </a>
          ,{' '}
          <a className={LINK_CLASSES} href="https://1password.com/">
            1Password
          </a>
          , and{' '}
          <a className={LINK_CLASSES} href="https://apps.apple.com/us/app/google-authenticator/id388497605">
            Google Authenticator
          </a>
          . Once you've downloaded one of these apps, you can scan the QR code
          below to generate one-time passwords. If you need help,{' '}
          <a className={LINK_CLASSES} href="mailto:support@increase.com">
            email us
          </a>
          !
        </p>
        {oneTimePasswordProvisioningURI && <div className="flex flex-row justify-center">
            <QRCodeSVG value={oneTimePasswordProvisioningURI.one_time_password_provisioning_uri} bgColor={'white'} />
          </div>}
        <br />
        <br />
        <div>
          <TextInput data-testid="confirmOneTimePassword.oneTimePassword" id="confirmOneTimePassword.oneTimePassword" name="one_time_password" value={otp} onChange={s => setOtp(s)} placeholder="One time password" data-sentry-element="TextInput" data-sentry-source-file="confirm-one-time-password.tsx" />
        </div>
        {confirmOTPPost.error && <div className="text-sm text-danger">
            {confirmOTPPost.error.response?.data.message}
          </div>}
        <Button data-testid="confirmOneTimePassword.confirmButton" id="confirmOneTimePassword.confirmButton" onClick={() => submit()} data-sentry-element="Button" data-sentry-source-file="confirm-one-time-password.tsx">
          Confirm
        </Button>
      </div>
    </div>;
};