import { isString } from 'lodash';
import { ReactNode } from 'react';
import { Box } from '../Box';
import { Icon, IconType } from '../Icon';
import { Body, Caption } from '../Text';
import { BaseRow } from './BaseRow';
type Props = {
  className?: string;
  title: string | ReactNode;
  titleStyle?: 'mono' | 'normal';
  titleClassName?: string;
  subtitle?: string | ReactNode;
  subtitleStyle?: 'mono' | 'normal' | 'caption';
  subtitleClassName?: string;
  detailRight?: string | ReactNode;
  subDetailRight?: string | ReactNode;
  icon?: IconType;
  iconClassName?: string;
  showCarat?: boolean;
} & ({
  href?: string;
} | {
  onClick?: () => void;
} | {
  rightButton?: ReactNode;
});
export const ListRow = (props: Props) => {
  const {
    className,
    icon,
    iconClassName,
    title,
    titleClassName,
    titleStyle = 'normal',
    subtitle,
    detailRight,
    subDetailRight,
    subtitleStyle = 'normal',
    subtitleClassName
  } = props;
  const href = 'href' in props ? props.href : undefined;
  const onClick = 'onClick' in props ? props.onClick : undefined;
  const rightButton = 'rightButton' in props ? props.rightButton : undefined;
  return <BaseRow href={href} onClick={onClick} icon={icon} iconClassName={iconClassName} tall={!!subtitle} className={className} left={<Box gap="0" justifyCenter>
          {isString(title) ? <Body color="primary" contents={title} className={titleClassName} weight={titleStyle === 'mono' ? 'mono' : 'medium'} /> : title}
          {subtitle && (isString(subtitle) ? subtitleStyle === 'caption' ? <Caption color="secondary" contents={subtitle} className={subtitleClassName} weight="normal" /> : <Body color="secondary" contents={subtitle} className={subtitleClassName} weight={subtitleStyle === 'mono' ? 'mono' : 'normal'} /> : subtitle)}
        </Box>} right={<div className="flex items-center gap-2">
          <div className="text-right">
            {detailRight && isString(detailRight) ? <div className="shrink-0">
                <Body contents={detailRight} color="primary" className="text-right" weight="bold" />
              </div> : detailRight}
            {subDetailRight && (isString(subDetailRight) ? <div className="shrink-0">
                  <Body color="secondary" contents={subDetailRight} className="text-subtle text-right" />
                </div> : subDetailRight)}
          </div>
          {(href || props.showCarat) && <Icon name="caret_right" className="stroke-subtle" />}
          {rightButton}
        </div>} data-sentry-element="BaseRow" data-sentry-component="ListRow" data-sentry-source-file="ListRow.tsx" />;
};