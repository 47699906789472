import { useGroupGateDelete, useGroupGatePost, useGroupGatesList, useGroupGet, useOperatorGet } from 'src/hooks/reactQuery';
import { GroupGatePostParametersFeature } from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { OperatorNotes } from './operator-notes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { sortBy } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const Gates = () => {
  const {
    groupID
  } = useTypedParams(ROUTES.GROUP_GATES);
  const {
    data: group
  } = useGroupGet(groupID);
  const {
    data: gates,
    refetch
  } = useGroupGatesList(groupID);
  const {
    data: operator
  } = useOperatorGet({});
  const categoricals = useCategoricals();
  const groupGatePost = useGroupGatePost();
  const groupGateDelete = useGroupGateDelete();
  if (!group || !categoricals || !operator || !gates) {
    return <div>Loading...</div>;
  }
  const name = group.name;
  const title = name ? `${name} (${group.friendly_nickname})` : group.friendly_nickname;
  const toggleGate = async (feature: GroupGatePostParametersFeature, currentlyActive: boolean): Promise<unknown> => {
    if (currentlyActive) {
      await groupGateDelete.mutateAsync([groupID, feature]);
    } else {
      await groupGatePost.mutateAsync([groupID, {
        feature
      }]);
    }
    return await refetch();
  };
  return <PageLayout headline={`Gates for ${title}`} data-sentry-element="PageLayout" data-sentry-component="Gates" data-sentry-source-file="gates.tsx">
      <>
        <Section header="Gates" data-sentry-element="Section" data-sentry-source-file="gates.tsx">
          <PropertyList items={sortBy(categoricals.gate, g => g.value).map(gate => {
          const active = gates.gates.includes(gate.value);
          return {
            label: gate.value,
            value: active,
            valueLabel: gate.name,
            onToggle: () => toggleGate(gate.value, active)
          };
        })} data-sentry-element="PropertyList" data-sentry-source-file="gates.tsx" />
        </Section>

        <Section header="Notes" data-sentry-element="Section" data-sentry-source-file="gates.tsx">
          <OperatorNotes modelId={group.id} data-sentry-element="OperatorNotes" data-sentry-source-file="gates.tsx" />
        </Section>
      </>
    </PageLayout>;
};