import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useRampBusinessAccountOnboardingSurveyListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { RampBusinessAccountOnboardingSurveyTable } from '../tables/RampBusinessAccountOnboardingSurveyTable';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeAccountFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const RampBusinessAccountOnboardingSurveyListPage = () => {
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST);
  const [accounts, setAccounts] = useSearchParamsState(ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST, 'accounts');
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const listResult = useRampBusinessAccountOnboardingSurveyListInfinite({
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    accounts: accounts.length > 0 ? accounts : undefined
  });
  const filters: TableFilter[] = useMemo(() => [makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created'), makeAccountFilter(accounts, setAccounts)], [startAt, endAt, setTimeRange, accounts, setAccounts]);
  return <PageLayout headline={'Ramp Business Account Onboarding Surveys'} data-sentry-element="PageLayout" data-sentry-component="RampBusinessAccountOnboardingSurveyListPage" data-sentry-source-file="RampBusinessAccountOnboardingSurveyListPage.tsx">
      <TableStateWrapper table={RampBusinessAccountOnboardingSurveyTable} style="primary" {...listResult} emptyTitle="No Surveys" emptySubtitle="There are no surveys to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="RampBusinessAccountOnboardingSurveyListPage.tsx" />
    </PageLayout>;
};