import React from 'react';
import { useManagementInformationSystemRiskMetricsList } from 'src/hooks/reactQuery';
import { ProgramRiskMetrics } from './program-risk-metrics';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
export const CompliancePage = () => {
  const [atTime] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'at_time');
  const [period] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'lookback_period');
  const {
    data: riskMetricsData
  } = useManagementInformationSystemRiskMetricsList({
    at_time: atTime,
    period: period
  });
  return <>
      {riskMetricsData ? <div>
          <ProgramRiskMetrics metrics={riskMetricsData.data.metrics} period={period} />
        </div> : <h3>Loading</h3>}
    </>;
};