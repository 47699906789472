import React, { useMemo } from 'react';
import { useUserListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { UsersTable } from '../tables/UsersTable';
import { makeKeywordFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const UserListPage = () => {
  const [keyword, setKeyword] = useSearchParamsState(ROUTES.USERS_LIST, 'keyword');
  const listResult = useUserListInfinite({
    keyword: keyword === null ? undefined : keyword
  });
  const filters = useMemo(() => [makeKeywordFilter(keyword, setKeyword)], [keyword, setKeyword]);
  return <PageLayout headline="Users" data-sentry-element="PageLayout" data-sentry-component="UserListPage" data-sentry-source-file="UserListPage.tsx">
      <TableStateWrapper table={UsersTable} {...listResult} style="primary" emptyTitle="No Users" emptySubtitle="There are no Users to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="UserListPage.tsx" />
    </PageLayout>;
};