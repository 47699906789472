import React, { useMemo } from 'react';
import { useOAuthConnectionListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { OAuthConnectionsTable } from '../tables/OAuthConnectionsTable';
import { makeGroupFilter, makeOAuthApplicationFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const OAuthConnectionListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.OAUTH_CONNECTION_LIST, 'group');
  const [connectedGroupFilter, setConnectedGroupFilter] = useSearchParamsState(ROUTES.OAUTH_CONNECTION_LIST, 'connected_group');
  const [oauthApplicationFilter, setOAuthApplicationFilter] = useSearchParamsState(ROUTES.OAUTH_CONNECTION_LIST, 'oauth_application');
  const listResult = useOAuthConnectionListInfinite({
    groups: groupFilter.length === 0 ? undefined : groupFilter,
    connected_groups: connectedGroupFilter.length === 0 ? undefined : connectedGroupFilter
  });
  const filters = useMemo(() => [makeGroupFilter(groupFilter, setGroupFilter), makeGroupFilter(connectedGroupFilter, setConnectedGroupFilter), makeOAuthApplicationFilter(oauthApplicationFilter, setOAuthApplicationFilter)], [connectedGroupFilter, groupFilter, oauthApplicationFilter, setConnectedGroupFilter, setGroupFilter, setOAuthApplicationFilter]);
  return <PageLayout headline="OAuth Connections" data-sentry-element="PageLayout" data-sentry-component="OAuthConnectionListPage" data-sentry-source-file="OAuthConnectListPage.tsx">
      <TableStateWrapper table={OAuthConnectionsTable} {...listResult} style="primary" emptyTitle="No OAuth Connections" emptySubtitle="There are no OAuth Connections to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="OAuthConnectListPage.tsx" />
    </PageLayout>;
};