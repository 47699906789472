import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { usePlatformVendorListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeGroupFilter, makeKeywordFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { PlatformVendorsTable } from '../tables/PlatformVendorsTable';
export const VendorListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.PLATFORM_VENDORS, 'group');
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(ROUTES.PLATFORM_VENDORS, 'keyword');
  const filters: TableFilter[] = useMemo(() => compact([makeGroupFilter(groupFilter, setGroupFilter), makeKeywordFilter(keywordFilter, setKeywordFilter)]), [groupFilter, keywordFilter, setGroupFilter, setKeywordFilter]);
  const listResult = usePlatformVendorListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    keyword: keywordFilter && keywordFilter.length > 0 ? keywordFilter : undefined
  });
  return <PageLayout headline={'Vendors'} data-sentry-element="PageLayout" data-sentry-component="VendorListPage" data-sentry-source-file="PlatformVendorListPage.tsx">
      <TableStateWrapper table={PlatformVendorsTable} style="primary" {...listResult} emptyTitle="No Vendors" emptySubtitle="There are no vendors to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="PlatformVendorListPage.tsx" />
    </PageLayout>;
};