import classNames from 'classnames';
import { ReactNode } from 'react';
const Styles = {
  'large-heading': 'text-[23px] leading-[28px] md:text-[27px] md:leading-[32px] font-interphases font-[640] tracking-[-.008em]',
  'medium-heading': 'text-[19px] leading-[24px] md:text-[19px] md:leading-[24px] font-interphases font-[640] tracking-[-.008em]',
  'small-heading': 'text-[17px] leading-[20px] font-interphases font-[640] tracking-[-.008em]',
  'large-subheading': 'text-[20px] leading-[24px] md:text-[22px] md:leading-[28px] font-interphases font-[520]',
  'medium-subheading': 'text-[18px] leading-[24px] md:text-[20px] md:leading-[24px] font-interphases font-[520]',
  'small-subheading': 'text-[16px] leading-[20px] font-interphases font-[520]',
  body: 'text-[14px] leading-[20px] font-interphases font-[440] tracking-[.014em]',
  'body-medium': 'text-[14px] leading-[20px] font-interphases font-[520] tracking-[.014em]',
  'body-bold': 'text-[14px] leading-[20px] font-interphases font-[600] tracking-[.014em]',
  'body-mono': 'text-[13px] leading-[20px] font-inputmono font-[400] [font-feature-settings: "ss01", "ss02", "ss12"]',
  caption: 'text-[12px] leading-[16px] font-interphases font-[440] tracking-[.01em]',
  'caption-medium': 'text-[12px] leading-[16px] font-interphases font-[520] tracking-[.01em]',
  'caption-bold': 'text-[12px] leading-[16px] font-interphases font-[600] tracking-[.01em]',
  'caption-mono': 'text-[11px] leading-[16px] font-inputmono font-[400] [font-feature-settings: "ss01", "ss02", "ss12"]',
  // text colors
  'color-inherit': '',
  'color-emphasis': 'text-strong',
  'color-primary': 'text-main',
  'color-secondary': 'text-subtle',
  'color-offset': 'text-inverse',
  'color-disabled': 'text-disabled',
  'color-red': 'text-danger',
  'color-green': 'text-success',
  'color-blue': 'text-info',
  'color-yellow': 'text-warning',
  'color-purple': 'text-assistive'
};
type Contents = {
  contents: string | number;
} | {
  children: ReactNode;
};
const Base = (props: {
  className?: string;
  style?: object;
} & Contents) => <div className={classNames('m-0 antialiased md:antialiased', props.className)} style={props.style} data-sentry-component="Base" data-sentry-source-file="index.tsx">
    {'contents' in props ? props.contents : props.children}
  </div>;
export type HeadingStylingProps = {
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'disabled' | 'offset' | 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'inherit';
  className?: string;
};
export const Heading = ({
  className,
  size = 'medium',
  color = 'primary',
  ...rest
}: HeadingStylingProps & Contents) => <Base {...rest} className={classNames(size === 'large' && Styles['large-heading'], size === 'medium' && Styles['medium-heading'], size === 'small' && Styles['small-heading'], color === 'primary' && Styles['color-emphasis'], color === 'secondary' && Styles['color-secondary'], color === 'disabled' && Styles['color-disabled'], color === 'offset' && Styles['color-offset'], color === 'red' && Styles['color-red'], color === 'green' && Styles['color-green'], color === 'blue' && Styles['color-blue'], color === 'yellow' && Styles['color-yellow'], color === 'purple' && Styles['color-purple'], color === 'inherit' && Styles['color-inherit'], className)} data-sentry-element="Base" data-sentry-component="Heading" data-sentry-source-file="index.tsx" />;
export type SubheadingStylingProps = {
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'disabled' | 'offset' | 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'inherit';
  className?: string;
};
export const Subheading = ({
  className,
  size = 'medium',
  color = 'primary',
  ...rest
}: SubheadingStylingProps & Contents) => <Base {...rest} className={classNames(size === 'large' && Styles['large-subheading'], size === 'medium' && Styles['medium-subheading'], size === 'small' && Styles['small-subheading'], color === 'primary' && Styles['color-emphasis'], color === 'secondary' && Styles['color-secondary'], color === 'disabled' && Styles['color-disabled'], color === 'offset' && Styles['color-offset'], color === 'red' && Styles['color-red'], color === 'green' && Styles['color-green'], color === 'blue' && Styles['color-blue'], color === 'yellow' && Styles['color-yellow'], color === 'purple' && Styles['color-purple'], color === 'inherit' && Styles['color-inherit'], className)} data-sentry-element="Base" data-sentry-component="Subheading" data-sentry-source-file="index.tsx" />;
export type BodyStylingProps = {
  weight?: 'normal' | 'medium' | 'bold' | 'mono';
  color?: 'emphasis' | 'primary' | 'secondary' | 'disabled' | 'offset' | 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'inherit';
  className?: string;
  style?: object;
};
export const Body = ({
  className,
  weight = 'normal',
  color = 'primary',
  ...rest
}: BodyStylingProps & Contents) => <Base {...rest} className={classNames(weight === 'normal' && Styles['body'], weight === 'medium' && Styles['body-medium'], weight === 'bold' && Styles['body-bold'], weight === 'mono' && Styles['body-mono'], color === 'emphasis' && Styles['color-emphasis'], color === 'primary' && Styles['color-primary'], color === 'secondary' && Styles['color-secondary'], color === 'disabled' && Styles['color-disabled'], color === 'offset' && Styles['color-offset'], color === 'red' && Styles['color-red'], color === 'green' && Styles['color-green'], color === 'blue' && Styles['color-blue'], color === 'yellow' && Styles['color-yellow'], color === 'purple' && Styles['color-purple'], color === 'inherit' && Styles['color-inherit'], className)} data-sentry-element="Base" data-sentry-component="Body" data-sentry-source-file="index.tsx" />;
export type CaptionStylingProps = {
  weight?: 'normal' | 'medium' | 'bold' | 'mono';
  color?: 'emphasis' | 'primary' | 'secondary' | 'disabled' | 'offset' | 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'inherit';
  className?: string;
};
export const Caption = ({
  className,
  weight = 'normal',
  color = 'primary',
  ...rest
}: CaptionStylingProps & Contents) => <Base {...rest} className={classNames(weight === 'normal' && Styles['caption'], weight === 'medium' && Styles['caption-medium'], weight === 'bold' && Styles['caption-bold'], weight === 'mono' && Styles['caption-mono'], color === 'emphasis' && Styles['color-emphasis'], color === 'primary' && Styles['color-primary'], color === 'secondary' && Styles['color-secondary'], color === 'disabled' && Styles['color-disabled'], color === 'offset' && Styles['color-offset'], color === 'red' && Styles['color-red'], color === 'green' && Styles['color-green'], color === 'blue' && Styles['color-blue'], color === 'yellow' && Styles['color-yellow'], color === 'purple' && Styles['color-purple'], color === 'inherit' && Styles['color-inherit'], className)} data-sentry-element="Base" data-sentry-component="Caption" data-sentry-source-file="index.tsx" />;