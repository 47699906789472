import { BadgeColor } from '@increase/shared/components/Badge';
import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { EntityAddressVerificationResultListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { compact } from 'lodash';
type Props = PrestyledTableProps<EntityAddressVerificationResultListResponseDataItem>;
const badgeColors: Record<EntityAddressVerificationResultListResponseDataItem['outcome'], BadgeColor> = {
  valid_address: 'green',
  valid_address_manual_override: 'green',
  invalid_address: 'yellow',
  mailbox_address: 'red',
  registered_agent_address: 'red'
};
export const EntityAddressVerificationTable = (props: Props) => {
  const columns = useMemo<TableColumns<EntityAddressVerificationResultListResponseDataItem>>(() => [{
    header: 'Outcome',
    contents: role => ({
      text: humanize(role.outcome),
      badgeColor: badgeColors[role.outcome]
    })
  }, {
    header: 'Clean Address',
    expand: 1,
    contents: review => ({
      text: compact([review.clean_line1, review.clean_line2, review.clean_city, review.clean_state, review.clean_zip]).join(', ')
    })
  }, {
    header: 'Original Address',
    expand: 1,
    contents: review => ({
      text: compact([review.original_line1, review.original_line2, review.original_city, review.original_state, review.original_zip]).join(', ')
    })
  }, {
    header: 'Created At',
    contents: review => ({
      text: formatISO8601Timestamp(review.created_at, 'full')
    })
  }], []);
  const getRowProps = () => ({
    className: 'hover:bg-main-hover transition-all group'
  });
  return <Table<EntityAddressVerificationResultListResponseDataItem> columns={columns} getRowProps={getRowProps} {...props} data-sentry-element="Table" data-sentry-component="EntityAddressVerificationTable" data-sentry-source-file="EntityAddressVerificationTable.tsx" />;
};