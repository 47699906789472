import { useFedwireInboundRequestForReversalsGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { FedwireRequestForReversalPropertyList } from '../property-lists/FedwireRequestForReversalPropertyList';
export const FedwireInboundRequestForReversalDetailPage = () => {
  const {
    requestForReversalId
  } = useTypedParams(ROUTES.FEDWIRE_INBOUND_REQUEST_FOR_REVERSALS_DETAIL);
  const {
    data: requestForReversal
  } = useFedwireInboundRequestForReversalsGet(requestForReversalId);
  if (!requestForReversal) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline={'Fedwire Request for Reversal'} data-sentry-element="PageLayout" data-sentry-component="FedwireInboundRequestForReversalDetailPage" data-sentry-source-file="FedwireInboundRequestForReversalDetailPage.tsx">
      <FedwireRequestForReversalPropertyList fedwireRequestForReversal={requestForReversal} data-sentry-element="FedwireRequestForReversalPropertyList" data-sentry-source-file="FedwireInboundRequestForReversalDetailPage.tsx" />
      <OperatorNotes modelId={requestForReversal.id} data-sentry-element="OperatorNotes" data-sentry-source-file="FedwireInboundRequestForReversalDetailPage.tsx" />
    </PageLayout>;
};