import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useEntityClusterListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeEnumFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { EntityClusterListCategoriesItem, EntityClusterListStatusesItem } from 'src/build/operations';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { EntityClustersTable } from '../tables/EntityClustersTable';
export const EntityClusterListPage = () => {
  const [statusFilter, setStatusFilter] = useSearchParamsState(ROUTES.ENTITY_CLUSTERS_LIST, 'statuses');
  const [categoryFilter, setCategoryFilter] = useSearchParamsState(ROUTES.ENTITY_CLUSTERS_LIST, 'categories');
  const [countOrGreater, setCountOrGreater] = useSearchParamsState(ROUTES.ENTITY_CLUSTERS_LIST, 'count_or_greater');
  const filters: TableFilter[] = useMemo(() => compact([makeStatusFilter(statusFilter, setStatusFilter, EntityClusterListStatusesItem), makeEnumFilter(categoryFilter, setCategoryFilter, 'Category', EntityClusterListCategoriesItem, 'info'), {
    type: 'string',
    label: 'Count entities or greater',
    id: 'count',
    icon: 'file_search',
    value: countOrGreater == null ? '' : '' + countOrGreater,
    onChange: value => setCountOrGreater(value === '' ? null : +value)
  }]), [setStatusFilter, statusFilter, setCategoryFilter, categoryFilter, countOrGreater, setCountOrGreater]);
  const listResult = useEntityClusterListInfinite({
    statuses: statusFilter.length > 0 ? statusFilter : undefined,
    categories: categoryFilter.length > 0 ? categoryFilter : undefined,
    count_or_greater: countOrGreater != null ? countOrGreater : undefined
  });
  return <PageLayout headline={'Entity Clusters'} data-sentry-element="PageLayout" data-sentry-component="EntityClusterListPage" data-sentry-source-file="EntityClusterListPage.tsx">
      <TableStateWrapper table={EntityClustersTable} style="primary" {...listResult} emptyTitle="No Clusters" emptySubtitle="There are no clusters to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="EntityClusterListPage.tsx" />
    </PageLayout>;
};