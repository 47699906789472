import { useDocumentRequestCancel, useLateReturnRequestGet, useOperatorGet, useProofOfAuthorizationRequestSubmissionPatch } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { LateReturnRequestPropertyList } from '../property-lists/LateReturnRequestPropertyList';
import { LateReturnRequestSubmissionPropertyList } from '../property-lists/LateReturnRequestSubmissionPropertyList';
import { Box } from '@increase/shared/components/Box';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { LateReturnRequestGetResponseAchTransfersItem } from 'src/build/operations';
import { useMemo } from 'react';
import { Table, TableColumns } from '@increase/shared/components/Table';
import { Heading } from '@increase/shared/components/Text';
import { Button } from '@increase/shared/components/Button';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { OperatorNotes } from '../operator-notes';
import { AssociatedManualTasks } from '../associated-manual-tasks';
export const LateReturnRequestDetailPage = () => {
  const {
    lateReturnRequestId
  } = useTypedParams(ROUTES.LATE_RETURN_REQUESTS_DETAIL);
  const {
    data: lateReturnRequest
  } = useLateReturnRequestGet(lateReturnRequestId);
  const actionSubmission = useProofOfAuthorizationRequestSubmissionPatch();
  const cancelRequest = useDocumentRequestCancel();
  const {
    data: operator
  } = useOperatorGet({});
  const achTransfersTableColumns = useMemo<TableColumns<LateReturnRequestGetResponseAchTransfersItem>>(() => {
    return [{
      header: 'Identifier',
      expand: 1,
      contents: transfer => ({
        text: transfer.id,
        href: buildPath(ROUTES.TRANSFER_DETAIL, {
          transferID: transfer.id
        })
      })
    }, {
      header: 'Description',
      expand: 1,
      contents: transfer => ({
        text: transfer.description
      })
    }, {
      header: 'Amount',
      contents: transfer => ({
        text: formatAmount(transfer.amount, 'USD')
      })
    }];
  }, []);
  if (!lateReturnRequest) {
    return <h3>Loading</h3>;
  }
  const documentRequestIsCanceledOrAccepted = ['canceled', 'accepted', 'provisionally_accepted'].includes(lateReturnRequest.document_request.status);
  return <PageLayout headline={'Late Return Request'} action={!documentRequestIsCanceledOrAccepted && <Button disabled={!operator || operator.role !== 'internal'} style="warning" text="Cancel Request" onClick={async () => {
    await cancelRequest.mutateAsync([lateReturnRequest.document_request.id]);
    window.location.reload();
  }} />} data-sentry-element="PageLayout" data-sentry-component="LateReturnRequestDetailPage" data-sentry-source-file="LateReturnRequestDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<>
            <LateReturnRequestPropertyList lateReturnRequest={lateReturnRequest} layoutHorizontallyWhenPossible={false} title="Summary" />
            <AssociatedManualTasks objectId={lateReturnRequest.id} />
          </>} left={<>
            <Box>
              <Heading contents="ACH Transfers" />
              <Table<LateReturnRequestGetResponseAchTransfersItem> columns={achTransfersTableColumns} data={lateReturnRequest.ach_transfers} />
            </Box>
            <Box>
              {lateReturnRequest.submissions.map((submission, submissionIdx) => {
          return <LateReturnRequestSubmissionPropertyList key={submission.id} lateReturnRequestSubmission={submission} action={submission.status === 'pending_review' && !documentRequestIsCanceledOrAccepted && <>
                            <Button size="small" style="primary" onClick={() => actionSubmission.mutateAsync([submission.id, {
              approved: true
            }])} text="Approve" />
                            <Button size="small" style="secondary" onClick={() => actionSubmission.mutateAsync([submission.id, {
              approved: false
            }])} text="Reject" />
                          </>} title={`Submissions ${submissionIdx + 1} - ${formatISO8601Timestamp(submission.created_at, 'month-day-hour-minute')}`} />;
        })}
            </Box>
            <OperatorNotes modelId={lateReturnRequest.id} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="LateReturnRequestDetailPage.tsx" />
    </PageLayout>;
};