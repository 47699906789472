import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
export const OnboardingSurveysIndexPage = () => {
  return <PageLayout headline={'User onboarding surveys'} data-sentry-element="PageLayout" data-sentry-component="OnboardingSurveysIndexPage" data-sentry-source-file="onboarding-survey.tsx">
      <PropertyList items={[{
      label: 'Ramp Business Account',
      value: 'view',
      href: buildPath(ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST, {}),
      tooltip: 'This will merge with `Other Commercial Users` shortly.'
    }, {
      label: 'Other Commercial Users',
      value: 'view',
      href: buildPath(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST, {})
    }, {
      label: 'Consumer Users',
      value: 'view',
      href: buildPath(ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST, {})
    }]} data-sentry-element="PropertyList" data-sentry-source-file="onboarding-survey.tsx" />
    </PageLayout>;
};