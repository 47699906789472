import classNames from 'classnames';
import { ReactNode } from 'react';
type Props = {
  left: ReactNode;
  right: ReactNode;
  size?: 'x-large' | 'large' | 'small' | 'minimized';
};
const SplitLayout = ({
  left,
  right,
  size = 'large'
}: Props) => {
  return <>
      <div className={classNames('z-30 hidden md:fixed md:inset-y-0 md:flex md:flex-col', size === 'x-large' && 'md:w-64', size === 'large' && 'md:w-52', size === 'small' && 'md:w-48', size === 'minimized' && 'bg-main ease-out-quint transition-all duration-200 hover:shadow-xl md:w-[61px] hover:md:w-[220px]')}>
        {left}
      </div>
      <div className={classNames('flex flex-1 flex-col', size === 'x-large' && 'md:pl-64', size === 'large' && 'md:pl-52', size === 'small' && 'md:pl-48', size === 'minimized' && 'md:pl-[61px]')}>
        {right}
      </div>
    </>;
};
export { SplitLayout };