import React from 'react';
import { useCardGet, useCompletedTransactionListInfinite, usePendingTransactionListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { CardPropertyList } from '../property-lists/CardPropertyList';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import { PendingTransactionsTable } from '../tables/PendingTransactionsTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
export const CardDetailPage = () => {
  const {
    cardID
  } = useTypedParams(ROUTES.CARD);
  const {
    data: card
  } = useCardGet(cardID);
  const transactionList = useCompletedTransactionListInfinite({
    cards: [cardID]
  });
  const pendingTransactionList = usePendingTransactionListInfinite({
    route_ids: [cardID],
    limit: 10
  });
  if (!card) {
    return <div>Loading...</div>;
  }
  return <PageLayout headline={card.description || card.id} data-sentry-element="PageLayout" data-sentry-component="CardDetailPage" data-sentry-source-file="CardDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<CardPropertyList card={card} title="Details" layoutHorizontallyWhenPossible={false} />} left={<>
            <TableStateWrapper table={PendingTransactionsTable} style="detail" {...pendingTransactionList} title="Pending Transactions" emptyTitle="No pending transactions" emptySubtitle="There are no pending transactions to display" viewMoreHref={buildPath(ROUTES.PENDING_TRANSACTIONS_LIST, {}, {
        card: [cardID]
      })} showAccount={false} showGroup={false} showRoute={false} />
            <TableStateWrapper table={CompletedTransactionsTable} style="detail" {...transactionList} title="Completed Transactions" emptyTitle="No transactions" emptySubtitle="There are no transactions to display" viewMoreHref={buildPath(ROUTES.TRANSACTIONS_LIST, {}, {
        card: [cardID]
      })} showAccount={false} showGroup={false} showRoute={false} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="CardDetailPage.tsx" />
    </PageLayout>;
};