import { useEntityGet, useCommercialOnboardingSurveyGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount } from 'src/build/operations';
import { formatAddress, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useProgramPropertyRow, useGroupPropertyRow } from 'src/lib/propertyListHelpers';
const booleanQuestion = (label: string, value: boolean): ListItem => value ? {
  label,
  value: 'Yes',
  badgeColor: 'yellow'
} : {
  label,
  value: 'No'
};
const formatRange = (range: CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount): string => ({
  zero: '$0',
  zero_to_ten_thousand_dollars: '$0 - $10,000',
  ten_thousand_to_fifty_thousand_dollars: '$10,000 - $50,000',
  fifty_thousand_to_one_hundred_thousand_dollars: '$50,000 - $100,000',
  more_than_one_hundred_thousand_dollars: 'More than $100,000'
})[range];
export const CommercialOnboardingSurveyDetailPage = () => {
  const {
    id: commercialOnboardingSurveyId
  } = useTypedParams(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_DETAILS);
  const {
    data: survey
  } = useCommercialOnboardingSurveyGet(commercialOnboardingSurveyId);
  const categoricals = useCategoricals();
  const programRow = useProgramPropertyRow(survey?.program_id);
  const groupRow = useGroupPropertyRow(survey?.group_id);
  const {
    data: entity
  } = useEntityGet(survey?.entity_id || '', {
    enabled: !!survey?.entity_id
  });
  if (!survey) {
    return <Loading />;
  }
  const address = entity?.setup_submission_structure.corporation?.submitted_address;
  const industryCode = entity?.setup_submission_structure?.corporation?.industry_code;
  const industryCodeName = categoricals?.industry_code?.find(c => c.value === industryCode)?.name;
  return <PageLayout headline={`Onboarding Survey - ${survey.entity_name}`} data-sentry-element="PageLayout" data-sentry-component="CommercialOnboardingSurveyDetailPage" data-sentry-source-file="CommercialOnboardingSurveyDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<PropertyList title="Details" items={compact<ListItem>([{
      label: 'ID',
      value: survey.id
    }, survey.archived_at && {
      label: 'Status',
      value: 'Archived',
      badgeColor: 'red'
    }, programRow, groupRow, {
      label: 'Entity',
      value: survey.entity_name,
      href: buildPath(ROUTES.ENTITY, {
        entityId: survey.entity_id
      })
    }, industryCodeName && {
      label: 'Business Industry',
      value: industryCodeName
    }, industryCode && {
      label: 'NAICS code',
      value: industryCode
    }, {
      label: 'Business location',
      value: address ? formatAddress(address) : '-'
    }, {
      label: 'Created At',
      value: formatISO8601Timestamp(survey.created_at, 'medium')
    }, survey.archived_at && {
      label: 'Archived At',
      value: formatISO8601Timestamp(survey.archived_at, 'medium')
    }])} layoutHorizontallyWhenPossible={false} />} left={<>
            <PropertyList title="Expected activity" items={[{
        label: 'Monthly domestic wire amount',
        value: formatRange(survey.monthly_domestic_wire_amount)
      }, {
        label: 'Monthly ACH origination amount',
        value: formatRange(survey.monthly_ach_origination_amount)
      }, {
        label: 'Monthly deposit amount',
        value: formatRange(survey.monthly_deposit_amount)
      }]} layoutHorizontallyWhenPossible={false} />
            <PropertyList title="High-risk business types & high risk transaction activity" items={[booleanQuestion('Does this business cash checks?', survey.is_check_casher), booleanQuestion('Does this business provide financial services?', survey.is_financial_services_provider), booleanQuestion('Does this business engage in making or accepting payments related to internet gambling?', survey.is_internet_gambling_related), booleanQuestion('Does the business conduct business in the marijuana industry?', survey.is_marijuana_related), booleanQuestion('Does this business issue stored value cards?', survey.issues_stored_value_cards), booleanQuestion("Does this business issue negotiable instruments (i.e. money orders, cashier's checks, travelers checks, etc.)?", survey.issues_negotiable_instruments), booleanQuestion('Does this business currently receive or expect to receive payments from credit/debit or store valued cards?', survey.receives_credit_debit_or_stored_value_card_payments)]} layoutHorizontallyWhenPossible={false} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="CommercialOnboardingSurveyDetailPage.tsx" />
    </PageLayout>;
};