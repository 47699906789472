import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import React, { useMemo } from 'react';
import { ExternalAccountListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
import { uniq } from 'lodash';
type ExternalAccountsTableProps = PrestyledTableProps<ExternalAccountListResponseDataItem>;
function formatIntegerWithExceeds(amount: number, exceedsLimit: number): string {
  return `${amount >= exceedsLimit ? '+' : ''}${amount.toLocaleString('en-US')}`;
}
export const ExternalAccountsTable = ({
  ...rest
}: ExternalAccountsTableProps) => {
  const columns = useMemo<TableColumns<ExternalAccountListResponseDataItem>>(() => {
    return [{
      header: 'ID',
      contents: externalAccount => ({
        text: externalAccount.id
      })
    }, {
      header: 'Routing number',
      contents: externalAccount => ({
        text: externalAccount.routing_number
      })
    }, {
      header: 'Account number',
      expand: 1,
      contents: externalAccount => ({
        text: externalAccount.account_number
      })
    }, {
      header: 'Count transactions',
      contents: externalAccount => ({
        text: formatIntegerWithExceeds(externalAccount.external_account_transactions_sample.length, 100)
      })
    }, {
      header: 'Count entities',
      contents: externalAccount => ({
        text: formatIntegerWithExceeds(uniq(externalAccount.external_account_transactions_sample.map(transaction => transaction.entity_id)).length, 100)
      })
    }];
  }, []);
  const getRowProps = (datum: ExternalAccountListResponseDataItem) => ({
    href: buildPath(ROUTES.EXTERNAL_ACCOUNTS_DETAILS, {
      externalAccountID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table columns={columns} getRowProps={rest.getRowProps ?? getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ExternalAccountsTable" data-sentry-source-file="ExternalAccountsTable.tsx" />;
};