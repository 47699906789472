import { Menu } from '../Menu';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Tooltip } from '../Tooltip';
import { Contents, MobileDisplayContents, STATUS_INDICATOR_COLOR_CLASSES } from '.';
import { Badge } from '../Badge';
import { Icon } from '../Icon';
import { MenuLayout } from '../MenuLayout';
import { StyledLink } from '../StyledLink';
import { Body, Caption } from '../Text';
import { TextButton } from '../TextButton';
export const TableCellContents = (props: {
  contents: Contents;
}) => {
  const {
    contents
  } = props;
  if ('badgeColor' in contents) {
    return <Badge size="small" color={contents.badgeColor} label={contents.text} icon={contents.icon} />;
  }
  if ('menuItems' in contents) {
    return <Menu button={<TextButton size="small" icon="more" />}>
        <MenuLayout items={contents.menuItems} />
      </Menu>;
  }
  let icon: ReactNode | null = null;
  if ('icon' in contents && contents.icon) {
    icon = <Icon name={contents.icon} className={classNames('mr-2 h-4 w-4 shrink-0', contents.iconClassname)} />;
  }
  const body = <Body color={contents.textColor ?? 'secondary'} weight={contents.textWeight ?? 'normal'} className={classNames('truncate', 'strikeThrough' in contents && contents.strikeThrough && 'line-through')}>
      {'href' in contents && contents.href ? <StyledLink style="underline" href={contents.href} suppressFocus>
          {contents.text}
        </StyledLink> : contents.text}
    </Body>;
  const statusIndicator = 'statusIndicator' in contents && contents.statusIndicator ? <div className={classNames(STATUS_INDICATOR_COLOR_CLASSES[contents.statusIndicator], 'mr-2 h-2 w-2 shrink-0 rounded-full')} /> : undefined;
  const cellBody = <>
      {statusIndicator}
      {icon}
      {body}
    </>;
  if ('tooltip' in contents && contents.tooltip) {
    return <Tooltip content={contents.tooltip} icon={contents.tooltipIcon && contents.tooltipIcon} direction="bottom" className="max-w-full">
        {cellBody}
      </Tooltip>;
  }
  return <>
      {statusIndicator}
      {icon}
      {body}
    </>;
};
export const MobileTableCellContents = (props: {
  contents: MobileDisplayContents;
  align: 'left' | 'right';
}) => {
  const {
    contents
  } = props;
  if ('menuItems' in contents) {
    return <Menu button={<TextButton size="small" icon="more" />}>
        <MenuLayout items={contents.menuItems} />
      </Menu>;
  }
  let icon: ReactNode | null = null;
  if ('icon' in contents && contents.icon) {
    icon = <div className="bg-stronger text-strong relative flex items-center justify-center rounded p-2">
        <Icon name={contents.icon} className={classNames('h-4 w-4 shrink-0')} />
        {'statusIndicator' in contents && contents.statusIndicator && <div className={classNames(STATUS_INDICATOR_COLOR_CLASSES[contents.statusIndicator], 'border-inverse absolute bottom-0 right-0 h-3 w-3 rounded border-2')} />}
      </div>;
  }
  const body = <Body color={contents.textColor ?? 'primary'} weight={contents.textWeight ?? 'medium'} className={classNames('truncate', 'strikeThrough' in contents && contents.strikeThrough && 'line-through')}>
      {contents.text}
    </Body>;
  const caption = 'caption' in contents ? <Caption color="secondary" className="truncate">
        {contents.caption}
      </Caption> : null;
  return <div className="flex items-center gap-3 truncate" data-sentry-component="MobileTableCellContents" data-sentry-source-file="contents.tsx">
      {icon}
      <div className={classNames('truncate', props.align === 'left' ? 'text-left' : 'text-right')}>
        {body}
        {caption}
      </div>
    </div>;
};