import { Button, ButtonStyle } from './Button';
import { IconType } from './Icon';
import { useModal } from './Modal';
import { ModalLayout } from './ModalLayout';
import { Body } from './Text';
type Props = {
  title: string;
  text: string;
  confirmText: string;
  confirmIcon: IconType;
  confirmStyle: ButtonStyle;
  cancelText: string;
  onConfirm: () => Promise<unknown>;
  onCancel?: () => Promise<void>;
};
export const ConfirmModal = ({
  title,
  text,
  confirmText,
  confirmIcon,
  cancelText,
  confirmStyle = 'primary',
  onCancel,
  onConfirm
}: Props) => {
  return <ModalLayout title={<Body weight="medium" contents={title} color="primary" />} body={<div className="flex flex-row items-start gap-2">
          <div className="flex w-full flex-col items-start gap-2">
            <Body color="secondary" contents={text} />
            <div className="flex w-full flex-row justify-end gap-2">
              <Button style="secondary" text={cancelText} onClick={onCancel} />
              <Button icon={confirmIcon} text={confirmText} style={confirmStyle} onClick={onConfirm} />
            </div>
          </div>
        </div>} data-sentry-element="ModalLayout" data-sentry-component="ConfirmModal" data-sentry-source-file="ConfirmModal.tsx" />;
};
export const useConfirmModal = (props: Props) => {
  const {
    onConfirm,
    onCancel = async () => {},
    ...rest
  } = props;
  const {
    modalOpen,
    showModal,
    closeModal
  } = useModal();
  const showConfirmModal = () => {
    showModal(<ConfirmModal {...rest} onCancel={() => onCancel().then(closeModal)} onConfirm={() => onConfirm().then(closeModal)} />);
  };
  const setModalOpen = (show: boolean) => {
    if (show) {
      showConfirmModal();
    } else {
      closeModal();
    }
  };
  return {
    modalOpen,
    setModalOpen
  };
};