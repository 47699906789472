import { ManagementInformationSystemVolumesListResponseDataTotals, ManagementInformationSystemVolumesListResponseDataVolumesItem } from 'src/build/operations';
import { formatInteger } from 'src/lib/format-string';
import { formatAmount, formatPercentage } from '@increase/shared/lib/formatting';
import { VolumeTableDataRow } from './helpers';
import { Table, TableColumns } from '@increase/shared/components/Table';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = {
  volumes: ManagementInformationSystemVolumesListResponseDataVolumesItem[];
  totals: ManagementInformationSystemVolumesListResponseDataTotals;
  metric: 'volume' | 'count';
};
const VOLUME_COLUMNS: TableColumns<VolumeTableDataRow> = [{
  header: 'Name',
  contents: data => ({
    text: data.partner_name,
    href: data.program_id === 'total' ? undefined : buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
      bankProgramId: data.program_id
    }),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  })
}, {
  header: 'Originated ACH',
  contents: data => ({
    text: formatAmount(data.originated_ach_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated Check',
  contents: data => ({
    text: formatAmount(data.originated_check_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated Wire',
  contents: data => ({
    text: formatAmount(data.originated_wire_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated RTP',
  contents: data => ({
    text: formatAmount(data.originated_rtp_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Card',
  contents: data => ({
    text: formatAmount(data.card_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Total',
  contents: data => ({
    text: formatAmount(data.total_volume, 'USD'),
    textWeight: data.program_id === 'total' ? 'bold' : undefined,
    tooltip: data.lookback_total_volume !== 0 ? `M/M: ${formatPercentage(data.total_volume / data.lookback_total_volume)}` : 'N/A',
    tooltipIcon: 'info'
  }),
  align: 'right'
}];
const COUNT_COLUMNS: TableColumns<VolumeTableDataRow> = [{
  header: 'Name',
  contents: data => ({
    text: data.partner_name,
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  })
}, {
  header: 'Originated ACH',
  contents: data => ({
    text: formatInteger(data.originated_ach_transaction_count),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated Check',
  contents: data => ({
    text: formatInteger(data.originated_check_transaction_count),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated Wire',
  contents: data => ({
    text: formatInteger(data.originated_wire_transaction_count),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Originated RTP',
  contents: data => ({
    text: formatInteger(data.originated_rtp_transaction_count),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}, {
  header: 'Card',
  contents: data => ({
    text: formatInteger(data.card_settlement_count),
    textWeight: data.program_id === 'total' ? 'bold' : undefined
  }),
  align: 'right'
}];
export const ProgramVolumes = ({
  volumes,
  totals,
  metric
}: Props) => {
  const tableData: VolumeTableDataRow[] = volumes.map((volume, index) => ({
    ...volume,
    id: `${volume.program_id}-${index}-volume`
  }));
  tableData.push({
    id: 'total-volume',
    program_id: 'total',
    partner_name: 'Total',
    originated_ach_volume: totals.originated_ach_volume,
    originated_wire_volume: totals.originated_wire_volume,
    originated_check_volume: totals.originated_check_volume,
    originated_rtp_volume: totals.originated_rtp_volume,
    originated_ach_transaction_count: totals.originated_ach_transaction_count,
    originated_wire_transaction_count: totals.originated_wire_transaction_count,
    originated_check_transaction_count: totals.originated_check_transaction_count,
    originated_rtp_transaction_count: totals.originated_rtp_transaction_count,
    card_volume: totals.card_volume,
    card_settlement_count: totals.card_settlement_count,
    total_volume: totals.volume,
    lookback_total_volume: totals.lookback_volume
  });
  const columns = metric === 'volume' ? VOLUME_COLUMNS : COUNT_COLUMNS;
  return <div className="space-y-4" data-sentry-component="ProgramVolumes" data-sentry-source-file="program-volumes.tsx">
      <Table columns={columns} data={tableData} data-sentry-element="Table" data-sentry-source-file="program-volumes.tsx" />
    </div>;
};