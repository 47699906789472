export const splitOnFedwireTags = (rawMessageBody: string): string => {
  return rawMessageBody.split('{').join('\n{').trim();
};

export const parseFedwireMessage = (
  rawMessageBody: string
): { [tag: number]: string } => {
  const message: { [tag: string]: string } = {};
  const matchedTags = rawMessageBody.matchAll(/{(\d+)}([^{}]+)/g);

  for (const match of matchedTags) {
    if (!match.groups) {
      continue;
    }

    const { tag, body } = match.groups;
    message[tag] = body;
  }

  return message;
};
