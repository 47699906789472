import { TableColumns, Table, PrestyledTableProps, Contents } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { TransferReviewListResponseDataItem } from 'src/build/operations';
import { formatAmount, formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { ROUTES, buildPath } from 'src/lib/routes';
import { absurd } from '@increase/shared/lib/absurd';
import { useOperatorGet } from 'src/hooks/reactQuery';
type Props = PrestyledTableProps<TransferReviewListResponseDataItem>;
const buildTransferLinkForReview = (review: TransferReviewListResponseDataItem): Contents => {
  let href: string | undefined;
  if (review.reviewer === 'increase') {
    switch (review.transfer_type) {
      case 'ach_transfer':
      case 'check_transfer':
      case 'real_time_payments_transfer':
      case 'wire_transfer':
        href = buildPath(ROUTES.TRANSFER_DETAIL, {
          transferID: review.transfer_id
        });
        break;
      case 'check_deposit':
      case 'inbound_ach_entry':
      case 'inbound_wire':
        href = buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: review.transfer_id
        });
        break;
      case 'inbound_ctx_entry':
      case 'inbound_international_ach_entry':
        href = undefined;
        break;
      default:
        absurd(review.transfer_type);
    }
  }
  return {
    text: review.transfer_id,
    textColor: 'primary',
    textWeight: 'medium',
    href
  };
};
export const TransferReviewsTable = (props: Props) => {
  const {
    data: operator
  } = useOperatorGet({});
  const isIncreaseOperator = operator?.role === 'internal';
  const {
    ...rest
  } = props;
  const columns = useMemo<TableColumns<TransferReviewListResponseDataItem>>(() => {
    return [{
      header: 'Created',
      contents: review => ({
        text: formatISO8601Timestamp(review.created_at, 'month-day-hour-minute')
      }),
      mobileContents: review => ({
        text: review.transfer_id,
        caption: formatISO8601Timestamp(review.created_at, 'month-day-hour-minute')
      })
    }, {
      header: 'Transfer ID',
      expand: 1,
      contents: review => buildTransferLinkForReview(review)
    }, {
      header: 'Transfer Type',
      contents: review => ({
        text: humanize(review.transfer_type)
      })
    }, isIncreaseOperator && {
      header: 'Reviewer',
      contents: review => ({
        text: humanize(review.reviewer)
      })
    }, {
      header: 'Group',
      expand: 1,
      contents: review => ({
        text: review.group_name ?? review.group_friendly_nickname,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: review.group_id
        })
      })
    }, {
      header: 'Program',
      expand: 1,
      contents: review => ({
        text: review.program_name,
        href: buildPath(ROUTES.PROGRAMS_DETAIL, {
          programId: review.program_id
        })
      })
    }, {
      header: 'Reason',
      contents: review => ({
        text: humanize(review.denial_reason ?? '-'),
        badgeColor: 'gray'
      })
    }, {
      header: 'Amount',
      align: 'right',
      contents: review => ({
        text: formatAmount(review.amount, review.currency),
        textWeight: 'bold',
        textColor: 'primary'
      }),
      mobileContents: review => ({
        text: formatAmount(review.amount, review.currency),
        textColor: 'primary',
        textWeight: 'bold',
        caption: humanize(review.denial_reason ?? '-')
      })
    }];
  }, [isIncreaseOperator]);
  return <Table<TransferReviewListResponseDataItem> columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="TransferReviewsTable" data-sentry-source-file="TransferReviewsTable.tsx" />;
};