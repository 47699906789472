import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames from 'classnames';
import { Body } from './Text';
export type Props = {
  label?: string;
  labelDirection?: 'right' | 'left';
  checked: boolean;
  disabled?: boolean;
  onChange: (newValue: boolean) => void;
};
const Switch = ({
  checked = false,
  disabled,
  onChange,
  label,
  labelDirection = 'right'
}: Props) => {
  return <div className={classNames(labelDirection === 'left' && 'flex-row-reverse', 'flex flex-row items-center gap-3')} data-sentry-component="Switch" data-sentry-source-file="Switch.tsx">
      <HeadlessSwitch checked={checked} onChange={!disabled ? onChange : undefined} className={classNames('relative inline-flex h-[18px] w-7 shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-full', 'ring-offset-main focus:ring-main focus:outline-none focus:ring-[3px]', 'transition-colors duration-300 ease-in-out', checked ? 'bg-brand hover:bg-brand-hover' : 'bg-disabled-strong hover:bg-brand/25', {
      'pointer-events-none opacity-50': disabled
    })} data-sentry-element="HeadlessSwitch" data-sentry-source-file="Switch.tsx">
        <span className="sr-only">Use setting</span>
        {/* background (since switch is bigger than the track)*/}
        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full" />
        {/* button */}
        <span aria-hidden="true" className={classNames(checked ? 'translate-x-2.5' : 'translate-x-0', 'bg-main ease-out-quint pointer-events-none absolute left-0.5 inline-block h-3.5 w-3.5 rounded-full shadow-md transition-transform duration-300', {
        'opacity-50': disabled
      })} />
      </HeadlessSwitch>
      {label && <Body color="primary" contents={label} />}
    </div>;
};
export { Switch };