import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { administrationRedirectUrl } from './object-viewer';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const Unit21Object = () => {
  const {
    objectID,
    objectType
  } = useTypedParams(ROUTES.UNIT21_OBJECT_VIEWER);
  const navigate = useNavigate();
  const url = administrationRedirectUrl(objectType, objectID);
  useEffect(() => {
    if (url) {
      navigate(url, {
        replace: false
      });
    }
  });
  return url ? <div data-sentry-component="Unit21Object" data-sentry-source-file="unit21-object.tsx">Redirecting {objectID}.</div> : <div data-sentry-component="Unit21Object" data-sentry-source-file="unit21-object.tsx">
      Unable to redirect {objectID}. Don't know about {objectType}
    </div>;
};