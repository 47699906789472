import React from 'react';
import { useDeclinedTransactionGet } from 'src/hooks/reactQuery';
import { OperatorNotes } from './operator-notes';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { StyledLink } from './deprecated/StyledLink';
import { formatAmount } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { Body } from '@increase/shared/components/Text';
import { useAccountPropertyRow, useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { useServices } from 'src/hooks/use-services';
import { extractSourceFields } from 'src/lib/sourceHelpers';
export const DeclinedTransactionDetail = () => {
  const {
    operations
  } = useServices();
  const {
    declinedTransactionID
  } = useTypedParams(ROUTES.DECLINED_TRANSACTION_DETAIL);
  const {
    data: declinedTransaction
  } = useDeclinedTransactionGet(declinedTransactionID);
  const groupRow = useGroupPropertyRow(declinedTransaction?.group_id);
  const accountRow = useAccountPropertyRow(declinedTransaction?.account_id);
  if (!declinedTransaction) {
    return <>Loading...</>;
  }
  const source = declinedTransaction.source as unknown as Record<string, string> | undefined;
  return <PageLayout id="application.transactionDetails" headline={declinedTransaction.id} data-sentry-element="PageLayout" data-sentry-component="DeclinedTransactionDetail" data-sentry-source-file="declined-transaction-detail.tsx">
      <Section header="Declined Transaction" data-sentry-element="Section" data-sentry-source-file="declined-transaction-detail.tsx">
        <PropertyList items={[groupRow, accountRow, {
        label: 'Amount ID',
        valueNode: <Body color="primary" className="line-through">
                  {formatAmount(declinedTransaction.amount, declinedTransaction.currency)}
                </Body>
      }, {
        label: 'Description',
        value: declinedTransaction.description
      }, {
        label: 'Declined Transaction Type (Source Type)',
        value: declinedTransaction.source_type
      }, {
        label: 'Date',
        value: declinedTransaction.date
      }, {
        label: 'Created at',
        value: declinedTransaction.created_at
      }]} data-sentry-element="PropertyList" data-sentry-source-file="declined-transaction-detail.tsx"></PropertyList>
      </Section>

      {source ? <Section header="Source">
          <p className="p-1 text-sm">
            These fields define the details of this declined transaction. The
            meaning of each field is documented here:{' '}
            <StyledLink to="https://increase.com/documentation/api#declined-transaction-object">
              Increase Documentation
            </StyledLink>
            . Click on "Child Attributes" on the "Source" section and then the
            entry matching the `class_name` here.
          </p>
          <PropertyList items={extractSourceFields({
        object: declinedTransaction.source as object,
        operations
      })} />
        </Section> : null}

      <br />

      <OperatorNotes modelId={declinedTransactionID} data-sentry-element="OperatorNotes" data-sentry-source-file="declined-transaction-detail.tsx" />
    </PageLayout>;
};