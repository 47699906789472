import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ROUTES, buildPath } from 'src/lib/routes';
import { SanctionsScreeningReviewListResponseDataItem } from 'src/build/operations';
import { useOperatorGet, useSanctionsScreeningReviewListInfinite } from 'src/hooks/reactQuery';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { useMemo } from 'react';
import { makeDescriptionFilter, makeEnumFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { compact } from 'lodash';
import { SanctionsScreeningReviewsTable } from '../tables/SanctionScreeningReviewsTable';
const getRowProps = (datum: SanctionsScreeningReviewListResponseDataItem) => ({
  href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL, {
    reviewID: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const SanctionsScreeningReviewsListPage = () => {
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, 'statuses');
  const [reviewers, setReviewers] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, 'reviewer');
  const [records, setRecords] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, 'records');
  const [recordTypes, setRecordTypes] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, 'record_type');
  const listResponse = useSanctionsScreeningReviewListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    reviewers: reviewers.length > 0 ? reviewers : undefined,
    record_ids: records.length > 0 ? records : undefined,
    record_types: recordTypes.length > 0 ? recordTypes : undefined
  });
  const {
    data: operator
  } = useOperatorGet({});
  const multibank = !operator?.bank;
  const filters: TableFilter[] = useMemo(() => {
    return compact([makeStatusFilter(statuses, setStatuses, {
      backfill: 'Backfill',
      pending_reviewing: 'Pending reviewing',
      requires_attention: 'Requires attention',
      false_positive: 'False positive',
      true_positive: 'True positive'
    }), makeDescriptionFilter(records.join(','), r => setRecords(r?.split(','))), makeEnumFilter(recordTypes, setRecordTypes, 'Record Type', {
      entity: 'Entity',
      transfer: 'Transfer'
    }, 'info'), multibank && makeEnumFilter(reviewers, setReviewers, 'Reviewer', {
      grasshopper: 'Bank',
      increase: 'Increase'
    }, 'users')]);
  }, [statuses, setStatuses, reviewers, setReviewers, multibank, records, setRecords, recordTypes, setRecordTypes]);
  return <PageLayout headline="Sanctions Screening Reviews" data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningReviewsListPage" data-sentry-source-file="SanctionsScreeningReviewsListPage.tsx">
      <TableStateWrapper {...listResponse} table={SanctionsScreeningReviewsTable} style="primary" emptyTitle="No sanctions screening items pending review" emptySubtitle="" filters={filters} getRowProps={getRowProps} showRecordId={true} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningReviewsListPage.tsx" />
    </PageLayout>;
};