import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { humanize } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { TaskStatusListResponseDataItem } from 'src/build/operations';
import { Switch } from '@increase/shared/components/Switch';
import { useTaskStatusPatch } from 'src/hooks/reactQuery';
import { Badge } from '@increase/shared/components/Badge';
import { Box } from '@increase/shared/components/Box';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<TaskStatusListResponseDataItem>;
const TaskStatusSwitch = (props: {
  taskStatusId: string;
  status: TaskStatusListResponseDataItem['status'];
}) => {
  const patch = useTaskStatusPatch();
  return <Box flex className="min-w-28 justify-between" data-sentry-element="Box" data-sentry-component="TaskStatusSwitch" data-sentry-source-file="TaskStatusTable.tsx">
      <Badge color={props.status === 'active' ? 'green' : 'gray'} label={humanize(props.status)} data-sentry-element="Badge" data-sentry-source-file="TaskStatusTable.tsx" />
      <Switch disabled={patch.isLoading} checked={props.status === 'active'} onChange={newValue => patch.mutateAsync([{
      task_status_id: props.taskStatusId,
      status: newValue ? 'active' : 'disabled'
    }])} data-sentry-element="Switch" data-sentry-source-file="TaskStatusTable.tsx" />
    </Box>;
};
const dataDogLink = (taskStatus: TaskStatusListResponseDataItem): string => {
  const query = `@operation_name:"${taskStatus.name}"`;
  return `https://us3.datadoghq.com/logs?query=${encodeURIComponent(query)}`;
};
export const TaskStatusTable = (props: Props) => {
  const columns = useMemo<TableColumns<TaskStatusListResponseDataItem>>(() => {
    return [{
      header: 'Status',
      CellComponent: ({
        datum: taskStatus
      }) => <TaskStatusSwitch taskStatusId={taskStatus.id} status={taskStatus.status} />
    }, {
      header: 'Name',
      expand: 1,
      contents: taskStatus => ({
        text: taskStatus.name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Cron',
      contents: taskStatus => ({
        text: taskStatus.cron || 'never',
        textWeight: 'mono'
      })
    }, {
      header: 'Logs',
      contents: taskStatus => ({
        text: 'Logs',
        href: dataDogLink(taskStatus)
      })
    }, {
      header: 'Result',
      contents: taskStatus => ({
        text: taskStatus.result_status_id ? 'Result' : '',
        href: taskStatus.result_status_id ? buildPath(ROUTES.RESULT_STATUS, {
          resultStatusID: taskStatus.result_status_id
        }) : undefined
      })
    }];
  }, []);
  const defaultRowProps = () => ({
    className: 'hover:bg-main-hover transition-all group'
  });
  return <Table<TaskStatusListResponseDataItem> columns={columns} getRowProps={defaultRowProps} {...props} data-sentry-element="Table" data-sentry-component="TaskStatusTable" data-sentry-source-file="TaskStatusTable.tsx" />;
};