import React from 'react';
import { useOperatorGet, useOperatorTagList, useOperatorTagPost } from 'src/hooks/reactQuery';
import { Button } from './deprecated/Button';
import { Badge } from 'shared/components/Badge';
type Props = {
  idPrefix?: string;
  modelId: string;
};
export const OperatorTags = ({
  modelId,
  idPrefix
}: Props) => {
  const {
    data: operatorTags,
    refetch
  } = useOperatorTagList({
    model_id: modelId
  });
  const {
    data: operator
  } = useOperatorGet({});
  const operatorTagPost = useOperatorTagPost();
  const createOperatorTag = (key: string, value: string) => {
    const maybeValue = value === '' ? {} : {
      value
    };
    operatorTagPost.mutateAsync([{
      model_id: modelId,
      key,
      ...maybeValue
    }]).then(() => {
      refetch();
    });
  };
  if (!operator || !operatorTags) {
    return <></>;
  }
  const badges = <div className="flex flex-wrap items-center gap-2">
      {operatorTags.data.map(operatorTag => <Badge key={operatorTag.id} color="blue" label={`${operatorTag.key}${operatorTag.value ? `: ${operatorTag.value}` : ''}`} />)}
    </div>;
  if (operator.role !== 'internal') {
    return badges;
  }
  return <div className="space-y-4" data-sentry-component="OperatorTags" data-sentry-source-file="operator-tags.tsx">
      {badges}

      <div>
        <form onSubmit={submitEvent => {
        submitEvent.preventDefault();
        const form = Object.fromEntries(new FormData(submitEvent.currentTarget));
        createOperatorTag(String(form.key), String(form.value));
      }}>
          <div className="flex flex-wrap items-center gap-2 text-xs">
            <input id={idPrefix + '.keyInput'} className="rounded border border-stronger px-1 py-0.5" name="key" placeholder="Key" />
            <input id={idPrefix + '.valueInput'} className="rounded border border-stronger px-1 py-0.5" name="value" placeholder="Value" />
            <Button className="text-xs" data-testid="operator-tags.submit-button" id={idPrefix + '.submitButton'} type="submit" data-sentry-element="Button" data-sentry-source-file="operator-tags.tsx">
              Add tag
            </Button>
          </div>
        </form>
      </div>
    </div>;
};