/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FederalReserveGateway API
 * OpenAPI spec version: 0.0.1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  EchoGetParams,
  EchoGetResponse,
  FedwireDeleteParams,
  FedwireDeleteResponse,
  FedwireGetParams,
  FedwireGetResponse,
  FedwirePostParameters,
  FedwirePostResponse,
  LimitsChangeRequestCancelPostResponse,
  LimitsChangeRequestConfirmPostResponse,
  LimitsChangeRequestListResponse,
  LimitsChangeRequestPostParameters,
  LimitsChangeRequestPostResponse,
  LimitsEventListResponse,
  LimitsForRoutingNumberGetParams,
  LimitsForRoutingNumberGetResponse,
  LimitsGetResponse,
  LimitsListResponse,
  LimitsStatisticsGetResponse,
} from "./federalreservegateway.schemas";

/**
 * @summary EchoGet
 */
export const echoGet = <TData extends AxiosResponse<EchoGetResponse>>(
  params: EchoGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/echo`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary FedwireGet
 */
export const fedwireGet = <TData extends AxiosResponse<FedwireGetResponse>>(
  params: FedwireGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/fedwire`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary FedwirePost
 */
export const fedwirePost = <TData extends AxiosResponse<FedwirePostResponse>>(
  fedwirePostParameters: FedwirePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/fedwire`, fedwirePostParameters, options);
};

/**
 * @summary FedwireDelete
 */
export const fedwireDelete = <TData extends AxiosResponse<FedwireDeleteResponse>>(
  mqMessageIdentifier: string,
  params: FedwireDeleteParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/fedwire/${mqMessageIdentifier}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary LimitsList
 */
export const limitsList = <TData extends AxiosResponse<LimitsListResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits`, options);
};

/**
 * @summary LimitsGet
 */
export const limitsGet = <TData extends AxiosResponse<LimitsGetResponse>>(
  limitsId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits/${limitsId}`, options);
};

/**
 * @summary LimitsEventList
 */
export const limitsEventList = <TData extends AxiosResponse<LimitsEventListResponse>>(
  limitsId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits/${limitsId}/events`, options);
};

/**
 * @summary LimitsChangeRequestList
 */
export const limitsChangeRequestList = <
  TData extends AxiosResponse<LimitsChangeRequestListResponse>,
>(
  limitsId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits/${limitsId}/requests`, options);
};

/**
 * @summary LimitsChangeRequestPost
 */
export const limitsChangeRequestPost = <
  TData extends AxiosResponse<LimitsChangeRequestPostResponse>,
>(
  limitsId: string,
  limitsChangeRequestPostParameters: LimitsChangeRequestPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/limits/${limitsId}/requests`,
    limitsChangeRequestPostParameters,
    options,
  );
};

/**
 * @summary LimitsChangeRequestCancelPost
 */
export const limitsChangeRequestCancelPost = <
  TData extends AxiosResponse<LimitsChangeRequestCancelPostResponse>,
>(
  limitsId: string,
  limitsChangeRequestId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/limits/${limitsId}/requests/${limitsChangeRequestId}/cancel`,
    undefined,
    options,
  );
};

/**
 * @summary LimitsChangeRequestConfirmPost
 */
export const limitsChangeRequestConfirmPost = <
  TData extends AxiosResponse<LimitsChangeRequestConfirmPostResponse>,
>(
  limitsId: string,
  limitsChangeRequestId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/limits/${limitsId}/requests/${limitsChangeRequestId}/confirm`,
    undefined,
    options,
  );
};

/**
 * @summary LimitsStatisticsGet
 */
export const limitsStatisticsGet = <
  TData extends AxiosResponse<LimitsStatisticsGetResponse>,
>(
  limitsId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits/${limitsId}/statistics`, options);
};

/**
 * @summary LimitsForRoutingNumberGet
 */
export const limitsForRoutingNumberGet = <
  TData extends AxiosResponse<LimitsForRoutingNumberGetResponse>,
>(
  params: LimitsForRoutingNumberGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/limits_for_routing_number`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export type EchoGetResult = AxiosResponse<EchoGetResponse>;
export type FedwireGetResult = AxiosResponse<FedwireGetResponse>;
export type FedwirePostResult = AxiosResponse<FedwirePostResponse>;
export type FedwireDeleteResult = AxiosResponse<FedwireDeleteResponse>;
export type LimitsListResult = AxiosResponse<LimitsListResponse>;
export type LimitsGetResult = AxiosResponse<LimitsGetResponse>;
export type LimitsEventListResult = AxiosResponse<LimitsEventListResponse>;
export type LimitsChangeRequestListResult =
  AxiosResponse<LimitsChangeRequestListResponse>;
export type LimitsChangeRequestPostResult =
  AxiosResponse<LimitsChangeRequestPostResponse>;
export type LimitsChangeRequestCancelPostResult =
  AxiosResponse<LimitsChangeRequestCancelPostResponse>;
export type LimitsChangeRequestConfirmPostResult =
  AxiosResponse<LimitsChangeRequestConfirmPostResponse>;
export type LimitsStatisticsGetResult =
  AxiosResponse<LimitsStatisticsGetResponse>;
export type LimitsForRoutingNumberGetResult =
  AxiosResponse<LimitsForRoutingNumberGetResponse>;
