import React, { useMemo } from 'react';
import { useGroupListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { GroupsTable } from '../tables/GroupsTable';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeKeywordFilter, makeStatusFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
const groupStatuses = {
  active: 'Active',
  offboarded: 'Offboarded',
  rejected: 'Rejected',
  pending_activating: 'Pending Activating'
} as const;
export const Groups = () => {
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.GROUPS_LIST, 'statuses');
  const [keyword, setKeyword] = useSearchParamsState(ROUTES.GROUPS_LIST, 'keyword');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.GROUPS_LIST);
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeKeywordFilter(keyword, setKeyword), makeStatusFilter(statuses, setStatuses, groupStatuses), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created')]), [keyword, setKeyword, statuses, setStatuses, startAt, endAt, setTimeRange]);
  const groupList = useGroupListInfinite({
    status: statuses.length > 0 ? statuses : undefined,
    keyword: keyword === null ? undefined : keyword,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined
  });
  return <PageLayout headline="Groups" data-sentry-element="PageLayout" data-sentry-component="Groups" data-sentry-source-file="GroupListPage.tsx">
      <TableStateWrapper table={GroupsTable} {...groupList} style="primary" emptyTitle="No Groups" emptySubtitle="There are no groups to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="GroupListPage.tsx" />
    </PageLayout>;
};