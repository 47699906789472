import dynamic from 'next/dynamic';
import { useRoleListInfinite, useUserGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { UserPropertyList } from '../property-lists/UserPropertyList';
import { Box } from '@increase/shared/components/Box';
import { Body, Heading } from '@increase/shared/components/Text';
import { RolesTable } from '../tables/RolesTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
const JSONViewer = dynamic(() => import('react-json-view'), {
  ssr: false
});
export const UserDetailPage = () => {
  const {
    userId
  } = useTypedParams(ROUTES.USERS_DETAIL);
  const {
    data: user
  } = useUserGet(userId);
  const rolesListResponse = useRoleListInfinite({
    user_ids: [userId]
  });
  if (!user) {
    return <Loading />;
  }
  return <PageLayout headline={user.email} data-sentry-element="PageLayout" data-sentry-component="UserDetailPage" data-sentry-source-file="UserDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<>
            <UserPropertyList user={user} layoutHorizontallyWhenPossible={false} title="Summary" />
            <AssociatedManualTasks objectId={user.id} />
          </>} left={<>
            <TableStateWrapper table={RolesTable} {...rolesListResponse} style="detail" title={'Roles'} emptyTitle="No roles" emptySubtitle="There are no roles to display" viewMoreHref={buildPath(ROUTES.ROLES_LIST, {}, {
        user: [user.id]
      })} showActions={false} showGroup />

            <Box>
              <Heading>Clearbit enrichment</Heading>
              {user.enrichment && <JSONViewer name={null} displayDataTypes={false} indentWidth={2} displayObjectSize={false} quotesOnKeys={false} collapseStringsAfterLength={30} src={user.enrichment} />}

              {!user.enrichment && <Body>No Clearbit enrichment.</Body>}
            </Box>
          </>} data-sentry-element="SideBySide" data-sentry-source-file="UserDetailPage.tsx" />
    </PageLayout>;
};