import React from 'react';
import { InternalOperatorOnly } from '../internal-operator-only';
import { useManagementInformationSystemAccountsList } from 'src/hooks/reactQuery';
import { Section } from '../deprecated/Section';
import { ProgramAccountSummary } from './program-account-summary';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
export const AccountsPage = () => {
  const [atTime] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'at_time');
  const {
    data: accountsData
  } = useManagementInformationSystemAccountsList({
    at_time: atTime
  });
  return <>
      <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-source-file="AccountsPage.tsx">
        {accountsData ? <Section header="Account Summary">
            <ProgramAccountSummary accountSummaries={accountsData.data} />
          </Section> : <h3>Loading</h3>}
      </InternalOperatorOnly>
    </>;
};