import classNames from 'classnames';
import { Body } from '@increase/shared/components/Text';
export const findHighlights = (text: string, highlights: string[]): {
  token: string;
  highlight: boolean;
}[] => {
  const tokens = text.split(/\b/);
  return tokens.map(token => ({
    token,
    highlight: highlights.includes(token.toLowerCase())
  }));
};
export const splitForHighlights = (text: string): string[] => {
  return text.split(/\b/).filter(t => t.trim().length > 0);
};
export const CommonWordHighlight = (props: {
  highlights: string[];
  text: string;
}) => {
  const highlights = props.highlights.map(h => h.toLowerCase());
  return <Body color="primary" data-sentry-element="Body" data-sentry-component="CommonWordHighlight" data-sentry-source-file="common-word-highlight.tsx">
      {findHighlights(props.text, highlights).map(({
      token,
      highlight
    }, i) => <>
          <span key={`${token}-${i}`} className={classNames({
        'bg-warning': highlight
      })}>
            {token}
          </span>
        </>)}
    </Body>;
};