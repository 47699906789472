import { ReactNode } from 'react';
import { Heading } from '../Text';
import { IntersectionObserver } from '../IntersectionObserver';
import classNames from 'classnames';
export type StackedListProps = {
  title?: ReactNode;
  onBottomVisible?: () => void;
  children?: ReactNode;
  action?: ReactNode;
  className?: string;
  style?: 'rounded' | 'square';
};
export const StackedList = ({
  title,
  children,
  onBottomVisible,
  action,
  className,
  style = 'square'
}: StackedListProps) => {
  const titleEl = typeof title === 'string' ? <Heading contents={title} color="primary" /> : title;
  return <div className={className} data-sentry-component="StackedList" data-sentry-source-file="StackedList.tsx">
      {title && <div className="mb-4 flex flex-row items-center justify-between">
          {titleEl}
          {action}
        </div>}
      <div className={classNames('border-main bg-main', style === 'rounded' && 'shadow-xs rounded border', style === 'square' && 'border-t')}>
        <ul role="list">{children}</ul>
      </div>
      {onBottomVisible && <IntersectionObserver onVisible={onBottomVisible} />}
    </div>;
};