import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useExternalAccountListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { ExternalAccountsTable } from '../tables/ExternalAccountsTable';
export const ExternalAccountListPage = () => {
  const [entityCountOrGreater, setEntityCountOrGreater] = useSearchParamsState(ROUTES.EXTERNAL_ACCOUNTS_LIST, 'entity_count_or_greater');
  const [transactionCountOrGreater, setTransactionCountOrGreater] = useSearchParamsState(ROUTES.EXTERNAL_ACCOUNTS_LIST, 'transaction_count_or_greater');
  const [routingNumber, setRoutingNumber] = useSearchParamsState(ROUTES.EXTERNAL_ACCOUNTS_LIST, 'routing_number');
  const filters: TableFilter[] = useMemo(() => compact([{
    type: 'string',
    label: 'Count entities or greater',
    id: 'count',
    icon: 'file_search',
    value: entityCountOrGreater == null ? '' : '' + entityCountOrGreater,
    onChange: value => setEntityCountOrGreater(value === '' ? null : +value)
  }, {
    type: 'string',
    label: 'Count transactions or greater',
    id: 'count',
    icon: 'file_search',
    value: transactionCountOrGreater == null ? '' : '' + transactionCountOrGreater,
    onChange: value => setTransactionCountOrGreater(value === '' ? null : +value)
  }, {
    type: 'string',
    label: 'Routing number',
    id: 'account',
    icon: 'account',
    value: routingNumber ?? '',
    onChange: value => setRoutingNumber(value === '' ? null : value)
  }]), [entityCountOrGreater, setEntityCountOrGreater, transactionCountOrGreater, setTransactionCountOrGreater, routingNumber, setRoutingNumber]);
  const externalAccountListResult = useExternalAccountListInfinite({
    entity_count_or_greater: entityCountOrGreater != null ? entityCountOrGreater : undefined,
    transaction_count_or_greater: transactionCountOrGreater != null ? transactionCountOrGreater : undefined,
    routing_number: routingNumber != null ? routingNumber : undefined
  });
  return <PageLayout headline={'External Account'} data-sentry-element="PageLayout" data-sentry-component="ExternalAccountListPage" data-sentry-source-file="ExternalAccountListPage.tsx">
      <TableStateWrapper table={ExternalAccountsTable} {...externalAccountListResult} style="primary" emptyTitle="No External Accounts" emptySubtitle="There are no external accounts to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="ExternalAccountListPage.tsx" />
    </PageLayout>;
};