import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { FedwireInboundServiceMessageListResponseDataItem, FedwireInboundServiceMessageListResponseDataItemReviewStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
import { parseFedwireMessage } from 'src/lib/fedwire-utility';
type FedwireInboundServiceMessageTableProps = PrestyledTableProps<FedwireInboundServiceMessageListResponseDataItem>;
const serviceMessageBadgeColor: Record<FedwireInboundServiceMessageListResponseDataItemReviewStatus, BadgeColor> = {
  ignored: 'gray',
  actioned: 'green',
  flagged_by_operator: 'red',
  pending_review: 'yellow',
  requires_attention: 'red'
};
const defaultRowProps = (datum: FedwireInboundServiceMessageListResponseDataItem) => ({
  href: buildPath(ROUTES.FEDWIRE_INBOUND_SERVICE_MESSAGES_DETAIL, {
    serviceMessageId: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const FedwireInboundServiceMessagesTable = (props: FedwireInboundServiceMessageTableProps) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<FedwireInboundServiceMessageListResponseDataItem>>(() => {
    return [{
      header: 'ID',
      expand: 1,
      contents: serviceMessage => {
        return {
          text: serviceMessage.id
        };
      }
    }, {
      header: 'Body',
      expand: 1,
      contents: serviceMessage => {
        const message = parseFedwireMessage(serviceMessage.message_body);
        return {
          text: message['6500'] || message['9000'],
          textColor: 'primary',
          textWeight: 'medium'
        };
      }
    }, {
      header: 'Sender',
      contents: serviceMessage => {
        return {
          text: serviceMessage.sender_institution
        };
      }
    }, {
      header: 'Receiver',
      contents: serviceMessage => {
        return {
          text: serviceMessage.receiver_institution
        };
      }
    }, {
      header: 'Status',
      contents: serviceMessage => ({
        text: humanize(serviceMessage.review_status),
        badgeColor: serviceMessageBadgeColor[serviceMessage.review_status]
      })
    }, {
      header: 'Received on',
      contents: serviceMessage => ({
        text: formatISO8601Timestamp(serviceMessage.received_on, 'medium')
      })
    }];
  }, []);
  return <Table {...rest} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="FedwireInboundServiceMessagesTable" data-sentry-source-file="FedwireInboundServiceMessagesTable.tsx" />;
};