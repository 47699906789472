import {
  InSearchParams,
  Route,
  UniversalType,
} from 'react-router-typesafe-routes';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

export type TimeRangeSearchParams = {
  startAt: string | undefined;
  endAt: string | undefined;
};

type ReturnType = [
  searchParamsState: TimeRangeSearchParams,
  setSearchParamsState: (newState: TimeRangeSearchParams) => void,
];

type CreatedAtRawTypes = {
  created_on_or_after: UniversalType<string | undefined>;
  created_on_or_before: UniversalType<string | undefined>;
};

export const useTypedCreatedAtRangeParams = <
  TPath extends string,
  TPathTypes,
  TSearchTypes extends CreatedAtRawTypes,
  THash extends string[],
  TStateTypes,
>(
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>
): ReturnType => {
  const [params, setParams] = useTypedSearchParams(route);

  return [
    { startAt: params.created_on_or_after, endAt: params.created_on_or_before },
    ({ startAt, endAt }) =>
      setParams(
        (p) =>
          ({
            ...p,
            created_on_or_after: startAt,
            created_on_or_before: endAt,
          }) as Partial<InSearchParams<TSearchTypes>>
      ),
  ];
};

type RawTypes = {
  on_or_after: UniversalType<string | undefined>;
  on_or_before: UniversalType<string | undefined>;
};

export const useTypedRangeParams = <
  TPath extends string,
  TPathTypes,
  TSearchTypes extends RawTypes,
  THash extends string[],
  TStateTypes,
>(
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>
): ReturnType => {
  const [params, setParams] = useTypedSearchParams(route);

  return [
    { startAt: params.on_or_after, endAt: params.on_or_before },
    ({ startAt, endAt }) =>
      setParams(
        (p) =>
          ({
            ...p,
            on_or_after: startAt,
            on_or_before: endAt,
          }) as Partial<InSearchParams<TSearchTypes>>
      ),
  ];
};
