import React, { useCallback, useState } from 'react';
import { Button } from './deprecated/Button';
import { MultiImageTool } from './check-workflow/image-tool';
import { OperatorNotes } from './operator-notes';
import { useNavigate } from 'react-router';
import { useIdentityDocumentGet, useIdentityDocumentPatch } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { IdentityDocumentGetResponse } from 'src/build/operations';
import { PageLayout } from './deprecated/PageLayout';
import { TextInput } from './deprecated/TextInput';
import { StyledLink } from './deprecated/StyledLink';
import { Box } from '@increase/shared/components/Box';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
type ActionRowProps = {
  identityDocument: IdentityDocumentGetResponse;
  canVerify: boolean;
};
const ActionRow = ({
  identityDocument,
  canVerify
}: ActionRowProps) => {
  const [forceVerifyJustification, setForceVerifyJustification] = useState('');
  const identityDocumentPatch = useIdentityDocumentPatch();
  const navigate = useNavigate();
  const action = useCallback((approve: boolean, note?: string) => {
    identityDocumentPatch.mutateAsync([identityDocument.id, {
      identity_verification_status: approve ? 'verified' : 'falsified',
      note
    }]).then(() => navigate('/identity_documents'));
  }, [identityDocument, identityDocumentPatch, navigate]);
  const status = identityDocument.identity_verification_status;
  if (status === 'pending_verification') {
    return <div className="flex items-center space-x-2">
        <Button data-testid="identityDocumentReview.falsify" onClick={() => action(false)}>
          Falsify
        </Button>
        {canVerify && <Button data-testid="identityDocumentReview.verify" onClick={() => action(true)}>
            Verify
          </Button>}
      </div>;
  } else {
    return <div>
        <div className="max-w-lg">
          This identity document is:{' '}
          {identityDocument.identity_verification_status}
        </div>
        {status === 'falsified' && <div>
            <div>
              <TextInput name="forceVerifyJustification" value={forceVerifyJustification} onChange={s => setForceVerifyJustification(s)} placeholder="Justification" />
              <div>
                <Button id="identityDocumentReview.forceVerify" onClick={() => action(true, forceVerifyJustification)}>
                  Force Verify
                </Button>
              </div>
            </div>
          </div>}
      </div>;
  }
};
const Images = (props: {
  identityDocument: NonNullable<IdentityDocumentGetResponse>;
}) => {
  const [selected, setSelected] = useState(0);
  const {
    operations
  } = useServices();
  const imageURL = (fileID: string) => `${operations}/api_files/${fileID}/view`;
  const fileId = selected === 0 ? props.identityDocument.api_file_id : props.identityDocument.api_back_file_id;
  const segments = [{
    title: 'Front',
    selected: selected === 0,
    onSelect: () => setSelected(0)
  }];
  if (props.identityDocument.api_back_file_id) {
    segments.push({
      title: 'Reverse',
      selected: selected === 1,
      onSelect: () => setSelected(1)
    });
  }
  return <Box gap="1" className="border-2 border-solid border-stronger p-3" data-sentry-element="Box" data-sentry-component="Images" data-sentry-source-file="identity-document-review.tsx">
      <SegmentedControl segments={segments} data-sentry-element="SegmentedControl" data-sentry-source-file="identity-document-review.tsx" />
      {fileId ? <MultiImageTool selectionMode="none" url={imageURL(fileId)} size={700} /> : <div>No image available</div>}
    </Box>;
};
export const IdentityDocumentReview = () => {
  const {
    identityDocumentID
  } = useTypedParams(ROUTES.IDENTITY_DOCUMENT_REVIEW);
  const [nameMatch, setNameMatch] = useState(false);
  const [birthdateMatch, setBirthdateMatch] = useState(false);
  const [idNumberMatch, setIdNumberMatch] = useState(false);
  const [addressMatch, setAddressMatch] = useState(false);
  const {
    data: identityDocument
  } = useIdentityDocumentGet(identityDocumentID);
  if (!identityDocument) {
    return null;
  }
  const canVerify = nameMatch && [birthdateMatch, idNumberMatch, addressMatch].filter(match => match).length >= 2;
  return <PageLayout id="application.identityDocumentReview" headline="Identity Document Review" data-sentry-element="PageLayout" data-sentry-component="IdentityDocumentReview" data-sentry-source-file="identity-document-review.tsx">
      <StyledLink to={buildPath(ROUTES.GROUPS_DETAIL, {
      groupId: identityDocument.group_id
    })} data-sentry-element="StyledLink" data-sentry-source-file="identity-document-review.tsx">
        {identityDocument.group_id}
      </StyledLink>
      <Images identityDocument={identityDocument} data-sentry-element="Images" data-sentry-source-file="identity-document-review.tsx" />

      <Box flex justifyCenter data-sentry-element="Box" data-sentry-source-file="identity-document-review.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="identity-document-review.tsx">
          <h6>The document must match name:</h6>
          <ul>
            <li>
              <input data-testid="nameMatch" type="checkbox" name="nameMatch" checked={nameMatch} onChange={e => setNameMatch(e.target.checked)} />{' '}
              This document is for{' '}
              <strong>{identityDocument.submitted_name}</strong>
            </li>
          </ul>
          <h6>And two out of the following three:</h6>
          <ul>
            <li>
              <input data-testid="birthdateMatch" type="checkbox" name="birthdateMatch" checked={birthdateMatch} onChange={e => setBirthdateMatch(e.target.checked)} />{' '}
              Birthdate{' '}
              <strong>{identityDocument.submitted_date_of_birth}</strong>
            </li>
            <li>
              <input data-testid="addressMatch" type="checkbox" name="addressMatch" checked={addressMatch} onChange={e => setAddressMatch(e.target.checked)} />{' '}
              The address is{' '}
              <strong>
                {[identityDocument.submitted_address.line1, identityDocument.submitted_address.line2, identityDocument.submitted_address.city, identityDocument.submitted_address.state].filter(part => !!part).join(', ')}{' '}
                {identityDocument.submitted_address.zip}
              </strong>
            </li>
            <li>
              <input data-testid="idNumberMatch" type="checkbox" name="idNumberMatch" checked={idNumberMatch} onChange={e => setIdNumberMatch(e.target.checked)} />{' '}
              The identifying number is{' '}
              <strong>{identityDocument.submitted_document_number}</strong>
            </li>
          </ul>
        </Box>

        <PropertyList className="flex-1" items={compact<ListItem>([{
        label: 'Document type',
        value: identityDocument.submitted_document_type
      }, {
        label: 'Issuing country',
        value: identityDocument.submitted_country
      }, identityDocument.submitted_state && {
        label: 'Issuing state',
        value: identityDocument.submitted_state
      }])} data-sentry-element="PropertyList" data-sentry-source-file="identity-document-review.tsx"></PropertyList>
      </Box>

      <ActionRow identityDocument={identityDocument} canVerify={canVerify} data-sentry-element="ActionRow" data-sentry-source-file="identity-document-review.tsx" />
      <hr />
      <OperatorNotes modelId={identityDocument.id} data-sentry-element="OperatorNotes" data-sentry-source-file="identity-document-review.tsx" />
    </PageLayout>;
};