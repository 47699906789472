import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Body } from '@increase/shared/components/Text';
import { formatAddress, humanize } from '@increase/shared/lib/formatting';
import { BeneficialOwnerGetResponse, BeneficialOwnerGetResponseIdentityVerificationStatus, BeneficialOwnerGetResponseTaxIdValidationStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
type EntityPropertyListProps = {
  beneficialOwner: NonNullable<BeneficialOwnerGetResponse>;
  entityId?: string | null;
} & Omit<PropertyListProps, 'items'>;
const identityVerificationStatusBadgeColor: Record<BeneficialOwnerGetResponseIdentityVerificationStatus, BadgeColor> = {
  pending_verification: 'blue',
  update_pending_verification: 'blue',
  verified: 'green',
  falsified: 'yellow',
  no_match: 'yellow',
  verifying: 'blue',
  requires_attention: 'yellow',
  not_performed: 'gray'
};
const taxIdValidationStatusBadgeColor: Record<BeneficialOwnerGetResponseTaxIdValidationStatus, BadgeColor> = {
  pending_validating: 'blue',
  update_pending_validating: 'blue',
  requires_attention: 'yellow',
  failed: 'yellow',
  validated: 'green',
  not_provided: 'gray',
  skipped: 'gray'
};
export const EntityBeneficialOwnerPropertyList = (props: EntityPropertyListProps) => {
  const {
    beneficialOwner,
    ...rest
  } = props;
  return <PropertyList {...rest} action={<InternalOperatorOnly>
          <StyledLink style="underline" href={buildPath(ROUTES.ENTITY_ADDRESS_VERIFICATION, {
      addressableId: beneficialOwner.id
    })}>
            <Body>Address Verification</Body>
          </StyledLink>
        </InternalOperatorOnly>} items={compact<ListItem>([{
    label: 'Name',
    value: beneficialOwner.submitted_name
  }, {
    label: 'Date of birth',
    value: beneficialOwner.submitted_date_of_birth
  }, {
    label: 'Tax ID',
    value: beneficialOwner.submitted_identification_number_last4 ? `****${beneficialOwner.submitted_identification_number_last4}` : '-'
  }, {
    label: 'Tax ID type',
    value: beneficialOwner.submitted_identification_method ?? '-'
  }, {
    label: 'Identity validation status',
    value: humanize(beneficialOwner.identity_verification_status),
    badgeColor: identityVerificationStatusBadgeColor[beneficialOwner.identity_verification_status]
  }, {
    label: 'Sovos SSN Validation Return Code',
    value: humanize(beneficialOwner.tax_id_validation_status),
    badgeColor: taxIdValidationStatusBadgeColor[beneficialOwner.tax_id_validation_status]
  }, beneficialOwner.submission_sovos_response_id && {
    label: 'Sovos Raw Response',
    href: buildPath(ROUTES.ENTITY_SOVOS_RESPONSE, {
      sovosResponseId: beneficialOwner.submission_sovos_response_id
    }),
    value: 'View'
  }, {
    label: 'Address',
    value: formatAddress(beneficialOwner.submitted_address)
  }, props.entityId && {
    label: 'Entity ID',
    value: props.entityId,
    href: buildPath(ROUTES.ENTITY, {
      entityId: props.entityId
    })
  }])} data-sentry-element="PropertyList" data-sentry-component="EntityBeneficialOwnerPropertyList" data-sentry-source-file="EntityBeneficialOwnerPropertyList.tsx" />;
};