import { Caption } from '@increase/shared/components/Text';
import { useState } from 'react';
import { Button } from './Button';
import { DialogContext } from '@increase/shared/components/Modal';
import tinykeys from 'tinykeys';
import { useContext, useEffect, useId } from 'react';
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/react/20/solid';
const ALLOWED_SHORTCUTS = {
  '⌘.': '$mod+.'
} as const;
export const CopyButton = (props: {
  text: string;
  keyboardShortcut?: keyof typeof ALLOWED_SHORTCUTS;
}) => {
  const [done, setDone] = useState(false);
  const id = useId();
  const {
    modalState,
    searchOpen
  } = useContext(DialogContext);
  // Se shared/components/Button.tsx
  useEffect(() => {
    if (!props.keyboardShortcut) {
      return;
    }
    const anyDialogIsOpen = modalState !== 'none' || searchOpen;
    const unsubscribe = tinykeys(window, {
      [ALLOWED_SHORTCUTS[props.keyboardShortcut]]: e => {
        const activeElement = document.activeElement;
        const isInputActive = activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || (activeElement as HTMLElement).isContentEditable);
        if (isInputActive) {
          return;
        }
        if (anyDialogIsOpen) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        document.getElementById(id)?.click();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [props.keyboardShortcut, id, modalState, searchOpen]);
  const icon = done ? <ClipboardDocumentCheckIcon className="h-4 w-4" /> : <ClipboardDocumentIcon className="h-4 w-4" />;
  return <Button id={id} className="bg-main" onClick={() => {
    navigator.clipboard.writeText(props.text);
    setDone(true);
    setTimeout(() => setDone(false), 1000);
  }} data-sentry-element="Button" data-sentry-component="CopyButton" data-sentry-source-file="CopyButton.tsx">
      {props.keyboardShortcut ? <div className="flex items-center">
          {icon}
          <div className={'hidden translate-x-0.5 rounded-sm border border-strong bg-strong px-1 text-subtle md:block'}>
            <Caption weight="medium" color="inherit">
              {props.keyboardShortcut}
            </Caption>
          </div>
        </div> : icon}
    </Button>;
};