import React, { useMemo } from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useProgramACHReturnRateListInfinite } from 'src/hooks/reactQuery';
import { ProgramACHReturnRateListResponseDataItem } from 'src/build/operations';
import { Contents, PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { formatISO8601Timestamp, formatInteger, formatPercentage } from '@increase/shared/lib/formatting';
import { makeEnumFilter, makeGroupFilter, makeProgramFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { useTypedRangeParams } from 'src/hooks/timeSearchParameters';
export const THRESHOLDS = {
  overall: 0.15,
  administrative: 0.03,
  unauthorized: 0.005
};
const renderRate = (rate: string, threshold: number): Contents => {
  const rateAsNumber = parseFloat(rate);
  return rateAsNumber > threshold ? {
    text: formatPercentage(rateAsNumber),
    badgeColor: 'red'
  } : {
    text: formatPercentage(rateAsNumber)
  };
};
const ACHReturnRateTable = (props: PrestyledTableProps<ProgramACHReturnRateListResponseDataItem>) => {
  const columns: TableColumns<ProgramACHReturnRateListResponseDataItem> = [{
    header: 'Group',
    contents: row => ({
      text: row.group_name || row.group_id,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: row.group_id
      })
    })
  }, {
    header: 'Program',
    contents: row => ({
      text: row.program_name,
      href: buildPath(ROUTES.PROGRAMS_DETAIL, {
        programId: row.program_id
      })
    })
  }, {
    header: 'Period ending',
    contents: record => ({
      text: formatISO8601Timestamp(record.period_end, 'medium')
    })
  }, {
    header: 'Count ACH Debits',
    contents: row => ({
      text: formatInteger(row.count_ach_debits)
    })
  }, {
    header: 'Count returns',
    contents: row => ({
      text: formatInteger(row.count_ach_returns)
    })
  }, {
    header: 'Overall',
    contents: row => renderRate(row.overall_return_rate, THRESHOLDS.overall)
  }, {
    header: 'Administrative',
    contents: row => renderRate(row.administrative_return_rate, THRESHOLDS.administrative)
  }, {
    header: 'Unauthorized',
    contents: row => renderRate(row.unauthorized_return_rate, THRESHOLDS.unauthorized)
  }];
  const getRowProps = (datum: ProgramACHReturnRateListResponseDataItem) => ({
    href: buildPath(ROUTES.ACH_RETURN_RATES_DETAIL, {
      achReturnRateID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table {...props} columns={columns} getRowProps={getRowProps} data-sentry-element="Table" data-sentry-component="ACHReturnRateTable" data-sentry-source-file="ach-return-rates.tsx" />;
};
export const ACHReturnRates = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.ACH_RETURN_RATES_LIST, 'group');
  const [programFilter, setProgramFilter] = useSearchParamsState(ROUTES.ACH_RETURN_RATES_LIST, 'program');
  const [thresholdFilter, setThresholdFilter] = useSearchParamsState(ROUTES.ACH_RETURN_RATES_LIST, 'threshold');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedRangeParams(ROUTES.ACH_RETURN_RATES_LIST);
  const filters = useMemo(() => [makeGroupFilter(groupFilter, setGroupFilter), makeProgramFilter(programFilter, setProgramFilter), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Date'), makeEnumFilter(thresholdFilter, setThresholdFilter, 'Threshold', {
    over: 'Over',
    under: 'Under'
  }, 'shield_tick')], [groupFilter, programFilter, setGroupFilter, setProgramFilter, startAt, endAt, setTimeRange, thresholdFilter, setThresholdFilter]);
  const listResponse = useProgramACHReturnRateListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    program_ids: programFilter.length > 0 ? programFilter : undefined,
    period_start: startAt ?? undefined,
    period_end: endAt ?? undefined,
    on_or_over_threshold: thresholdFilter.includes('over') ? true : thresholdFilter.includes('under') ? false : undefined
  });
  return <PageLayout headline="ACH Return Rates" data-sentry-element="PageLayout" data-sentry-component="ACHReturnRates" data-sentry-source-file="ach-return-rates.tsx">
      <TableStateWrapper table={ACHReturnRateTable} {...listResponse} style="primary" emptySubtitle="No ACH return rates found." emptyTitle="No ACH return rates found." filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="ach-return-rates.tsx" />
    </PageLayout>;
};