import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { humanize } from '@increase/shared/lib/formatting';
import { CustomerIdentificationProgramTestingEntryListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<CustomerIdentificationProgramTestingEntryListResponseDataItem> & {
  showBatch: boolean;
};
export const testingStatusColors: Record<CustomerIdentificationProgramTestingEntryListResponseDataItem['testing_status'], BadgeColor> = {
  pending_reviewing: 'yellow',
  reviewed_without_remediations: 'gray',
  reviewed_with_remediation: 'gray',
  pending_remediation: 'red'
};
const finalStatus: Record<Exclude<CustomerIdentificationProgramTestingEntryListResponseDataItem['testing_status'], 'pending_reviewing'>, 'OK' | 'Waiting on user'> = {
  reviewed_without_remediations: 'OK',
  reviewed_with_remediation: 'OK',
  pending_remediation: 'Waiting on user'
};
const finalBadge: Record<Exclude<CustomerIdentificationProgramTestingEntryListResponseDataItem['testing_status'], 'pending_reviewing'>, BadgeColor> = {
  reviewed_without_remediations: 'green',
  reviewed_with_remediation: 'green',
  pending_remediation: 'red'
};
export const evaluationColors: Record<CustomerIdentificationProgramTestingEntryListResponseDataItem['original_automated_evaluation'], BadgeColor> = {
  valid: 'green',
  invalid: 'red',
  indeterminate: 'red'
};
const defaultRowProps = (datum: CustomerIdentificationProgramTestingEntryListResponseDataItem) => ({
  className: 'hover:bg-main-hover cursor-pointer transition-all group',
  href: buildPath(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS, {
    entryId: datum.id
  })
});
export const CustomerIdentificationProgramTestingEntryTable = (props: Props) => {
  const {
    getRowProps
  } = props;
  const columns = useMemo<TableColumns<CustomerIdentificationProgramTestingEntryListResponseDataItem>>(() => {
    return [{
      header: 'Entity',
      expand: 1,
      contents: entry => {
        return {
          text: entry.entity_name,
          href: buildPath(ROUTES.ENTITY, {
            entityId: entry.entity_id
          })
        };
      }
    }, {
      header: 'Program',
      expand: 1,
      contents: entry => {
        return {
          text: entry.bank_program_name,
          href: buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
            bankProgramId: entry.bank_program_id
          })
        };
      }
    }, {
      header: 'Group',
      expand: 1,
      contents: entry => {
        return {
          text: entry.entity_group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: entry.entity_group_id
          })
        };
      }
    }, {
      header: 'Status',
      expand: 1,
      contents: entry => {
        return {
          text: humanize(entry.testing_status),
          badgeColor: testingStatusColors[entry.testing_status]
        };
      }
    }, props.showBatch && {
      header: 'Batch',
      expand: 1,
      contents: entry => {
        return {
          text: `${entry.batch.period_start} through ${entry.batch.period_end}`,
          href: buildPath(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS, {
            batchId: entry.batch.id
          })
        };
      }
    }, {
      header: 'Original Evaluation',
      expand: 1,
      contents: entry => {
        return {
          text: humanize(entry.original_automated_evaluation),
          badgeColor: evaluationColors[entry.original_automated_evaluation]
        };
      }
    }, {
      header: 'Final determination',
      expand: 1,
      contents: entry => {
        return entry.testing_status === 'pending_reviewing' ? {
          text: ''
        } : {
          text: finalStatus[entry.testing_status],
          badgeColor: finalBadge[entry.testing_status]
        };
      }
    }];
  }, [props.showBatch]);
  return <Table {...props} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="CustomerIdentificationProgramTestingEntryTable" data-sentry-source-file="CustomerIdentificationProgramTestingEntryTable.tsx" />;
};