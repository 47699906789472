import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useDocumentRequestListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem, DocumentRequestListResponseDataItemStatus, DocumentRequestListSourceCategoriesItem } from 'src/build/operations';
import { makeStatusFilter, makeGroupFilter, makeProgramFilter, makeTimeRangeFilter, makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { DocumentRequestsTable } from '../tables/DocumentRequestsTable';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { DateTime } from 'luxon';
const documentRequestStatuses: Record<DocumentRequestListResponseDataItemStatus, string> = {
  awaiting_submission: 'Awaiting Submission',
  awaiting_review: 'Awaiting Review',
  accepted: 'Accepted',
  canceled: 'Canceled',
  provisionally_accepted: 'Provisionally Accepted'
} as const;
export const DocumentRequestListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.DOCUMENT_REQUESTS_LIST, 'group');
  const [programFilter, setProgramFilter] = useSearchParamsState(ROUTES.DOCUMENT_REQUESTS_LIST, 'program');
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.DOCUMENT_REQUESTS_LIST, 'statuses');
  const [categoriesFilter, setCategoriesFilter] = useSearchParamsState(ROUTES.DOCUMENT_REQUESTS_LIST, 'categories');
  const [subcategoriesFilter, setSubcategoriesFilter] = useSearchParamsState(ROUTES.DOCUMENT_REQUESTS_LIST, 'subcategories');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.DOCUMENT_REQUESTS_LIST);
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeStatusFilter(statuses, setStatuses, documentRequestStatuses), makeGroupFilter(groupFilter, setGroupFilter), makeProgramFilter(programFilter, setProgramFilter), makeEnumFilter(categoriesFilter, setCategoriesFilter, 'Category', DocumentRequestListSourceCategoriesItem, 'pile'), makeEnumFilter(subcategoriesFilter, setSubcategoriesFilter, 'Sub-Category', DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem, 'file_writing'), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created')]), [groupFilter, setGroupFilter, setStatuses, statuses, programFilter, setProgramFilter, startAt, endAt, setTimeRange, categoriesFilter, setCategoriesFilter, subcategoriesFilter, setSubcategoriesFilter]);
  const listResult = useDocumentRequestListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    group_ids: groupFilter.length > 0 ? groupFilter : undefined,
    program_ids: programFilter.length > 0 ? programFilter : undefined,
    source_categories: categoriesFilter.length > 0 ? categoriesFilter : undefined,
    platform_periodic_templated_spreadsheet_report_request_categories: subcategoriesFilter.length > 0 ? subcategoriesFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined
  });
  return <PageLayout headline={'Document Requests'} data-sentry-element="PageLayout" data-sentry-component="DocumentRequestListPage" data-sentry-source-file="DocumentRequestListPage.tsx">
      <TableStateWrapper table={DocumentRequestsTable} style="primary" {...listResult} emptyTitle="No Document Requests" emptySubtitle="There are no DocumentRequests to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="DocumentRequestListPage.tsx" />
    </PageLayout>;
};