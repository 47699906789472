import { ReactNode } from 'react';
import { OperatorGetResponseRole } from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';
type Props = {
  children: ReactNode;
  fallback?: ReactNode;
  additionalRoles?: OperatorGetResponseRole[];
};
export const InternalOperatorOnly = ({
  children,
  fallback,
  additionalRoles = []
}: Props) => {
  const {
    data: operator
  } = useOperatorGet({});
  if (operator && (operator.role === OperatorGetResponseRole.internal || additionalRoles.includes(operator?.role))) {
    return <>{children}</>;
  } else {
    return <>{fallback}</>;
  }
};