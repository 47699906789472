;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"l3eGeJ0SF7BZbatqyRvmj"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://d423b56176fbaf89235763ce27424a23@o336169.ingest.us.sentry.io/4508172977897472',
  // Setting this option to true will print useful information to the console while you're configuring Sentry.
  debug: false,
});
