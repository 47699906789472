import { Heading } from '@increase/shared/components/Text';
type SectionProps = {
  header?: string | React.ReactNode;
  children: React.ReactNode;
};
export const Section = (props: SectionProps) => {
  return <div className="space-y-2" data-sentry-component="Section" data-sentry-source-file="Section.tsx">
      {props.header && typeof props.header === 'string' ? <Heading>{props.header}</Heading> : props.header}
      <div>{props.children}</div>
    </div>;
};