import { ManagementInformationSystemProgramCountsListResponseData, ManagementInformationSystemVolumesListPeriod } from 'src/build/operations';
import { PercentChange } from './PercentChange';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { formatInteger } from '@increase/shared/lib/formatting';
type Props = {
  data: ManagementInformationSystemProgramCountsListResponseData;
  period: ManagementInformationSystemVolumesListPeriod;
};
export const ProgramCounts = ({
  data,
  period
}: Props) => {
  return <PropertyList items={[{
    label: 'Program count',
    value: formatInteger(data.number_programs)
  }, {
    label: `Period Change ${period}`,
    valueNode: <PercentChange previous={data.lookback_number_of_programs} current={data.number_programs} />
  }]} data-sentry-element="PropertyList" data-sentry-component="ProgramCounts" data-sentry-source-file="program-counts.tsx" />;
};