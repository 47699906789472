import React from 'react';
import { useOperatorManualQueueItemListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { OperatorManualQueueItemListQueueItem, OperatorManualQueueItemListStatusItem } from 'src/build/operations';
export const CheckItemsToAllocate = () => {
  const queueItems = useOperatorManualQueueItemListInfinite({
    queue: [OperatorManualQueueItemListQueueItem.inbound_check_item_allocation],
    status: [OperatorManualQueueItemListStatusItem.pending_actioning]
  });
  const data = queueItems.data?.pages.flatMap(page => page.data) ?? [];
  return <PageLayout id="application.inboundCheckItemAllocationQueue" headline="Inbound Check Item Allocation Queue" data-sentry-element="PageLayout" data-sentry-component="CheckItemsToAllocate" data-sentry-source-file="check-items-to-allocate.tsx">
      <div>
        <em>
          {data.length} Inbound Check {data.length === 1 ? 'Item' : 'Items'}
          {data.length === 0 && ' 🎉'}
        </em>
        <ItemList items={data.map(item => ({
        id: item.id,
        text: item.id,
        detail: item.created_at,
        rightDetail: item.record_id || undefined,
        href: item.link || undefined,
        rightText: 'Allocate'
      }))} data-sentry-element="ItemList" data-sentry-source-file="check-items-to-allocate.tsx" />
      </div>
    </PageLayout>;
};