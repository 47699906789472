import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Button } from '../Button';
import { Link } from 'react-router-dom';
import { IconType } from '../Icon';
import { Caption } from '../Text';
import { BadgeColor } from '../Badge';
import { MenuItem } from '../MenuLayout';
import tinykeys from 'tinykeys';
import { MobileTableCellContents, TableCellContents } from './contents';
import { Falsey } from 'lodash';
export const STATUS_INDICATOR_COLOR_CLASSES = {
  blue: 'bg-info-strong',
  gray: 'bg-disabled-strong',
  green: 'bg-success-strong',
  red: 'bg-danger-strong',
  yellow: 'bg-warning-strong',
  none: 'bg-transparent'
};
export type StatusIndicatorColor = keyof typeof STATUS_INDICATOR_COLOR_CLASSES;
export type Contents =
// Text or link
{
  text: string;
  textColor?: 'emphasis' | 'primary' | 'secondary';
  textWeight?: 'normal' | 'medium' | 'bold' | 'mono';
  icon?: IconType;
  iconClassname?: string;
  strikeThrough?: boolean;
  href?: string;
  tooltip?: string;
  tooltipIcon?: IconType;
  statusIndicator?: StatusIndicatorColor;
}
// Badge
| {
  text: string;
  icon?: IconType;
  badgeColor: BadgeColor;
  statusIndicator: never;
}
// Actions
| {
  menuItems: MenuItem[];
};
export type MobileDisplayContents = {
  icon?: IconType;
  statusIndicator?: StatusIndicatorColor;
  text: string;
  textWeight?: 'normal' | 'medium' | 'bold' | 'mono'; // defaults to medium
  textColor?: 'emphasis' | 'primary' | 'secondary' | 'red'; // defaults to primary
  strikeThrough?: boolean; // defaults to false
  caption?: string;
} | {
  menuItems: MenuItem[];
};
export type TableCellComponent<T> = React.FC<{
  datum: T;
  rowIdx: number;
}>;
export type ColumnContents<T> = {
  contents: (data: T) => Contents;
} | {
  // Escape hatch
  CellComponent: TableCellComponent<T>;
};
type BaseColumn<T> = ColumnContents<T> & {
  header: string;
  align?: 'left' | 'right'; // defaults to left
  expand?: 2 | 1 | 0; // defaults to 0
};
export type MobileColumn<T> = BaseColumn<T> & {
  mobileContents?: (data: T) => MobileDisplayContents;
  mobileHeader?: string;
};
const TABLE_WIDTHS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
} as const;
export type DesktopColumn<T> = BaseColumn<T> & {
  hideBelowTableWidth?: keyof typeof TABLE_WIDTHS;
};
export type TableColumns<T> = [MobileColumn<T> | Falsey, ...(DesktopColumn<T> | Falsey)[], MobileColumn<T> | Falsey];
export interface Identifiable {
  id: string;
}
export const TableExportButton = (props: {
  onClick: () => void;
}) => <Button size="small" style="secondary" text="Export" keyboardShortcut="E" icon="download" onClick={props.onClick} data-sentry-element="Button" data-sentry-component="TableExportButton" data-sentry-source-file="index.tsx" />;
export type TableProps<T extends Identifiable> = {
  data: T[];
  columns: TableColumns<T>;
  getRowProps?: (datum: T, rowIdx: number) => {
    onClick?: (e: React.MouseEvent) => void;
    className: string | undefined;
  } | {
    href: string | undefined;
    className: string | undefined;
  };
  renderHeaderForRow?: (datum: T, rowIdx: number) => React.ReactNode | undefined;
  renderFooterForRow?: (datum: T, rowIdx: number) => React.ReactNode | undefined;
  selectedRows?: string[];
  onSelectedRowsChange?: (selections: string[]) => void;
  selectable?: (row: T) => boolean;
};
export type PrestyledTableProps<T extends Identifiable> = Omit<TableProps<T>, 'columns'>;
const SelectionRowHeader = '___selection_row_header___';
const allSelectable = () => true;
export const Table = <T extends Identifiable,>({
  columns: _columns,
  data,
  getRowProps,
  renderHeaderForRow,
  renderFooterForRow,
  selectedRows,
  onSelectedRowsChange,
  selectable = allSelectable
}: TableProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [renderedTableWidth, setRenderedTableWidth] = useState(0);
  const handleToggleSelectAll = useCallback(() => {
    if (!selectedRows || !onSelectedRowsChange) {
      return;
    }
    const anyAreSelected = selectedRows.length > 0;
    const newSelections = anyAreSelected ? [] : data.filter(d => selectable(d)).map(d => d.id);
    onSelectedRowsChange(newSelections);
  }, [data, onSelectedRowsChange, selectedRows, selectable]);
  useEffect(() => {
    if (!onSelectedRowsChange) {
      return;
    }
    const unsubscribe = tinykeys(window, {
      '$mod+a': e => {
        e.preventDefault();
        handleToggleSelectAll();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [onSelectedRowsChange, handleToggleSelectAll]);
  const checkboxAllSelectorId = useId();

  // Columns can say they want to expand by 0, 1 or 2. We need to normalize this to a percentage
  // Columns which don't expand don't need a css width property. That property needs to account for
  // the columns which may be hidden at a given render width.
  const columns = useMemo(() => {
    const notNull: (MobileColumn<T> | DesktopColumn<T>)[] = _columns.filter((c): c is MobileColumn<T> | DesktopColumn<T> => typeof c === 'object' && c !== null && 'header' in c && !!c.header);
    const columnsHidden = notNull.map((column, columnIdx) => {
      const specifiedMinWidth = 'hideBelowTableWidth' in column ? column.hideBelowTableWidth : 'sm';
      const minWidth = columnIdx === 0 || columnIdx === notNull.length - 1 // First and last columns are always shown
      ? undefined : specifiedMinWidth;
      const hidden = minWidth ? renderedTableWidth < TABLE_WIDTHS[minWidth] : false;
      return {
        ...column,
        hidden
      };
    });
    const expansionPoints = columnsHidden.filter(c => !c.hidden).reduce((sum, c) => sum + (c.expand ?? 0), 0);
    const isMobileWidth = renderedTableWidth < TABLE_WIDTHS.sm;
    const columnsCSSWidth = columnsHidden.map((column, columnIdx) => {
      const expand = column.expand ?? 0;
      const cssWidthIfDesktop = expand ? `${expand / expansionPoints * 100}%` : undefined;
      const columnWidthIfMobile = columnIdx === 0 && isMobileWidth ? '100%' : undefined;
      const cssWidth = isMobileWidth ? columnWidthIfMobile : cssWidthIfDesktop;
      return {
        ...column,
        cssWidth
      };
    });
    if (!selectedRows) {
      return columnsCSSWidth;
    }
    const selectionColumn: (typeof columnsCSSWidth)[number] = {
      header: SelectionRowHeader,
      cssWidth: undefined,
      hidden: false,
      CellComponent: ({
        datum
      }) => <input className={classNames('border-strong text-strong ring-offset-main focus:ring-main cursor-pointer rounded-sm shadow-sm ring-offset-[2px] transition-colors focus:ring-[2px]')} type="checkbox" checked={selectedRows.includes(datum.id)} onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }} disabled={!selectable(datum)} onChange={() => {
        const wasSelected = selectedRows.includes(datum.id);
        return wasSelected ? onSelectedRowsChange?.(selectedRows.filter(id => id !== datum.id)) : onSelectedRowsChange?.([...selectedRows, datum.id]);
      }} />
    };
    const withSelectionColumns = [selectionColumn, ...columnsCSSWidth];
    return withSelectionColumns;
  }, [_columns, onSelectedRowsChange, renderedTableWidth, selectable, selectedRows]);
  useEffect(() => {
    const rowSelectionStatus = selectedRows?.length === 0 ? 'none' : selectedRows?.length === data.length ? 'all' : 'some';
    const checkbox = document.getElementById(checkboxAllSelectorId) as HTMLInputElement;
    if (checkbox) {
      checkbox.indeterminate = rowSelectionStatus === 'some';
    }
  }, [checkboxAllSelectorId, data.length, selectedRows]);
  useEffect(() => {
    function onResize() {
      if (ref.current && ref.current.clientWidth > 0) {
        setRenderedTableWidth(ref.current.clientWidth);
      }
    }
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref.current?.clientWidth, columns, selectedRows]);
  const isNarrowScreen = renderedTableWidth < TABLE_WIDTHS.sm;
  return <div ref={ref} data-sentry-component="Table" data-sentry-source-file="index.tsx">
      {/* Remove overflow-hidden */}
      <div>
        <div className="divide-main border-main bg-main table min-w-full max-w-[calc(100vw-32px)] table-auto border-collapse divide-y border-t last:border-y">
          <div className="table-header-group">
            <div className="table-row">
              {columns.map((column, idx) => <div key={idx} style={{
              width: column.cssWidth
            }} className={classNames('whitespace-nowrap p-2 align-middle', column.hidden ? 'hidden' : 'table-cell', idx === 0 && 'pl-[2px]', idx === columns.length - 1 && 'pr-[2px]')}>
                  <div className={classNames('flex items-center', column.align === 'right' ? 'justify-end' : 'justify-start')}>
                    {/* Use a magic string for the Select all header */}
                    {column.header === SelectionRowHeader && selectedRows ? <div className="flex items-center justify-center">
                        <input key={'checkbox-all'} className={classNames('border-strong text-strong ring-offset-main focus:ring-main cursor-pointer rounded-sm shadow-sm ring-offset-[2px] transition-colors focus:ring-[2px] disabled:cursor-default disabled:opacity-50')} type="checkbox" checked={selectedRows.length > 0} id={checkboxAllSelectorId} disabled={data.length === 0} onChange={handleToggleSelectAll} />
                      </div> : <Caption color="secondary" contents={isNarrowScreen && 'mobileHeader' in column && column.mobileHeader ? column.mobileHeader : column.header} />}
                  </div>
                </div>)}
            </div>
          </div>
          <div className="table-row-group">
            {data.map((datum, rowIdx) => {
            const header = renderHeaderForRow ? renderHeaderForRow(datum, rowIdx) : undefined;
            const footer = renderFooterForRow ? renderFooterForRow(datum, rowIdx) : undefined;
            const rowProps: {
              href?: string;
              onClick?: (e: React.MouseEvent) => void;
              className?: string;
            } = getRowProps ? getRowProps(datum, rowIdx) : {};
            const href = 'href' in rowProps ? rowProps.href : undefined;
            const onClick = 'onClick' in rowProps ? rowProps.onClick : undefined;
            const className = 'className' in rowProps ? rowProps.className : undefined;
            const cells = <Fragment key={datum.id}>
                  {columns.map((column, columnIdx) => {
                const contents = 'mobileContents' in column && column.mobileContents && isNarrowScreen ? <MobileTableCellContents contents={column.mobileContents(datum)} align={column.align ?? 'left'} /> : 'contents' in column ? <TableCellContents contents={column.contents(datum)} /> : <column.CellComponent datum={datum} rowIdx={rowIdx} />;
                return <td key={columnIdx} className={classNames(column.cssWidth && `max-w-[1px]`, column.hidden ? 'hidden' : 'table-cell', 'h-[36px] px-2 py-3 align-middle md:py-1', columnIdx === 0 && 'pl-[2px]', columnIdx === columns.length - 1 && 'pr-[2px]')}>
                        <div className={classNames('flex items-center whitespace-nowrap', column.align === 'right' ? 'justify-end' : 'justify-start')}>
                          {contents}
                        </div>
                      </td>;
              })}
                </Fragment>;
            return <Fragment key={datum.id}>
                  {/* There's generally not a header */}
                  {header && <div className={classNames('table-row', 'focus-visible:ring-main transition focus-visible:z-20 focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-offset-1', rowIdx !== 0 && 'border-main border-t')}>
                      <td colSpan={columns.length} className="w-full">
                        {header}
                      </td>
                    </div>}
                  {/* If the row is a link, we render a different element altogether */}
                  {href ? <Link to={href} className={classNames('focus-visible:ring-main table-row transition focus-visible:z-20 focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-offset-1', (!!header || rowIdx !== 0) && 'border-main border-t', className)}>
                      {cells}
                    </Link> :
              // Otherwise, we render a div
              <div onClick={onClick}
              // {...datum.getRowProps([rowProps])}
              className={classNames('focus-visible:ring-main table-row transition focus-visible:z-20 focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-offset-1', (!!header || rowIdx !== 0) && 'border-main border-t', className)}>
                      {cells}
                    </div>}
                  {/* There's generally not a footer */}
                  {footer && <div className={classNames('border-main focus-visible:ring-main table-row border-t transition focus-visible:z-20 focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-offset-1')}>
                      <td colSpan={columns.length} className="w-full">
                        {footer}
                      </td>
                    </div>}
                </Fragment>;
          })}
          </div>
        </div>
      </div>
    </div>;
};