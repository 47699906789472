import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { ControlRecordGetResponse, ControlRecordGetResponseResult } from 'src/build/operations';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';
type ControlRecordPropertyListProps = {
  controlRecord: ControlRecordGetResponse;
} & Omit<PropertyListProps, 'items'>;
const resultBadgeColor: Record<ControlRecordGetResponseResult, BadgeColor> = {
  success: 'green',
  failure: 'red'
};
export const ControlRecordPropertyList = (props: ControlRecordPropertyListProps) => {
  const {
    controlRecord,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(controlRecord.group_id);
  const programRow = useProgramPropertyRow(controlRecord.program_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: controlRecord.id,
    copyable: controlRecord.id
  }, groupRow, programRow, {
    label: 'Control',
    value: controlRecord.control_configuration_id,
    href: buildPath(ROUTES.CONTROLS_DETAIL, {
      id: controlRecord.control_configuration_id
    })
  }, {
    label: 'Category',
    value: humanize(controlRecord.category)
  }, {
    label: 'Latest result',
    value: humanize(controlRecord.result),
    badgeColor: resultBadgeColor[controlRecord.result]
  }, controlRecord.failing_since && {
    label: 'Failing since',
    value: formatISO8601Timestamp(controlRecord.failing_since, 'full')
  }, {
    label: 'Created',
    value: formatISO8601Timestamp(controlRecord.created_at, 'medium')
  }])} data-sentry-element="PropertyList" data-sentry-component="ControlRecordPropertyList" data-sentry-source-file="ControlRecordPropertyList.tsx" />;
};