import { ListItem } from '@increase/shared/components/PropertyList';
import { useAccountGet, useGroupGet, useProgramGet } from 'src/hooks/reactQuery';
import { buildPath, ROUTES } from './routes';
export const useGroupPropertyRow = (groupId: string | undefined): ListItem => {
  const {
    data
  } = useGroupGet(groupId || '', {
    enabled: !!groupId
  });
  if (!groupId || !data) {
    return {
      label: 'Group',
      value: ''
    };
  }
  return {
    label: 'Group',
    value: data.name || data.friendly_nickname,
    href: buildPath(ROUTES.GROUPS_DETAIL, {
      groupId
    })
  };
};
export const useProgramPropertyRow = (programId: string | undefined): ListItem => {
  const {
    data
  } = useProgramGet(programId || '', {
    enabled: !!programId
  });
  if (!data || !programId) {
    return {
      label: 'program',
      value: ''
    };
  }
  return {
    label: 'Program',
    value: data.name,
    href: buildPath(ROUTES.PROGRAMS_DETAIL, {
      programId
    })
  };
};
export const useAccountPropertyRow = (accountId: string | undefined): ListItem => {
  const {
    data
  } = useAccountGet(accountId || '', {
    enabled: !!accountId
  });
  if (!data || !accountId) {
    return {
      label: 'account',
      value: ''
    };
  }
  return {
    label: 'Account',
    value: data.name,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId
    })
  };
};