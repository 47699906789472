import classNames from 'classnames';
import { ReactNode } from 'react';
import { Caption } from '../Text';
import styles from './index.module.css';
type Props = {
  children: ReactNode;
  action?: ReactNode;
  padding?: 'normal' | 'large';
  className?: string;
};
const Note = ({
  children,
  action,
  padding = 'normal',
  className
}: Props) => {
  return <div className={classNames(styles.note, 'border-main bg-strong text-main flex items-center justify-between rounded border px-4', padding === 'normal' && 'py-2', padding === 'large' && 'py-4', className)} data-sentry-component="Note" data-sentry-source-file="index.tsx">
      <Caption color="primary" data-sentry-element="Caption" data-sentry-source-file="index.tsx">{children}</Caption>
      {action && <div className="ml-4">{action}</div>}
    </div>;
};
export { Note };