import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { FedwireInboundRequestForReversalListResponseDataItem, FedwireInboundRequestForReversalListResponseDataItemAllocationStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
import { parseFedwireMessage } from 'src/lib/fedwire-utility';
type FedwireInboundRequestForReversalsTableProps = PrestyledTableProps<FedwireInboundRequestForReversalListResponseDataItem>;
const requestForReversalBadgeColor: Record<FedwireInboundRequestForReversalListResponseDataItemAllocationStatus, BadgeColor> = {
  abandoned: 'gray',
  allocated: 'green',
  flagged_by_operator: 'red',
  pending_allocating: 'yellow',
  requires_attention: 'red'
};
const defaultRowProps = (datum: FedwireInboundRequestForReversalListResponseDataItem) => ({
  href: buildPath(ROUTES.FEDWIRE_INBOUND_REQUEST_FOR_REVERSALS_DETAIL, {
    requestForReversalId: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const FedwireInboundRequestForReversalsTable = (props: FedwireInboundRequestForReversalsTableProps) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<FedwireInboundRequestForReversalListResponseDataItem>>(() => {
    return [{
      header: 'ID',
      expand: 1,
      contents: requestForReversal => {
        return {
          text: requestForReversal.id
        };
      }
    }, {
      header: 'Body',
      expand: 1,
      contents: requestForReversal => {
        const message = parseFedwireMessage(requestForReversal.message_body);
        return {
          text: message['6500'] || message['9000'],
          textColor: 'primary',
          textWeight: 'medium'
        };
      }
    }, {
      header: 'Sender',
      contents: requestForReversal => {
        return {
          text: requestForReversal.sender_institution
        };
      }
    }, {
      header: 'Receiver',
      contents: requestForReversal => {
        return {
          text: requestForReversal.receiver_institution
        };
      }
    }, {
      header: 'Status',
      contents: requestForReversal => ({
        text: humanize(requestForReversal.allocation_status),
        badgeColor: requestForReversalBadgeColor[requestForReversal.allocation_status]
      })
    }, {
      header: 'Received on',
      contents: requestForReversal => ({
        text: formatISO8601Timestamp(requestForReversal.received_on, 'medium')
      })
    }];
  }, []);
  return <Table {...rest} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="FedwireInboundRequestForReversalsTable" data-sentry-source-file="FedwireInboundRequestForReversalsTable.tsx" />;
};