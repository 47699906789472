import { useInboundMailItemList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { InboundMailItemListResponse } from 'src/build/operations';
import moment from 'moment';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { partition } from 'lodash';
const InboundMailItemListItems = ({
  inboundMailItems
}: {
  inboundMailItems?: InboundMailItemListResponse;
}) => {
  if (!inboundMailItems) {
    return <div>Loading...</div>;
  }
  if (inboundMailItems.data.length === 0) {
    return <p className="text-lg">No mail items to review 🎉</p>;
  }
  const [pendingAllocating, other1] = partition(inboundMailItems.data, item => item.status === 'pending_allocating');
  const [requiresAttention, other2] = partition(other1, item => item.status === 'requires_attention');
  const [pendingReturnAllocating] = partition(other2, item => item.status === 'pending_return_allocating');
  return <>
      {pendingAllocating.length > 0 && <div>
          <h2 className="text-lg">Pending allocating</h2>
          <ItemList items={pendingAllocating.map(item => ({
        href: `/manual_tasks/Operations::ManualTask::AllocateStableMailboxMailItem?stable_mailbox_mail_item_id=${item.id}`,
        id: item.id,
        text: item.id,
        detail: moment(item.created_at).format('LLLL')
      }))} />
        </div>}
      {pendingReturnAllocating.length > 0 && <div>
          <h2 className="text-lg">Pending return allocating</h2>
          <ItemList items={pendingReturnAllocating.map(item => ({
        href: `/manual_tasks/Operations::ManualTask::HandleReturnedCheckTransferMailItem?stable_mailbox_mail_item_id=${item.id}`,
        id: item.id,
        text: item.id,
        detail: moment(item.created_at).format('LLLL')
      }))} />
        </div>}
      {requiresAttention.length > 0 && <div>
          <h2 className="text-lg">Requires attention</h2>
          <ItemList items={requiresAttention.map(item => ({
        href: `/object/${item.id}`,
        id: item.id,
        text: item.id,
        detail: moment(item.created_at).format('LLLL')
      }))} />
        </div>}
    </>;
};
export const InboundMailItemList = () => {
  const {
    data
  } = useInboundMailItemList({});
  return <PageLayout headline="Inbound mail item review" data-sentry-element="PageLayout" data-sentry-component="InboundMailItemList" data-sentry-source-file="inbound-mail-item-list.tsx">
      <StyledLink href="https://paper.dropbox.com/doc/Lockbox-runbook--CQ5Rdt0uFlOx9slwak8oxt_AAg-PrffJNlh7El6UipGnWYif" style="underline" data-sentry-element="StyledLink" data-sentry-source-file="inbound-mail-item-list.tsx">
        Runbook
      </StyledLink>
      <InboundMailItemListItems inboundMailItems={data} data-sentry-element="InboundMailItemListItems" data-sentry-source-file="inbound-mail-item-list.tsx" />
    </PageLayout>;
};