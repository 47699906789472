import { isMobileSafari, osVersion } from 'react-device-detect';
type Props = {
  sectionLabel: string;
  opts: JSX.Element[];
};

// https://bugs.webkit.org/show_bug.cgi?id=247443
const needsMobileSafari16OptGroupWorkaround = isMobileSafari && osVersion.startsWith('16.');
export const OptGroupWorkaround = ({
  sectionLabel,
  opts
}: Props) => needsMobileSafari16OptGroupWorkaround ? <>
      <option key={sectionLabel} disabled={true} label={`*${sectionLabel}*`} value="">
        *{sectionLabel}*
      </option>
      {opts}
    </> : <optgroup key={sectionLabel} label={sectionLabel}>
      {opts}
    </optgroup>;