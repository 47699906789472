import { usePlatformComplianceMetricsSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { formatAmount, formatISO8601Timestamp, formatInteger } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
const formatOptionalInteger = (value: number | null): string => value !== null ? formatInteger(value) : 'Not applicable.';
export const PlatformComplianceMetricsSubmission = () => {
  const {
    submissionID
  } = useTypedParams(ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL);
  const {
    data: submission
  } = usePlatformComplianceMetricsSubmissionGet(submissionID);
  const groupRow = useGroupPropertyRow(submission?.group_id);
  const programRow = useProgramPropertyRow(submission?.program_id);
  if (!submission) {
    return <></>;
  }
  return <PageLayout id="platform_compliance_metrics_submission" headline="Platform Compliance Metrics Submission" data-sentry-element="PageLayout" data-sentry-component="PlatformComplianceMetricsSubmission" data-sentry-source-file="platform-compliance-metrics-submission.tsx">
      <PropertyList items={[{
      label: 'Submission ID',
      value: submissionID
    }, groupRow, programRow, {
      label: 'Submission at',
      value: formatISO8601Timestamp(submission.created_at, 'full')
    }, {
      label: 'Period',
      value: `${submission.period_start} - ${submission.period_end}`
    }, {
      label: 'Total number of complaints',
      value: formatOptionalInteger(submission.number_of_complaints_received)
    }, {
      label: '"Total Number of Regulator/Law Enforcement/Legal Complaints"',
      value: formatOptionalInteger(submission.number_of_regulator_law_enforcement_legal_complaints_received)
    }, {
      label: 'Total Number of Card Related Regulation E Disputes',
      value: formatOptionalInteger(submission.number_of_card_related_regulation_e_disputes_received)
    }, {
      label: 'Total Number of Fraud Cases',
      value: formatInteger(submission.number_of_fraud_cases)
    }, {
      label: 'Total Fraud Loss Amount',
      value: formatAmount(submission.fraud_loss_amount, 'USD')
    }, {
      label: 'Total Number of ACH Related Regulation E Disputes',
      value: formatOptionalInteger(submission.number_of_ach_related_regulation_e_disputes_received)
    }, {
      label: 'Total Number of Fraud Related Transaction Monitoring Alerts',
      value: formatInteger(submission.number_of_fraud_related_transaction_monitoring_alerts_generated)
    }, {
      label: 'Total Number of AML Related Transaction Monitoring Alerts',
      value: formatInteger(submission.number_of_aml_related_transaction_monitoring_alerts_generated)
    }, {
      label: 'Total Number of Unusual Activity Report Cases',
      value: formatInteger(submission.number_of_unusual_activity_report_cases_created)
    }, {
      label: 'Total Number of Cases Escalated to Increase',
      value: formatInteger(submission.number_of_cases_escalated_to_increase)
    }, {
      label: 'Earliest Unresolved Alert',
      value: submission.earliest_unresolved_alert ?? 'The platform confirmed that there are no unresolved alerts.'
    }, {
      label: 'Total Number of True Office of Foreign Asset Control Hits',
      value: formatInteger(submission.number_of_true_office_of_foreign_asset_control_hits)
    }, {
      label: 'Total Number of Fraud Cases Associated with Identity Theft',
      value: formatOptionalInteger(submission.number_of_fraud_cases_associated_with_identity_theft)
    }, {
      label: 'Total Number of Applications Received',
      value: formatOptionalInteger(submission.number_of_applications_received)
    }, {
      label: 'Total Number of Applications Denied',
      value: formatOptionalInteger(submission.number_of_applications_denied)
    }, {
      label: 'Total Number of Users Offboarded',
      value: formatOptionalInteger(submission.number_of_users_offboarded)
    }, {
      label: 'Total Number of Users Onboarded',
      value: formatOptionalInteger(submission.number_of_users_onboarded)
    }]} data-sentry-element="PropertyList" data-sentry-source-file="platform-compliance-metrics-submission.tsx"></PropertyList>
    </PageLayout>;
};