import { PDFDocumentProxy } from 'pdfjs-dist';
import { must } from './must';

type CanvasImage = CanvasImageSource & { width: number; height: number };

async function imageFromBlob(blob: Blob): Promise<CanvasImage> {
  return new Promise((res, reject) => {
    const image = document.createElement('img');

    image.onerror = (err) => {
      reject(err);
    };

    image.onload = () => {
      res(image);
    };

    image.src = URL.createObjectURL(blob);
  });
}

function createImageData(width: number, height: number): ImageData {
  const canvas = document.createElement('canvas');
  const context = must(canvas.getContext('2d'));

  return context.createImageData(width, height);
}

const DEFAULT_OPTIONS = { maxPixels: 500000 };

function limitMaxPixels(
  image: { width: number; height: number },
  options: { maxPixels?: number; minimumWidth?: number; minimumHeight?: number }
) {
  const { maxPixels, minimumWidth, minimumHeight } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const aspect = image.width / image.height;

  // x * y = maxPixels;
  // x / y = aspect;

  // maxPixels / y = x;
  // x = aspect * y;
  // maxPixels/y = aspect * y
  // y = maxPixels / ( y * aspect )
  // y^2 = maxPixels / aspect
  // y = sqrt( maxPixels / aspect )

  let height = Math.sqrt(maxPixels / aspect);

  if (minimumHeight && height < minimumHeight) {
    height = minimumHeight;
  }

  let width = aspect * height;

  if (minimumWidth && width < minimumWidth) {
    width = minimumWidth;
    height = width / aspect;
  }

  return {
    height: Math.floor(height),
    width: Math.floor(width),
  };
}

export function getDataURL(image: ImageData): string {
  const canvas = document.createElement('canvas');
  const ctx = must(canvas.getContext('2d'));

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.putImageData(image, 0, 0);

  return canvas.toDataURL('image/png');
}

export async function getPixels(
  img: CanvasImageSource,
  options: {
    bounds: { width: number; height: number; left: number; top: number };
    rotation?: number;
    minimumWidth?: number;
    minimumHeight?: number;
    maxPixels?: number;
  }
): Promise<ImageData> {
  if (
    !('width' in img && 'height' in img) ||
    typeof img.width !== 'number' ||
    typeof img.height !== 'number'
  ) {
    throw new Error('static images only');
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2D context from canvas.');
  }

  const bounds = options.bounds;
  const imageDimension = Math.max(img.width, img.height);

  const { width: pixelWidth, height: pixelHeight } = limitMaxPixels(
    {
      width: bounds.width * imageDimension,
      height: bounds.height * imageDimension,
    },
    options
  );

  const canvasDimension = Math.max(
    pixelWidth / bounds.width,
    pixelHeight / bounds.height
  );

  canvas.width = canvasDimension;
  canvas.height = canvasDimension;

  let scale = 1;
  if (img.width > img.height) {
    scale = canvasDimension / img.width;
  } else {
    scale = canvasDimension / img.height;
  }

  const w = img.width * scale;
  const h = img.height * scale;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((Math.PI * (options.rotation || 0)) / 180);

  ctx.drawImage(img, -w / 2, -h / 2, w, h);

  const width = Math.round(bounds.width * canvasDimension);
  const height = Math.round(bounds.height * canvasDimension);

  const pixels = ctx.getImageData(
    bounds.left * canvasDimension,
    bounds.top * canvasDimension,
    width,
    height
  );

  return pixels;
}

export function applyThreshold(
  pixels: ImageData,
  threshold: number
): ImageData {
  const output = createImageData(pixels.width, pixels.height);
  const data = pixels.data;

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    output.data[i] =
      output.data[i + 1] =
      output.data[i + 2] =
        luminance > threshold ? 255 : 0;
    output.data[i + 3] = 255;
    //output.data[i + 4] = 1;
  }

  return output;
}

export function imageDataToCanvasImageSource(
  imageData: ImageData
): CanvasImageSource & { width: number; height: number } {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.putImageData(imageData, 0, 0);
  } else {
    throw new Error('Failed to get 2D context from canvas.');
  }

  return canvas;
}

export function addText(
  imageData: ImageData,
  text: string,
  font: string,
  point: { x: number; y: number }
): ImageData {
  const { x, y } = point;
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    const angle = imageData.width > imageData.height ? 90 : 0;
    ctx.putImageData(imageData, 0, 0);
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.font = font;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.fillText(text, 0, 0);
    ctx.restore();
  } else {
    throw new Error('Failed to get 2D context from canvas.');
  }

  return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

async function heicFromResponse(response: Response): Promise<CanvasImage> {
  const heic2any = (await import('heic2any')).default;
  const png = await heic2any({ blob: await response.blob() });
  if (Array.isArray(png)) {
    const blob = await imageFromBlob(png[0]);
    return blob;
  } else {
    const blog = await imageFromBlob(png);
    return blog;
  }
}

async function pdfFromResponse(response: Response): Promise<PDFDocumentProxy> {
  const { GlobalWorkerOptions, getDocument } = await import('pdfjs-dist');
  const pdfjsWorker = (await import('pdfjs-dist/build/pdf.worker.entry'))
    .default;
  GlobalWorkerOptions.workerSrc = pdfjsWorker;

  return getDocument({
    isEvalSupported: false,
    data: new Uint8Array(await response.arrayBuffer()),
  }).promise;
}

export async function loadImages(url: string): Promise<CanvasImage[]> {
  const response = await fetch(url, { credentials: 'include' });
  const type = response.headers?.get('content-type');

  if (type?.includes('image/heic')) {
    const heic = await heicFromResponse(response);
    return [heic];
  } else if (type?.includes('pdf')) {
    const pdfDoc = await pdfFromResponse(response);
    const bitmaps: ImageBitmap[] = [];

    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale: 3 });
      const canvas = document.createElement('canvas');
      const context = must(canvas.getContext('2d'));
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      context.save();
      await page.render({
        canvasContext: context,
        viewport,
      }).promise;
      const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
      const bitmap = await createImageBitmap(pixels);
      bitmaps.push(bitmap);
    }

    return bitmaps;
  } else {
    const blob = await response.blob();
    const image = await imageFromBlob(blob);
    return [image];
  }
}
