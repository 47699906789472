import { useBeneficialOwnerGet, useBeneficialOwnerRelationshipListInfinite, useSanctionsScreeningReviewListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { EntityBeneficialOwnerPropertyList } from './property-lists/EntityBeneficialOwnerPropertyList';
import { IdentityDocumentsTable } from './tables/IdentityDocumentsTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { MicrobiltSearchResultsTable } from './tables/MicrobiltSearchResultsTable';
import { EntitySetupCorporationBeneficialOwnerRelationshipsTable } from './tables/EntitySetupCorporationBeneficialOwnerRelationshipsTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { SanctionsScreeningReviewsTable } from './tables/SanctionScreeningReviewsTable';
import { AssociatedManualTasks } from './associated-manual-tasks';
export const EntitySetupBeneficialOwnerSubmissionPage = () => {
  const {
    beneficialOwnerId
  } = useTypedParams(ROUTES.BENEFICIAL_OWNER);
  const {
    data: beneficialOwner
  } = useBeneficialOwnerGet(beneficialOwnerId);
  const relationshipList = useBeneficialOwnerRelationshipListInfinite({
    beneficial_owner_id: beneficialOwnerId
  });
  const sanctionsListResult = useSanctionsScreeningReviewListInfinite({
    record_ids: [beneficialOwnerId],
    limit: 10
  }, {
    enabled: true
  });
  if (!beneficialOwner) {
    return <div>Loading</div>;
  }
  return <PageLayout headline={beneficialOwner.submitted_name} data-sentry-element="PageLayout" data-sentry-component="EntitySetupBeneficialOwnerSubmissionPage" data-sentry-source-file="entity-setup-beneficial-owner-submission-page.tsx">
      <SideBySide mode="centered" left={<>
            <EntityBeneficialOwnerPropertyList title="Basics" beneficialOwner={beneficialOwner} />
            <TableStateWrapper table={IdentityDocumentsTable} data={beneficialOwner.identity_document_submissions} hasNextPage={false} error={null} style="detail" title="Identity documents" emptySubtitle="No identity documents submitted" emptyTitle="No identity documents submitted" viewMoreHref="" />

            <TableStateWrapper table={MicrobiltSearchResultsTable} data={beneficialOwner.microbilt} hasNextPage={false} error={null} style="detail" title="MicroBilt searches" emptySubtitle="No MicroBilt search results found" emptyTitle="No MicroBilt search results found" viewMoreHref="" />

            <TableStateWrapper table={SanctionsScreeningReviewsTable} {...sanctionsListResult} style="detail" title="Office of Foreign Asset Control hits" emptySubtitle="No sanctions screening hits" emptyTitle="No sanctions screening hits" viewMoreHref={buildPath(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, {}, {
        records: [beneficialOwnerId]
      })} showRecordId={false} />

            <AssociatedManualTasks objectId={beneficialOwnerId} />
          </>} right={<TableStateWrapper {...relationshipList} table={EntitySetupCorporationBeneficialOwnerRelationshipsTable} style="primary" title="Entities" emptyTitle="No beneficial owner relationships" emptySubtitle="No MicroBilt search results found" filters={[]} />} data-sentry-element="SideBySide" data-sentry-source-file="entity-setup-beneficial-owner-submission-page.tsx" />
    </PageLayout>;
};