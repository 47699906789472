import { useIdentityDocumentList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { ItemList } from './deprecated/ItemList';
import moment from 'moment';
import { ROUTES, buildPath } from 'src/lib/routes';
export const IdentityDocumentList = () => {
  const {
    data: identityDocuments
  } = useIdentityDocumentList({});
  if (!identityDocuments) {
    return <div>Loading...</div>;
  }
  return <PageLayout id="application.identity-document-list" headline="Identity document list" data-sentry-element="PageLayout" data-sentry-component="IdentityDocumentList" data-sentry-source-file="identity-document-list.tsx">
      <Section header="Identity documents" data-sentry-element="Section" data-sentry-source-file="identity-document-list.tsx">
        {identityDocuments.data.length === 0 && <div>No identity documents</div>}
        {identityDocuments.data.length > 0 && <ItemList items={identityDocuments.data.map(item => ({
        href: buildPath(ROUTES.IDENTITY_DOCUMENT_REVIEW, {
          identityDocumentID: item.id
        }),
        id: item.id,
        text: `${item.id} [${item.group_id}]`,
        detail: moment(item.created_at).format('LLLL')
      }))} />}
      </Section>
    </PageLayout>;
};