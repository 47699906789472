import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useTaskStatusList } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { TaskStatusListStatusesItem } from 'src/build/operations';
import { makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { TaskStatusTable } from '../tables/TaskStatusTable';
export const TaskStatusListPage = () => {
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.TASK_STATUS_LIST, 'statuses');
  const filters: TableFilter[] = useMemo(() => compact([makeStatusFilter(statuses, setStatuses, TaskStatusListStatusesItem)]), [statuses, setStatuses]);
  const listResult = useTaskStatusList({
    statuses: statuses.length > 0 ? statuses : undefined
  });
  return <PageLayout headline={'Tasks'} data-sentry-element="PageLayout" data-sentry-component="TaskStatusListPage" data-sentry-source-file="TaskStatusListPage.tsx">
      <TableStateWrapper table={TaskStatusTable} style="primary" {...listResult} emptyTitle="No tasks" emptySubtitle="There are no tasks to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="TaskStatusListPage.tsx" />
    </PageLayout>;
};