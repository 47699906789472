import classNames from 'classnames';
import { KeyboardEventHandler } from 'react';
type Props = {
  'data-testid'?: string;
  id?: string;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  size?: 'large' | 'normal';
  type?: 'text' | 'password' | 'email' | 'number' | 'datetime-local';
  onChange: (value: string) => void;
  onKeyPress?: KeyboardEventHandler;
};
export const TextInput = ({
  wrapperClassName,
  className,
  label,
  autoFocus,
  size = 'normal',
  onChange,
  ...rest
}: Props) => {
  return <div className={wrapperClassName} data-sentry-component="TextInput" data-sentry-source-file="TextInput.tsx">
      {label && <label htmlFor={rest.name}>{label}</label>}
      <input {...rest} className={classNames(size === 'large' && 'px-2 py-2.5 text-base', size === 'normal' && 'p-2 text-sm', 'block w-full rounded border border-strong shadow-sm', className)} onChange={e => onChange(e.target.value)} autoFocus={autoFocus} />
    </div>;
};