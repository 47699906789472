import React, { useState } from 'react';
import { useManagementInformationSystemVolumesList } from 'src/hooks/reactQuery';
import { ProgramVolumes } from './program-volumes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { Switch } from '@increase/shared/components/Switch';
import { Body } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
export const VolumesPage = () => {
  const [atTime] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'at_time');
  const [period] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'lookback_period');
  const [volumes, setVolumes] = useState(true);
  const {
    data: volumeData
  } = useManagementInformationSystemVolumesList({
    at_time: atTime,
    period: period
  });
  return <>
      <div className="flex justify-end">
        <Box flex data-sentry-element="Box" data-sentry-source-file="VolumesPage.tsx">
          <Switch checked={volumes} onChange={setVolumes} label="Volume metrics" labelDirection="left" data-sentry-element="Switch" data-sentry-source-file="VolumesPage.tsx" />
          <Body color="primary" contents={'Count metrics'} data-sentry-element="Body" data-sentry-source-file="VolumesPage.tsx" />
        </Box>
      </div>
      {volumeData ? <ProgramVolumes metric={volumes ? 'volume' : 'count'} volumes={volumeData.data.volumes} totals={volumeData.data.totals} /> : <h3>Loading</h3>}
    </>;
};