import { useDropzone } from 'react-dropzone';
import { useMemo } from 'react';
import { useOperatorFileList, useOperatorFilePost, useOperatorGet } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { OperatorFileListResponseDataItem } from 'src/build/operations';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { Button } from '@increase/shared/components/Button';
type Props = {
  modelId: string;
};
const OperatorFilesTable = (props: PrestyledTableProps<OperatorFileListResponseDataItem>) => {
  const {
    operations
  } = useServices();
  const {
    data: operator
  } = useOperatorGet({});
  const defaultRowProps = (datum: OperatorFileListResponseDataItem) => ({
    href: `${operations}/operator-files/${datum.id}/view`,
    className: 'transition-all group hover:bg-main-hover cursor-pointer'
  });
  const columns: TableColumns<OperatorFileListResponseDataItem> = useMemo(() => [{
    header: 'Name',
    contents: datum => ({
      text: datum.transmission_name,
      textWeight: 'bold',
      textColor: 'emphasis'
    })
  }, {
    header: 'Purpose',
    contents: datum => ({
      text: datum.purpose ? humanize(datum.purpose) : ''
    })
  }, {
    header: 'Created',
    contents: datum => ({
      text: formatISO8601Timestamp(datum.created_at, 'day-month-year')
    })
  }, {
    header: 'Creator',
    contents: datum => ({
      text: datum.operator_email
    })
  }, operator?.role === 'internal' && {
    header: 'Archive',
    contents: datum => ({
      text: 'archive',
      href: `/manual_tasks/Operations::ManualTask::ArchiveOperatorFile?operator_file_id=${datum.id}`
    })
  }], [operator?.role]);
  return <Table {...props} columns={columns} getRowProps={defaultRowProps} data-sentry-element="Table" data-sentry-component="OperatorFilesTable" data-sentry-source-file="operator-files.tsx" />;
};
export const OperatorFiles = ({
  modelId
}: Props) => {
  const operatorFiles = useOperatorFileList({
    model_id: modelId
  });
  const operatorFilePost = useOperatorFilePost();
  const createOperatorFile = (file: File) => {
    operatorFilePost.mutateAsync([{
      file,
      model_id: modelId,
      purpose: 'know_your_customer'
    }]).then(() => {
      operatorFiles.refetch();
    });
  };
  const {
    getInputProps,
    getRootProps
  } = useDropzone({
    onDrop: acceptedFiles => createOperatorFile(acceptedFiles[0])
  });
  return <>
      <input {...getInputProps()} />
      <TableStateWrapper action={<Button disabled={operatorFilePost.isLoading} onClick={getRootProps().onClick} icon="arrow_up" text="Upload" />} title="Operator Files" emptyTitle="No operator files" emptySubtitle="" table={OperatorFilesTable} {...operatorFiles} style="detail" viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-source-file="operator-files.tsx" />
    </>;
};