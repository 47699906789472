import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAccuratePercentage, formatAmount } from '@increase/shared/lib/formatting';
import { BankProgramCurrentFeePlanGetResponse } from 'src/build/operations';
type BankFeePlanPropertyListProps = {
  bankFeePlan: BankProgramCurrentFeePlanGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const BankFeePlanPropertyList = (props: BankFeePlanPropertyListProps) => {
  const {
    bankFeePlan,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'Monthly Base Fee',
    value: formatAmount(bankFeePlan.monthly_base_fee, 'USD')
  }, {
    label: 'Future-Dated ACH Origination',
    value: formatAmount(parseFloat(bankFeePlan.ach_future_dated_origination_fee), 'USD', true, 3)
  }, {
    label: 'Same-Day ACH Origination',
    value: formatAmount(parseFloat(bankFeePlan.ach_same_day_origination_fee), 'USD', true, 3)
  }, {
    label: 'ACH Returns',
    value: formatAmount(parseFloat(bankFeePlan.ach_return_fee), 'USD', true, 3)
  }, {
    label: 'Wire Origination',
    value: formatAmount(parseFloat(bankFeePlan.wire_origination_fee), 'USD', true, 3)
  }, {
    label: 'Real-Time Payments Origination',
    value: formatAmount(parseFloat(bankFeePlan.real_time_payments_transfer_origination_fee), 'USD', true, 3)
  }, {
    label: 'Check Origination',
    value: formatAmount(parseFloat(bankFeePlan.check_origination_fee), 'USD', true, 3)
  }, {
    label: 'Minimum Non-Card Transaction Fee',
    value: formatAmount(parseFloat(bankFeePlan.minimum_non_card_transaction_fee), 'USD')
  }, {
    label: 'Card Volume Fee',
    value: formatAccuratePercentage(bankFeePlan.card_payment_fee_rate)
  }, {
    label: 'Minimum Card Volume Fee',
    value: formatAmount(parseFloat(bankFeePlan.minimum_card_transaction_fee), 'USD')
  }, parseFloat(bankFeePlan.monthly_consumer_entity_fee) > 0 && {
    label: 'Consumer Account Fee',
    value: formatAmount(parseFloat(bankFeePlan.monthly_consumer_entity_fee), 'USD')
  }, {
    label: 'Service Fee',
    value: formatAccuratePercentage(bankFeePlan.service_fee_rate)
  }])} data-sentry-element="PropertyList" data-sentry-component="BankFeePlanPropertyList" data-sentry-source-file="BankFeePlanPropertyList.tsx" />;
};