import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { Outlet } from 'react-router';
const COMMON_FILTER_PARAMETER_NAMES = ['group', 'account', 'card', 'account_number', 'on_or_after', 'on_or_before', 'amount_or_greater', 'amount_or_less', 'exact_amount'];
export const TransactionsWrapperPage = () => {
  const segments = [{
    slug: '/transactions',
    title: 'Completed'
  }, {
    slug: '/pending_transactions',
    title: 'Pending'
  }] as const;
  const segmentIndex = location.pathname.includes('pending') ? 1 : 0;
  const cleanSegments = segments.map(({
    slug,
    title
  }, i) => {
    const currentFilterParameters = new URLSearchParams(window.location.search);
    const newFilteredParameters: URLSearchParams = new URLSearchParams();
    currentFilterParameters.forEach((value, key) => {
      if (COMMON_FILTER_PARAMETER_NAMES.includes(key)) {
        newFilteredParameters.append(key, value);
      }
    });
    const newSearchString: string = newFilteredParameters.toString();
    const href = `${slug}?${newSearchString}`;
    return {
      title,
      href,
      selected: i === segmentIndex
    };
  });
  return <PageLayout headline={'Transactions'} segmentedControl={<SegmentedControl segments={cleanSegments} />} data-sentry-element="PageLayout" data-sentry-component="TransactionsWrapperPage" data-sentry-source-file="TransactionsWrapperPage.tsx">
      <Outlet data-sentry-element="Outlet" data-sentry-source-file="TransactionsWrapperPage.tsx" />
    </PageLayout>;
};