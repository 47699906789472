import { useManualTaskList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useState } from 'react';
import { TextInput } from '@increase/shared/components/TextInput';
export const ManualTaskList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: manualTasks
  } = useManualTaskList({});
  if (!manualTasks) {
    return <>Loading...</>;
  }
  const lowercase = searchQuery.toLowerCase();
  const filteredTasks = manualTasks.data.filter(mt => mt.name.toLowerCase().includes(lowercase) || mt.description?.toLowerCase().includes(lowercase) || mt.arguments.find(arg => arg.name.toLowerCase().includes(lowercase)));
  return <PageLayout headline="Manual Tasks" data-sentry-element="PageLayout" data-sentry-component="ManualTaskList" data-sentry-source-file="manual-task-list.tsx">
      <TextInput value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder="Search for a manual task..." data-sentry-element="TextInput" data-sentry-source-file="manual-task-list.tsx" />
      <ItemList items={filteredTasks.map(mt => ({
      text: mt.name.replace('Operations::ManualTask::', ''),
      id: mt.name,
      detail: mt.description ?? '',
      href: buildPath(ROUTES.MANUAL_TASK, {
        taskName: mt.name
      })
    }))} data-sentry-element="ItemList" data-sentry-source-file="manual-task-list.tsx" />
    </PageLayout>;
};