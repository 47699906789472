import { useManualTaskRunGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { StyledLink } from './deprecated/StyledLink';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
export const ManualTaskRunDetail = () => {
  const {
    manualTaskRunId
  } = useTypedParams(ROUTES.MANUAL_TASK_RUN);
  const {
    data: manualTaskRun
  } = useManualTaskRunGet(manualTaskRunId);
  if (!manualTaskRun) {
    return <>Loading...</>;
  }
  const searchParams = new URLSearchParams(manualTaskRun.arguments as Record<string, string>);
  const cloneLink = `/manual_tasks/${manualTaskRun.task_name}?${searchParams.toString()}`;
  return <PageLayout headline={`Manual Task Run ${manualTaskRunId}`} data-sentry-element="PageLayout" data-sentry-component="ManualTaskRunDetail" data-sentry-source-file="manual-task-run-detail.tsx">
      <PropertyList items={[{
      label: 'ID',
      value: manualTaskRun.id
    }, {
      label: 'Task',
      value: manualTaskRun.task_name.replace('Operations::ManualTask::', ''),
      href: buildPath(ROUTES.MANUAL_TASK, {
        taskName: manualTaskRun.task_name
      })
    }, {
      label: 'Status',
      value: manualTaskRun.status
    }, {
      label: 'Started at',
      value: manualTaskRun.started_at || ''
    }, {
      label: 'Finished at',
      value: manualTaskRun.failed_at || manualTaskRun.succeeded_at || ''
    }, {
      label: 'Operator',
      value: manualTaskRun.operator_email
    }]} data-sentry-element="PropertyList" data-sentry-source-file="manual-task-run-detail.tsx" />

      <Section header="Arguments" data-sentry-element="Section" data-sentry-source-file="manual-task-run-detail.tsx">
        <div>
          <pre style={{
          whiteSpace: 'pre-wrap'
        }}>
            {JSON.stringify(manualTaskRun.arguments, null, 2)}
          </pre>
        </div>
      </Section>

      <Section header="Output" data-sentry-element="Section" data-sentry-source-file="manual-task-run-detail.tsx">
        <div>
          <pre style={{
          whiteSpace: 'pre-wrap'
        }}>
            {manualTaskRun.output}
            <br />
            {manualTaskRun.failure_message}
          </pre>
        </div>
      </Section>

      <h4>Output</h4>

      <StyledLink to={cloneLink} data-sentry-element="StyledLink" data-sentry-source-file="manual-task-run-detail.tsx">Clone this task...</StyledLink>
    </PageLayout>;
};