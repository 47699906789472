import { FocusEventHandler, useState } from 'react';
import { TextInput, TextInputProps } from './TextInput';
type Props = Exclude<TextInputProps, 'type'>;
export const DateInput = ({
  onBlur,
  onFocus,
  min,
  ...rest
}: Props) => {
  const [type, setType] = useState(rest.value ? 'date' : 'text');
  const onBlurOverride: FocusEventHandler<HTMLInputElement> = e => {
    if (!e.target.value) {
      setType('text');
    }
    onBlur?.(e);
  };
  const onFocusOverride: FocusEventHandler<HTMLInputElement> = e => {
    setType('date');
    onFocus?.(e);
  };
  return <TextInput key={type} autoFocus={type === 'date' && !rest.value} type={type} onBlur={onBlurOverride} onFocus={onFocusOverride} min={min} {...rest} data-sentry-element="TextInput" data-sentry-component="DateInput" data-sentry-source-file="DateInput.tsx" />;
};