import { ManagementInformationSystemRiskMetricsListResponseDataMetricsItem, ManagementInformationSystemVolumesListPeriod } from 'src/build/operations';
import { Table } from '../deprecated/Table';
import { PercentChange } from './PercentChange';
import { formatInteger } from 'src/lib/format-string';
type RiskMetricTableDataRow = ManagementInformationSystemRiskMetricsListResponseDataMetricsItem & {
  id: string;
};
type Props = {
  metrics: ManagementInformationSystemRiskMetricsListResponseDataMetricsItem[];
  period: ManagementInformationSystemVolumesListPeriod;
};
export const ProgramRiskMetrics = ({
  metrics,
  period
}: Props) => {
  const tableData: RiskMetricTableDataRow[] = metrics.map((metric, index) => ({
    ...metric,
    id: `${metric.program_id}-${index}-risk`
  }));
  const totals = {
    count_complaints: tableData.reduce((sum, item) => sum + item.count_complaints, 0),
    previous_count_complaints: tableData.reduce((sum, item) => sum + item.previous_count_complaints, 0),
    count_transaction_monitoring_alerts: tableData.reduce((sum, item) => sum + item.count_transaction_monitoring_alerts, 0),
    previous_count_transaction_monitoring_alerts: tableData.reduce((sum, item) => sum + item.previous_count_transaction_monitoring_alerts, 0)
  };
  tableData.push({
    id: 'total-risk',
    program_id: 'total',
    partner_name: 'Total',
    count_complaints: totals.count_complaints,
    previous_count_complaints: totals.previous_count_complaints,
    count_transaction_monitoring_alerts: totals.count_transaction_monitoring_alerts,
    previous_count_transaction_monitoring_alerts: totals.previous_count_transaction_monitoring_alerts
  });
  return <div data-sentry-component="ProgramRiskMetrics" data-sentry-source-file="program-risk-metrics.tsx">
      <Table data={tableData} columns={[{
      key: 'partner_name',
      label: 'Name'
    }, {
      key: 'count_complaints',
      label: 'Complaints',
      align: 'right',
      render: record => formatInteger(record.count_complaints)
    }, {
      key: 'complaints_percent_change',
      label: `Complaints Change ${period}`,
      align: 'right',
      render: (record: RiskMetricTableDataRow) => <PercentChange previous={record.previous_count_complaints} current={record.count_complaints} percentStyle="text-strong" />
    }, {
      key: 'count_transaction_monitoring_alerts',
      label: 'Transaction Monitoring Alerts',
      align: 'right',
      render: record => formatInteger(record.count_transaction_monitoring_alerts)
    }, {
      key: 'transaction_monitoring_alerts_percent_change',
      align: 'right',
      label: `Transaction Monitoring Alerts Period Change ${period}`,
      render: (record: RiskMetricTableDataRow) => <PercentChange previous={record.previous_count_transaction_monitoring_alerts} current={record.count_transaction_monitoring_alerts} percentStyle="text-strong" />
    }]} boldLastRow data-sentry-element="Table" data-sentry-source-file="program-risk-metrics.tsx" />
    </div>;
};