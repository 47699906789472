import { useState } from 'react';
import { Button } from './deprecated/Button';
import { PageLayout } from './deprecated/PageLayout';
import { useAttestationGet, useAttestationPatch } from 'src/hooks/reactQuery';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const AttestationDetail = () => {
  const {
    attestationId
  } = useTypedParams(ROUTES.ATTESTATIONS_DETAIL);
  const {
    data: attestation
  } = useAttestationGet(attestationId);
  const {
    mutate,
    error
  } = useAttestationPatch();
  const [successMessage, setSuccessMessage] = useState('');
  if (!attestation) {
    return <>Loading...</>;
  }
  return <PageLayout headline={attestation.pretty_name} data-sentry-element="PageLayout" data-sentry-component="AttestationDetail" data-sentry-source-file="attestation-detail.tsx">
      <pre>{attestation.attestation_body}</pre>
      {attestation.attested_at ? <p>This Attestation was attested on {attestation.attested_at}</p> : <Button onClick={() => {
      mutate([attestation.id, {
        attested: true
      }], {
        onSuccess: () => {
          setSuccessMessage('Success!');
        }
      });
    }}>
          I Attest
        </Button>}
      {error && error.response && <p>{error.response.data.message}</p>}
      {successMessage && <p>{successMessage}</p>}
    </PageLayout>;
};