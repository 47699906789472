import { StyledLink } from './StyledLink';
import { Heading, Body } from './Text';
type StackOfLinksProps = {
  title?: string;
  links: {
    href: string;
    text: string;
  }[];
};
export const StackOfLinks = ({
  links,
  title = 'Quick Links'
}: StackOfLinksProps) => {
  return <div className="space-y-3" data-sentry-component="StackOfLinks" data-sentry-source-file="StackOfLinks.tsx">
      <Heading contents={title} data-sentry-element="Heading" data-sentry-source-file="StackOfLinks.tsx" />
      <div className="border-main h-px border-t" />
      {links.map(link => <Body key={link.href} className="truncate">
          <StyledLink style="underline" href={link.href}>
            {link.text}
          </StyledLink>
        </Body>)}
    </div>;
};