import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useCustomerIdentificationProgramTestingBatchListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CustomerIdentificationProgramTestingBatchTable } from '../tables/CustomerIdentificationProgramTestingBatchTable';
export const CustomerIdentificationProgramTestingBatchListPage = () => {
  const batchList = useCustomerIdentificationProgramTestingBatchListInfinite({});
  return <PageLayout headline={'Customer Identification Program Tests'} data-sentry-element="PageLayout" data-sentry-component="CustomerIdentificationProgramTestingBatchListPage" data-sentry-source-file="CustomerIdentificationProgramTestingBatchListPage.tsx">
      <TableStateWrapper table={CustomerIdentificationProgramTestingBatchTable} style="primary" {...batchList} emptyTitle="No program tests" emptySubtitle="There are no tests to display" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="CustomerIdentificationProgramTestingBatchListPage.tsx" />
    </PageLayout>;
};