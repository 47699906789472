import { Body } from './Text';
export type ErrorMessageProps = {
  message: string;
};
export const ErrorMessage = ({
  message
}: ErrorMessageProps) => {
  return <div className="border-danger bg-danger rounded border p-2" data-sentry-component="ErrorMessage" data-sentry-source-file="ErrorMessage.tsx">
      <Body color="red" data-sentry-element="Body" data-sentry-source-file="ErrorMessage.tsx">{message}</Body>
    </div>;
};