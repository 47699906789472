import { DateTime } from 'luxon';
import { DateFormat, formatISO8601Timestamp } from '../../lib/formatting';
import { Caption } from '../Text';
import { CartesianGrid, YAxis, XAxis } from 'recharts';
export interface RollupChartRawEntry {
  label: string;
  value: string;
  timestamp: string;
}
export type TruncationPeriod = 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
export interface Rollup {
  entries: RollupChartRawEntry[];
  start_time: string;
  end_time: string;
  truncation_period: TruncationPeriod;
}
export const getDateFormatParameterForXAxis = (truncationPeriod: TruncationPeriod): DateFormat => {
  switch (truncationPeriod) {
    case 'minute':
      return 'time';
    case 'hour':
      return 'month-day-hour';
    case 'day':
      return 'month-day-numeric';
    case 'week':
      return 'month-day-year-numeric';
    case 'month':
      return 'month-year';
    case 'quarter':
      return 'month-year';
    case 'year':
      return 'month-year';
  }
};
export const getDateFormatParameterForTooltip = (truncationPeriod: TruncationPeriod): DateFormat => {
  switch (truncationPeriod) {
    case 'minute':
      return 'time';
    case 'hour':
      return 'month-day-hour';
    case 'day':
      return 'medium';
    case 'week':
      return 'medium';
    case 'month':
      return 'month-year';
    case 'quarter':
      return 'month-year';
    case 'year':
      return 'month-year';
  }
};
export type DragTimePeriod = {
  startLabel: string;
  startDateTime: DateTime;
  endLabel: string;
  endDateTime: DateTime;
};
export type RollupDimension<T extends string> = {
  value: T;
  onChange: (value: T) => void;
  options: ReadonlyArray<{
    value: T;
    title: string;
  }>;
};
export const DragHintTooltip = (props: {
  dateFormat: DateFormat;
  dragTimePeriod: DragTimePeriod;
}) => {
  const {
    dragTimePeriod,
    dateFormat
  } = props;
  return <div className="border-main bg-subtle mx-4 rounded border p-3 shadow-md" data-sentry-component="DragHintTooltip" data-sentry-source-file="common.tsx">
      <Caption color="emphasis" weight="medium" data-sentry-element="Caption" data-sentry-source-file="common.tsx">
        {formatISO8601Timestamp(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dragTimePeriod.startDateTime.toISO()!, dateFormat, 'UTC')}{' '}
        -{' '}
        {formatISO8601Timestamp(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dragTimePeriod.endDateTime.toISO()!, dateFormat, 'UTC')}
      </Caption>
      <Caption color="secondary" data-sentry-element="Caption" data-sentry-source-file="common.tsx">Release to filter to this time period</Caption>
    </div>;
};
export const StyledGrid = () => <CartesianGrid stroke="#D5DEE5" strokeWidth={0.5} strokeDasharray="3 3" data-sentry-element="CartesianGrid" data-sentry-component="StyledGrid" data-sentry-source-file="common.tsx" />;
export const StyledYAxis = (props: {
  formatValue: (value: number) => string;
}) => <YAxis type="number" hide={true} tickCount={8} tickLine={false} axisLine={false} dy={-16} allowDecimals={false} interval={0} tickFormatter={props.formatValue} tick={{
  fontFamily: 'TT Interphases Pro',
  fontSize: 12,
  fill: '#9da8b2'
}} orientation="right" mirror={true} data-sentry-element="YAxis" data-sentry-component="StyledYAxis" data-sentry-source-file="common.tsx" />;
export const StyledXAxis = (props: {
  dateFormat: DateFormat;
}) => <XAxis dataKey="timestamp" tickLine={false} stroke="#EDF1F5" interval="preserveEnd" minTickGap={20} tick={{
  fontFamily: 'TT Interphases Pro',
  fontSize: 12,
  fill: '#9da8b2'
}} padding="no-gap" tickFormatter={timestamp => {
  return formatISO8601Timestamp(timestamp, props.dateFormat);
}} data-sentry-element="XAxis" data-sentry-component="StyledXAxis" data-sentry-source-file="common.tsx" />;