import React from 'react';
import { Dialog } from '@headlessui/react';
import { Section } from './deprecated/Section';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { TextInput } from './deprecated/TextInput';
import { Button } from './deprecated/Button';
import { useState } from 'react';
import { useDigitalWalletImageFileList, usePhysicalCardImageFileList, useDigitalWalletImageFileApprove, usePhysicalCardImageFileApprove, useDigitalWalletImageFileReject, usePhysicalCardImageFileReject } from '../hooks/reactQuery';
import { useServices } from '../hooks/use-services';
import { DigitalWalletImageFileListResponseDataItemStatus, PhysicalCardImageFileListResponseDataItemStatus, DigitalWalletImageFileListResponseDataItemImageType } from '../build/operations/operations.schemas';
import { absurd } from 'src/lib/absurd';
import classNames from 'classnames';
import { AlertList } from '@increase/shared/components/AlertList';
import { Badge } from 'shared/components/Badge';
const LIMIT = 4;
const ObjectLink = ({
  id
}: {
  id: string;
}) => <a href={`https://administration.increase.com/object/${id}`} data-sentry-component="ObjectLink" data-sentry-source-file="card-profile-images.tsx">{id}</a>;
const StoreVisaDetailsButton = ({
  digitalWalletImageFileId
}: {
  digitalWalletImageFileId: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const approveDigitalWalletImageFile = useDigitalWalletImageFileApprove();
  const [newVisaIdentifier, setNewVisaIdentifier] = useState('');
  const submit = () => {
    approveDigitalWalletImageFile.mutateAsync([digitalWalletImageFileId, {
      visa_identifier: newVisaIdentifier
    }]).then(() => {
      setModalShowing(false);
    });
  };
  return <>
      <Button data-testid="showModalButton" className="bg-success p-2" onClick={() => setModalShowing(true)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
        Approve
      </Button>

      <Dialog open={modalShowing} onClose={() => setModalShowing(false)} className={'relative z-50'} data-sentry-element="Dialog" data-sentry-source-file="card-profile-images.tsx">
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4" data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">
            <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">Store Visa details</Dialog.Title>
            <p className="my-2 text-base text-subtle">
              Make sure the background image has a Visa logo in it, as described
              in the{' '}
              <a className="text-info" href="https://paper.dropbox.com/doc/Card-profile-image-reviewing--B9UtG~~3J96ZUSbjm~IdKlSoAg-FPZKUuzitz1QBygwHMSjh">
                review instructions
              </a>
              .
            </p>
            <div>
              <TextInput id="application.cardProfileImages.visaIdentifier" name="visa_identifier" value={newVisaIdentifier} placeholder="Visa identifier" onChange={(s: string) => setNewVisaIdentifier(s)} data-sentry-element="TextInput" data-sentry-source-file="card-profile-images.tsx" />
              {approveDigitalWalletImageFile.error && <AlertList tasks={[{
              icon: 'info',
              title: 'Error',
              body: approveDigitalWalletImageFile.error.response?.data.message,
              key: '0',
              style: 'error'
            }]} />}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button onClick={() => setModalShowing(false)} disabled={approveDigitalWalletImageFile.isLoading} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Cancel
              </Button>
              <Button onClick={submit} disabled={approveDigitalWalletImageFile.isLoading || !newVisaIdentifier} className="bg-success" data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>;
};
const ApprovePhysicalCardImageButton = ({
  physicalCardImageFileId
}: {
  physicalCardImageFileId: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const approvePhysicalCardImageFile = usePhysicalCardImageFileApprove();
  const submit = () => {
    approvePhysicalCardImageFile.mutateAsync([physicalCardImageFileId]).then(() => {
      setModalShowing(false);
    });
  };
  return <>
      <Button data-testid="showModalButton" className="bg-success p-2" onClick={() => setModalShowing(true)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
        Approve
      </Button>

      <Dialog open={modalShowing} onClose={() => setModalShowing(false)} className={'relative z-50'} data-sentry-element="Dialog" data-sentry-source-file="card-profile-images.tsx">
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4" data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">
            <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">Approve</Dialog.Title>
            <div>
              {approvePhysicalCardImageFile.error && <AlertList tasks={[{
              icon: 'info',
              title: 'Error',
              body: approvePhysicalCardImageFile.error.response?.data.message,
              key: '0',
              style: 'error'
            }]} />}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button onClick={() => setModalShowing(false)} disabled={approvePhysicalCardImageFile.isLoading} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Cancel
              </Button>
              <Button onClick={submit} disabled={approvePhysicalCardImageFile.isLoading} className="bg-success" data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>;
};
const RejectImageButton = ({
  rejecter,
  id
}: {
  rejecter: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  id: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const submit = () => {
    rejecter.mutateAsync([id]).then(() => {
      setModalShowing(false);
    });
  };
  return <>
      <Button data-testid="showModalButton" className="mx-2 bg-danger p-2" onClick={() => setModalShowing(true)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
        Reject
      </Button>

      <Dialog open={modalShowing} onClose={() => setModalShowing(false)} className={'relative z-50'} data-sentry-element="Dialog" data-sentry-source-file="card-profile-images.tsx">
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4" data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">
            <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="card-profile-images.tsx">Reject</Dialog.Title>
            <div>
              {rejecter.error && <AlertList tasks={[{
              icon: 'info',
              title: 'Error',
              body: rejecter.error.response?.data.message,
              key: '0',
              style: 'error'
            }]} />}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button onClick={() => setModalShowing(false)} disabled={rejecter.isLoading} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Cancel
              </Button>
              <Button onClick={submit} disabled={rejecter.isLoading} className="bg-danger" data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>;
};
const DigitalWalletImages = () => {
  const [page, setPage] = useState(0);
  const {
    operations
  } = useServices();
  const rejectDigitalWalletImageFile = useDigitalWalletImageFileReject();
  const {
    data
  } = useDigitalWalletImageFileList({
    offset: page * LIMIT,
    limit: LIMIT
  });
  if (!data) {
    return <h3>Loading</h3>;
  }
  return <Section header="Digital wallet images" data-sentry-element="Section" data-sentry-component="DigitalWalletImages" data-sentry-source-file="card-profile-images.tsx">
      <Table data={data.data} columns={[{
      key: 'status',
      label: 'Status',
      render: record => {
        switch (record.status) {
          case DigitalWalletImageFileListResponseDataItemStatus.pending:
            return <Badge color="yellow" label="Pending" />;
          case DigitalWalletImageFileListResponseDataItemStatus.requires_attention:
            return <Badge color="yellow" label="Requires attention" />;
          case DigitalWalletImageFileListResponseDataItemStatus.rejected:
            return <Badge color="red" label="Rejected" />;
          case DigitalWalletImageFileListResponseDataItemStatus.active:
            return <Badge color="green" label="Active" />;
          default:
            absurd(record.status);
        }
      }
    }, {
      key: 'id',
      label: 'ID',
      render: record => <ObjectLink id={record.id} />
    }, {
      key: 'image_type',
      label: 'Image type'
    }, {
      key: 'card_profiles',
      label: 'Card profiles',
      render: record => <ul>
                {record.card_profiles.map(cardProfile => <li key={cardProfile.id} className="px-5">
                    <ObjectLink id={cardProfile.id} />{' '}
                    <em>({cardProfile.status})</em>
                  </li>)}
              </ul>
    }, {
      key: 'image',
      label: 'Image',
      render: record => {
        const url = `${operations}/api_files/${record.api_file_id}/view`;
        return <a href={url}>
                  <img alt={record.id} className={classNames(`border border-strong shadow-md`, record.image_type === DigitalWalletImageFileListResponseDataItemImageType.background ? `w-48` : `w-8`)} src={url} />
                </a>;
      }
    }, {
      key: 'actions',
      label: 'Actions',
      render: record => record.status === 'pending' ? <>
                  <StoreVisaDetailsButton digitalWalletImageFileId={record.id} />
                  <RejectImageButton rejecter={rejectDigitalWalletImageFile} id={record.id} />
                </> : 'No action'
    }]} data-sentry-element="Table" data-sentry-source-file="card-profile-images.tsx" />
      <div className="my-5 flex flex-row gap-8">
        <div>Page {page + 1}</div>
        <div>
          <Button disabled={page === 0} onClick={() => setPage(page - 1)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
            Previous Page
          </Button>
          <Button className="mx-2" disabled={!data || data.data.length < LIMIT} onClick={() => setPage(page + 1)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
            Next Page
          </Button>
        </div>
      </div>
    </Section>;
};
const PhysicalCardImages = () => {
  const [page, setPage] = useState(0);
  const {
    operations
  } = useServices();
  const rejectPhysicalCardImageFile = usePhysicalCardImageFileReject();
  const {
    data
  } = usePhysicalCardImageFileList({
    offset: page * LIMIT,
    limit: LIMIT
  });
  if (!data) {
    return <h3>Loading</h3>;
  }
  return <Section header="Physical card images" data-sentry-element="Section" data-sentry-component="PhysicalCardImages" data-sentry-source-file="card-profile-images.tsx">
      <Table data={data.data} columns={[{
      key: 'status',
      label: 'Status',
      render: record => {
        switch (record.status) {
          case PhysicalCardImageFileListResponseDataItemStatus.pending_reviewing:
            return <Badge color="yellow" label="Pending reviewing" />;
          case PhysicalCardImageFileListResponseDataItemStatus.pending_submitting:
            return <Badge color="yellow" label="Pending submitting" />;
          case PhysicalCardImageFileListResponseDataItemStatus.rejected:
            return <Badge color="red" label="Rejected" />;
          case PhysicalCardImageFileListResponseDataItemStatus.requires_attention:
            return <Badge color="yellow" label="Requires attention" />;
          case PhysicalCardImageFileListResponseDataItemStatus.submitted:
            return <Badge color="green" label="Active" />;
          default:
            absurd(record.status);
        }
      }
    }, {
      key: 'id',
      label: 'ID',
      render: record => <ObjectLink id={record.id} />
    }, {
      key: 'image_type',
      label: 'Image type'
    }, {
      key: 'card_profiles',
      label: 'Card profiles',
      render: record => <ul>
                {record.card_profiles.map(cardProfile => <li key={cardProfile.id} className="px-5">
                    <ObjectLink id={cardProfile.id} />{' '}
                    <em>({cardProfile.status})</em>
                  </li>)}
              </ul>
    }, {
      key: 'image',
      label: 'Image',
      render: record => {
        const url = `${operations}/api_files/${record.transformed_api_file_id}/view`;
        return <a href={url}>
                  <img alt={record.id} className="w-48 border border-strong shadow-md" src={url} />
                </a>;
      }
    }, {
      key: 'actions',
      label: 'Actions',
      render: record => record.status === 'pending_reviewing' ? <>
                  <ApprovePhysicalCardImageButton physicalCardImageFileId={record.id} />
                  <RejectImageButton rejecter={rejectPhysicalCardImageFile} id={record.id} />
                </> : 'No action'
    }]} data-sentry-element="Table" data-sentry-source-file="card-profile-images.tsx" />
      <div className="my-5 flex flex-row gap-8">
        <div>Page {page + 1}</div>
        <div>
          <Button disabled={page === 0} onClick={() => setPage(page - 1)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
            Previous Page
          </Button>
          <Button className="mx-2" disabled={!data || data.data.length < LIMIT} onClick={() => setPage(page + 1)} data-sentry-element="Button" data-sentry-source-file="card-profile-images.tsx">
            Next Page
          </Button>
        </div>
      </div>
    </Section>;
};
export const CardProfileImages = () => {
  return <PageLayout headline={<span>
          Card profile images{' '}
          <a className="text-subtle" href="https://paper.dropbox.com/doc/Card-profile-image-reviewing--B9UtG~~3J96ZUSbjm~IdKlSoAg-FPZKUuzitz1QBygwHMSjh">
            (instructions)
          </a>
        </span>} data-sentry-element="PageLayout" data-sentry-component="CardProfileImages" data-sentry-source-file="card-profile-images.tsx">
      <>
        <DigitalWalletImages data-sentry-element="DigitalWalletImages" data-sentry-source-file="card-profile-images.tsx" />
        <PhysicalCardImages data-sentry-element="PhysicalCardImages" data-sentry-source-file="card-profile-images.tsx" />
      </>
    </PageLayout>;
};