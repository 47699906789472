import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useDocumentRequestScheduleListEntire, useDocumentRequestSchedulePost, useProgramGet } from 'src/hooks/reactQuery';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
import { fromPairs, values } from 'lodash';
import { DocumentRequestSchedulePostResponseCadence, DocumentRequestSchedulePostResponseCategory } from 'src/build/operations';
import { humanize } from '@increase/shared/lib/formatting';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Body, Heading } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
import { Select } from '@increase/shared/components/Select';
import { DateInput } from '@increase/shared/components/DateInput';
import moment from 'moment';
type RowProps = {
  category: DocumentRequestSchedulePostResponseCategory;
  cadence: DocumentRequestSchedulePostResponseCadence;
  programId: string;
  firstRunOn?: string | null;
};
const nextQuarter = (): string => {
  return moment().add(1, 'quarter').startOf('quarter').toISOString();
};
const GRID = 'grid max-w-[600px] grid-cols-3 gap-2';
const Row = ({
  category,
  programId,
  cadence,
  firstRunOn
}: RowProps) => {
  const mutate = useDocumentRequestSchedulePost();
  return <div className={GRID} data-sentry-component="Row" data-sentry-source-file="DocumentRequestSchedulePage.tsx">
      <StyledLink style="underline" href={buildPath(ROUTES.DOCUMENT_REQUESTS_LIST, {}, {
      program: [programId],
      categories: [category === 'financial_report' ? 'platform_financial_report_submission_request' : category === 'platform_compliance_metrics' ? 'platform_compliance_metrics_submission_request' : 'platform_periodic_templated_spreadsheet_report_request'],
      subcategories: category === 'financial_report' || category === 'platform_compliance_metrics' ? [] : [category]
    })} data-sentry-element="StyledLink" data-sentry-source-file="DocumentRequestSchedulePage.tsx">
        <Body data-sentry-element="Body" data-sentry-source-file="DocumentRequestSchedulePage.tsx">{humanize(category)}</Body>
      </StyledLink>
      <Select sections={[{
      options: [{
        label: 'Monthly',
        value: 'monthly'
      }, {
        label: 'Quarterly',
        value: 'quarterly'
      }, {
        label: 'Never',
        value: 'never'
      }]
    }]} value={cadence} onUpdate={newCadence => mutate.mutateAsync([{
      program_id: programId,
      category: category as DocumentRequestSchedulePostResponseCategory,
      cadence: newCadence,
      first_run_on: newCadence === 'never' ? undefined : nextQuarter()
    }])} data-sentry-element="Select" data-sentry-source-file="DocumentRequestSchedulePage.tsx" />
      {firstRunOn && <DateInput value={firstRunOn} onChange={v => {
      mutate.mutateAsync([{
        program_id: programId,
        category: category as DocumentRequestSchedulePostResponseCategory,
        cadence: cadence,
        first_run_on: v.target.value
      }]);
    }} />}
    </div>;
};
export const DocumentRequestSchedulePage = () => {
  const {
    programId
  } = useTypedParams(ROUTES.DOCUMENT_REQUEST_SCHEDULE);
  const {
    data: program
  } = useProgramGet(programId);
  const {
    data
  } = useDocumentRequestScheduleListEntire({
    program_id: programId
  });
  const groupRow = useGroupPropertyRow(program?.group_id);
  const programRow = useProgramPropertyRow(programId);
  if (!data) {
    return null;
  }
  const cadences = fromPairs(data.map(schedule => [schedule.category, schedule]));
  return <PageLayout headline={'Document request schedule'} data-sentry-element="PageLayout" data-sentry-component="DocumentRequestSchedulePage" data-sentry-source-file="DocumentRequestSchedulePage.tsx">
      <SideBySide mode="rightDrawer" left={<Box>
            <Heading>Select which reports you'd like to send</Heading>
            <div className={GRID}>
              <Heading size="small">Category</Heading>
              <Heading size="small">Cadence</Heading>
              <Heading size="small">Start on</Heading>
            </div>
            {values(DocumentRequestSchedulePostResponseCategory).map(category => {
        const schedule = cadences[category];
        return <Row key={category} category={category} programId={programId} cadence={schedule?.cadence || DocumentRequestSchedulePostResponseCadence.never} firstRunOn={schedule?.first_run_on} />;
      })}
          </Box>} right={<PropertyList title="Details" items={[groupRow, programRow]} layoutHorizontallyWhenPossible={false} />} data-sentry-element="SideBySide" data-sentry-source-file="DocumentRequestSchedulePage.tsx" />
    </PageLayout>;
};