import { useMemo } from 'react';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { humanize } from '@increase/shared/lib/formatting';
import { BeneficialOwnerRelationshipListResponseDataItem } from 'src/build/operations';
type EntitySetupCorporationBeneficialOwnerRelationshipsTableProps = PrestyledTableProps<BeneficialOwnerRelationshipListResponseDataItem> & {
  title: string;
};
export const EntitySetupCorporationBeneficialOwnerRelationshipsTable = (props: EntitySetupCorporationBeneficialOwnerRelationshipsTableProps) => {
  const {
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BeneficialOwnerRelationshipListResponseDataItem>>(() => {
    return [{
      header: 'Entity',
      contents: relationship => {
        return {
          text: relationship.entity_setup_corporation_submission_name,
          href: '/entities/' + relationship.entity_id
        };
      }
    }, {
      header: 'Prong',
      contents: relationship => {
        return {
          text: humanize(relationship.prong)
        };
      }
    }, {
      header: 'Title',
      contents: relationship => {
        return {
          text: relationship.submitted_company_title || ''
        };
      }
    }, {
      header: 'Status',
      contents: relationship => {
        return {
          text: relationship.archived ? 'Archived' : 'Active'
        };
      }
    }];
  }, []);
  return <Table columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="EntitySetupCorporationBeneficialOwnerRelationshipsTable" data-sentry-source-file="EntitySetupCorporationBeneficialOwnerRelationshipsTable.tsx" />;
};