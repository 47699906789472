type InputType<
  Categories extends string,
  OrvalType extends Record<Categories, null | Record<string, unknown>>,
> = {
  [K in Categories]: OrvalType[K] | null;
} & {
  category: Categories | 'other';
};

type OutputType<
  Categories extends string,
  OrvalType extends Record<Categories, null | Record<string, unknown>>,
> = {
  [K in Categories]: NonNullable<OrvalType[K]>;
} & { category: Categories };

export const isSubHashAnyOf = <
  C extends 'other' | string,
  O extends Record<C, null | Record<string, unknown>>,
>(
  inputType: InputType<C, O>
): inputType is OutputType<C, O> => {
  if (inputType.category === 'other') {
    return false;
  } else {
    return inputType[inputType.category] !== null;
  }
};
