import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { useEffect, useMemo, useState } from 'react';
import { SanctionsScreeningTriggerTokenListResponseDataItem } from 'src/build/operations';
import { useConfirmModal } from '@increase/shared/components/ConfirmModal';
import { useSanctionsScreeningTriggerWordsArchivePost } from 'src/hooks/reactQuery';
type Props = PrestyledTableProps<SanctionsScreeningTriggerTokenListResponseDataItem>;
export const SanctionsScreeningTriggerWordsTable = (props: Props) => {
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const x = useSanctionsScreeningTriggerWordsArchivePost();
  const {
    setModalOpen
  } = useConfirmModal({
    title: 'Confirm archive?',
    text: 'Are you sure you want to delete this word or phrase?',
    confirmText: 'Yes, delete',
    confirmIcon: 'trash',
    cancelText: 'Go back',
    confirmStyle: 'warning',
    onConfirm: async () => {
      if (!toDelete) {
        return;
      }
      setToDelete(undefined);
      return x.mutateAsync([toDelete]);
    },
    onCancel: async () => setToDelete(undefined)
  });
  useEffect(() => {
    if (toDelete) {
      setModalOpen(true);
    }
  });
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<SanctionsScreeningTriggerTokenListResponseDataItem>>(() => {
    return [{
      header: 'Word or phrase',
      iconType: 'account',
      expand: 1,
      contents: token => ({
        text: token.token,
        textWeight: 'mono'
      })
    }, {
      header: 'Creator',
      iconType: 'account',
      contents: token => ({
        text: token.created_by_email
      })
    }, {
      header: 'Created at',
      iconType: 'account',
      contents: token => ({
        text: formatISO8601Timestamp(token.created_at, 'full')
      })
    }, {
      header: 'Actions',
      contents: token => ({
        menuItems: [{
          key: 'delete',
          icon: 'trash',
          title: 'Archive',
          onClick: () => {
            setToDelete(token.id);
          }
        }]
      })
    }];
  }, []);
  const defaultRowProps = () => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<SanctionsScreeningTriggerTokenListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="SanctionsScreeningTriggerWordsTable" data-sentry-source-file="SanctionsScreeningTriggerWordsTable.tsx" />;
};