import { PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { ProgramGetResponse } from 'src/build/operations';
type ProgramPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const ProgramReportingConfigurationPropertyList = (props: ProgramPropertyListProps) => {
  const {
    program,
    ...rest
  } = props;
  const items = [{
    label: 'Reports third-party risk management',
    value: program.allows_submitting_vendors
  }, {
    label: 'Reports security incidents',
    value: program.allows_submitting_security_incident_reports
  }, {
    label: 'Files unusual activity reports',
    value: program.allows_submitting_unusual_activity_reports
  }, {
    label: 'Request complaints list periodically',
    value: program.request_complaint_lists_automatically
  }];
  if (items.filter(({
    value
  }) => value).length === 0) {
    return <></>;
  }
  return <PropertyList {...rest} items={items.map(({
    label,
    value
  }) => ({
    label,
    value: value ? 'true' : 'false'
  }))} data-sentry-element="PropertyList" data-sentry-component="ProgramReportingConfigurationPropertyList" data-sentry-source-file="ProgramReportingConfigurationPropertyList.tsx" />;
};