import { humanize } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from './routes';

const SKIP_SOURCE_KEYS = [
  'id',
  'created_at',
  'updated_at',
  'amount',
  'account_id',
  'created_by_id',
  'created_by_type',
  'description',
  'group_id',
];

const TRANSFER_IDS = ['transfer_id', 'check_deposit_id'];

export const extractSourceFields = ({
  object,
  operations,
  prefix = '',
}: {
  object: object;
  operations: string;
  prefix?: string;
}): { label: string; value: string; href?: string }[] => {
  return Object.entries(object)
    .filter(([key, value]) => !SKIP_SOURCE_KEYS.includes(key) && value != null)
    .map(([key, value]) => {
      if (value && typeof value == 'object') {
        return extractSourceFields({
          object: value,
          prefix: prefix + key + '.',
          operations,
        });
      } else {
        let href = undefined;

        if (key.endsWith('file_id')) {
          href = operations + '/api_files/' + value + '/view';
        }

        if (TRANSFER_IDS.find((t) => key.endsWith(t))) {
          href = buildPath(ROUTES.TRANSFER_DETAIL, {
            transferID: value,
          });
        }

        return [
          { label: humanize(`${prefix}${key}`), value: '' + value, href },
        ];
      }
    })
    .reduce((a, b) => a.concat(b), []);
};
