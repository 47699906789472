import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
type Props = {
  entity_setup_identity_document_submission: {
    id: string;
    identity_verification_status: string;
  };
};
export const EntitySetupIdentityDocumentSubmission = ({
  entity_setup_identity_document_submission
}: Props) => {
  const entitySetupIdentityDocumentSubmission = entity_setup_identity_document_submission;
  return <PropertyList items={[{
    label: 'Document',
    value: 'View',
    href: buildPath(ROUTES.IDENTITY_DOCUMENT_REVIEW, {
      identityDocumentID: entitySetupIdentityDocumentSubmission.id
    })
  }, {
    label: 'Status',
    value: entitySetupIdentityDocumentSubmission.identity_verification_status
  }]} data-sentry-element="PropertyList" data-sentry-component="EntitySetupIdentityDocumentSubmission" data-sentry-source-file="entity-setup-identity-document-submission.tsx" />;
};