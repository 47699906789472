import { compact, flatten, uniq } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Heading } from '@increase/shared/components/Text';
import { CompletedTransactionListResponseDataItem, ExternalAccountGetResponseRoutingNumberDetailsAnyOf, FraudKnownBadSubmissionsSearchResponseDataItem } from 'src/build/operations';
import { useFraudKnownBadSubmissionsSearch, useExternalAccountGet, useEntityReferenceListInfinite, useTransactionsByAccountAndRoutingNumberListInfinite } from 'src/hooks/reactQuery';
import { ROUTES } from 'src/lib/routes';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CompletedTransactionsTable, CompletedTransactionsTableProps } from '../tables/CompletedTransactionTable';
import { FraudKnownBadSubmissionsTable } from '../tables/FraudKnownBadSubmissionsTable';
import { Loading } from '@increase/shared/components/Loading';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { EntitiesTable } from '../tables/EntitiesTable';
const ExternalAccountTransactions = (props: {
  routingNumber: string;
  accountNumber: string;
}) => {
  const transactionsListResult = useTransactionsByAccountAndRoutingNumberListInfinite({
    routing_number: props.routingNumber,
    account_number: props.accountNumber
  });
  return <TableStateWrapper<CompletedTransactionListResponseDataItem, CompletedTransactionsTableProps> {...transactionsListResult} table={CompletedTransactionsTable} style="detail" title="Sample transactions" emptyTitle="No transactions" emptySubtitle="There are no transactions." showAccount={true} showGroup={true} showRoute={false} viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-component="ExternalAccountTransactions" data-sentry-source-file="ExternalAccountDetailPage.tsx" />;
};
const ExternalAccountEntities = (props: {
  externalAccountId: string;
}) => {
  const entityListResult = useEntityReferenceListInfinite({
    external_account_id: props.externalAccountId
  });
  return <TableStateWrapper table={EntitiesTable} {...entityListResult} style="detail" title="Sample entities" emptyTitle="No entities" emptySubtitle="There are no entities to display." showGroup={true} viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-component="ExternalAccountEntities" data-sentry-source-file="ExternalAccountDetailPage.tsx" />;
};
const ExternalAccountKnownBadSubmissions = (props: {
  entityIDs: string[];
}) => {
  const {
    data,
    error
  } = useFraudKnownBadSubmissionsSearch({
    entity_ids: props.entityIDs
  });
  if (!data) {
    return <Loading />;
  }
  return <TableStateWrapper<FraudKnownBadSubmissionsSearchResponseDataItem> table={FraudKnownBadSubmissionsTable} data={data.data} error={error} style="detail" title="Sample known bad submissions" emptyTitle="No known bad submissions" emptySubtitle="There are no known bad submissions." viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-component="ExternalAccountKnownBadSubmissions" data-sentry-source-file="ExternalAccountDetailPage.tsx" />;
};
const routingNumberProperties = (routingNumberDetails: ExternalAccountGetResponseRoutingNumberDetailsAnyOf) => {
  const displayFinancialInstitutionIconUrl = (financialInstitutionIconUrl: string | null) => {
    if (!financialInstitutionIconUrl) {
      return null;
    }
    return <img src={financialInstitutionIconUrl} className="h-4 w-4" alt="icon" data-sentry-component="displayFinancialInstitutionIconUrl" data-sentry-source-file="ExternalAccountDetailPage.tsx" />;
  };
  const displayFinancialInstitutionWebsite = (financialInstitutionWebsite: string | null) => {
    if (!financialInstitutionWebsite) {
      return null;
    }
    return <StyledLink href={financialInstitutionWebsite} target="_blank" data-sentry-element="StyledLink" data-sentry-component="displayFinancialInstitutionWebsite" data-sentry-source-file="ExternalAccountDetailPage.tsx">
        {financialInstitutionWebsite}
      </StyledLink>;
  };
  return [{
    label: 'Name',
    value: routingNumberDetails.pretty_name || routingNumberDetails.name
  }, {
    label: 'ACH',
    valueNode: routingNumberDetails.fedach_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />
  }, {
    label: 'Wire',
    valueNode: routingNumberDetails.fedwire_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />
  }, {
    label: 'RTP',
    valueNode: routingNumberDetails.real_time_payments_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />
  }, {
    label: 'Icon',
    valueNode: displayFinancialInstitutionIconUrl(routingNumberDetails.financial_institution_icon_url)
  }, {
    label: 'Website',
    valueNode: displayFinancialInstitutionWebsite(routingNumberDetails.financial_institution_website)
  }];
};
export const ExternalAccountDetailPage = () => {
  const {
    externalAccountID
  } = useTypedParams(ROUTES.EXTERNAL_ACCOUNTS_DETAILS);
  const {
    data
  } = useExternalAccountGet(externalAccountID);
  if (!data) {
    return <Loading />;
  }
  const entityIDs = uniq(compact(data.external_account_transactions_sample.map(transaction => transaction.entity_id)));
  return <PageLayout id="application.service.external_account" headline={`External Account ${externalAccountID}`} data-sentry-element="PageLayout" data-sentry-component="ExternalAccountDetailPage" data-sentry-source-file="ExternalAccountDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<Box>
            <Heading>Details</Heading>
            <PropertyList items={compact<ListItem>(flatten([{
        label: 'Routing #',
        value: data.routing_number
      }, {
        label: 'Account #',
        value: data.account_number
      }, data.routing_number_details && routingNumberProperties(data.routing_number_details)]))} />
          </Box>} left={<Box>
            <ExternalAccountKnownBadSubmissions entityIDs={entityIDs} />
            <ExternalAccountTransactions routingNumber={data.routing_number} accountNumber={data.account_number} />
            <ExternalAccountEntities externalAccountId={data.id} />
          </Box>} data-sentry-element="SideBySide" data-sentry-source-file="ExternalAccountDetailPage.tsx" />
    </PageLayout>;
};