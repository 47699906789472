import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useCardListInfinite } from 'src/hooks/reactQuery';
import { CardsTable } from '../tables/CardsTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { CardGetResponseStatus } from 'src/build/operations';
import { makeStatusFilter, makeGroupFilter, makeAccountFilter, makeKeywordFilter, makeTimeRangeFilter, makeEntityFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
const cardStatuses: Record<CardGetResponseStatus, string> = {
  active: 'Active',
  disabled: 'Disabled',
  canceled: 'Canceled'
} as const;
export const CardListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.CARDS_LIST, 'group');
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.CARDS_LIST, 'statuses');
  const [accountFilter, setAccountFilter] = useSearchParamsState(ROUTES.CARDS_LIST, 'account');
  const [entityFilter, setEntityFilter] = useSearchParamsState(ROUTES.CARDS_LIST, 'entity');
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(ROUTES.CARDS_LIST, 'keyword');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.CARDS_LIST);
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeStatusFilter(statuses, setStatuses, cardStatuses), makeGroupFilter(groupFilter, setGroupFilter), makeAccountFilter(accountFilter, setAccountFilter), makeEntityFilter(entityFilter, setEntityFilter), makeKeywordFilter(keywordFilter, setKeywordFilter), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created')]), [accountFilter, endAt, groupFilter, keywordFilter, setAccountFilter, setGroupFilter, setKeywordFilter, setStatuses, setTimeRange, startAt, statuses, entityFilter, setEntityFilter]);
  const listResult = useCardListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    account_ids: accountFilter.length > 0 ? accountFilter : undefined,
    entity_ids: entityFilter.length > 0 ? entityFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined
  });
  return <PageLayout headline={'Cards'} data-sentry-element="PageLayout" data-sentry-component="CardListPage" data-sentry-source-file="CardListPage.tsx">
      <TableStateWrapper table={CardsTable} style="primary" {...listResult} emptyTitle="No Cards" emptySubtitle="There are no cards to display" filters={filters} showGroup showAccount data-sentry-element="TableStateWrapper" data-sentry-source-file="CardListPage.tsx" />
    </PageLayout>;
};