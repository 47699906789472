import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, formatAmount, formatInteger } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { ProgramCreditExposureGetResponseUnreleasedSummariesItem } from 'src/build/operations';
type RowType = ProgramCreditExposureGetResponseUnreleasedSummariesItem & {
  id: string;
};
type UnreleasedCreditExposureTransactionSummariesTableProps = PrestyledTableProps<RowType>;
export const UnreleasedCreditExposureTransactionSummariesTable = ({
  ...rest
}: UnreleasedCreditExposureTransactionSummariesTableProps) => {
  const columns = useMemo<TableColumns<RowType>>(() => [{
    header: 'Source Type',
    contents: summary => ({
      text: summary.source_type.replaceAll('API::', '')
    })
  }, {
    header: 'Amount',
    contents: summary => ({
      text: formatAmount(summary.amount, summary.currency)
    })
  }, {
    header: 'Releases At',
    contents: summary => ({
      text: summary.releases_at ? formatISO8601Timestamp(summary.releases_at, 'month-day-hour-minute') : '-'
    })
  }, {
    header: 'Count',
    contents: summary => ({
      text: formatInteger(summary.count)
    })
  }], []);
  return <Table columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="UnreleasedCreditExposureTransactionSummariesTable" data-sentry-source-file="UnreleasedCreditExposureTransactionSummariesTable.tsx" />;
};