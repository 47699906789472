import { PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatInteger } from '@increase/shared/lib/formatting';
import { useGroupGet } from 'src/hooks/reactQuery';
type GroupAPILimitsPropertyListProps = Omit<PropertyListProps, 'items'> & {
  groupId: string;
};
export const GroupAPILimitsPropertyList = (props: GroupAPILimitsPropertyListProps) => {
  const {
    groupId
  } = props;
  const {
    data: group
  } = useGroupGet(groupId);
  if (!group) {
    return null;
  }
  return <PropertyList {...props} items={[{
    label: 'Maximum Number of subscriptions',
    value: group.maximum_event_subscription_count ? formatInteger(group.maximum_event_subscription_count) : 'Not set.'
  }]} data-sentry-element="PropertyList" data-sentry-component="GroupAPILimitsPropertyList" data-sentry-source-file="GroupAPILimitsPropertyList.tsx" />;
};