import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useControlConfigurationListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ControlConfigurationsTable } from '../tables/ControlConfigurationsTable';
import { ControlConfigurationGetResponseResult, ControlConfigurationListCategoriesItem } from 'src/build/operations';
import { makeEnumFilter, makeGroupFilter, makeProgramFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const ControlConfigurationsListPage = () => {
  const [results, setResults] = useSearchParamsState(ROUTES.CONTROLS_LIST, 'results');
  const [categories, setCategories] = useSearchParamsState(ROUTES.CONTROLS_LIST, 'categories');
  const [group, setGroup] = useSearchParamsState(ROUTES.CONTROLS_LIST, 'group');
  const [program, setProgram] = useSearchParamsState(ROUTES.CONTROLS_LIST, 'program');
  const controlConfigurationList = useControlConfigurationListInfinite({
    results: results.length === 0 ? undefined : results,
    categories: categories.length === 0 ? undefined : categories,
    groups: group.length === 0 ? undefined : group,
    program_ids: program.length === 0 ? undefined : program
  });
  return <PageLayout headline={'Controls'} data-sentry-element="PageLayout" data-sentry-component="ControlConfigurationsListPage" data-sentry-source-file="ControlConfigurationsListPage.tsx">
      <TableStateWrapper table={ControlConfigurationsTable} style="primary" {...controlConfigurationList} emptyTitle="No Controls" emptySubtitle="There are no controls to display" filters={[makeEnumFilter(results, setResults, 'Result', ControlConfigurationGetResponseResult, 'tick_circle'), makeEnumFilter(categories, setCategories, 'Category', ControlConfigurationListCategoriesItem, 'account'), makeGroupFilter(group, setGroup), makeProgramFilter(program, setProgram)]} data-sentry-element="TableStateWrapper" data-sentry-source-file="ControlConfigurationsListPage.tsx" />
    </PageLayout>;
};