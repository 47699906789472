import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, formatAmount } from '@increase/shared/lib/formatting';
import { formatBank } from 'src/lib/formatEnums';
import { useMemo } from 'react';
import { AccountGetResponse, AccountGetResponseStatus } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';
type AccountsTableProps = {
  showProgram: boolean;
  showBank: boolean;
  showGroup: boolean;
  showEntity: boolean;
} & PrestyledTableProps<AccountGetResponse>;
const readableStatus = (status: AccountGetResponseStatus): string => ({
  closed: 'Closed',
  open: 'Open',
  pending_opening: 'Pending opening'
})[status];
const getAccountEntity = (account: AccountGetResponse): {
  name: string | null;
  id: string | null;
} => {
  return {
    name: account.informational_entity_name ?? account.entity_name,
    id: account.informational_entity_id ?? account.entity_id
  };
};
export const AccountsTable = ({
  showBank,
  showProgram,
  showGroup,
  showEntity,
  ...rest
}: AccountsTableProps) => {
  const columns = useMemo<TableColumns<AccountGetResponse>>(() => [{
    expand: 1,
    header: 'Account name',
    contents: account => ({
      text: account.name,
      textWeight: 'medium',
      textColor: 'emphasis'
    })
  }, showBank && {
    header: 'Bank',
    contents: account => ({
      text: formatBank(account.bank)
    })
  }, showGroup && {
    header: 'Group',
    contents: account => ({
      text: account.group_id,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: account.group_id
      })
    })
  }, showProgram && {
    header: 'Program',
    contents: account => ({
      text: account.program_name,
      href: buildPath(ROUTES.PROGRAMS_DETAIL, {
        programId: account.program_id
      })
    })
  }, showEntity && {
    header: 'Entity',
    contents: account => {
      const entity = getAccountEntity(account);
      return {
        text: entity.name ?? '',
        href: entity.id ? buildPath(ROUTES.ENTITY, {
          entityId: entity.id
        }) : undefined
      };
    }
  }, {
    header: 'Opened',
    contents: account => ({
      text: account.opened_at ? formatISO8601Timestamp(account.opened_at, 'medium') : ''
    })
  }, {
    header: 'Status',
    contents: account => ({
      text: readableStatus(account.status),
      badgeColor: account.status === 'open' ? 'gray' : 'red'
    })
  }, {
    align: 'right',
    header: 'Balance',
    contents: account => ({
      text: formatAmount(account.current_balance, 'USD'),
      textWeight: 'bold',
      textColor: 'emphasis'
    })
  }], [showBank, showGroup, showProgram, showEntity]);
  const getRowProps = (datum: AccountGetResponse) => ({
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table columns={columns} getRowProps={rest.getRowProps ?? getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="AccountsTable" data-sentry-source-file="AccountsTable.tsx" />;
};