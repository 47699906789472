import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CheckDepositReview } from './check-deposit-review';
import { CheckDepositReviewList } from './check-deposit-review-list';
import { ACHReturnRate } from './ach-return-rate';
import { ACHReturnRates } from './ach-return-rates';
import { Entity } from './entity';
import { ObjectViewer } from './object-viewer';
import { GroupQueueItems } from './group-queue-items';
import { OperatorListPage } from './list_pages/OperatorListPage';
import { Results } from './results';
import { TransactionDetail } from './transaction-detail';
import { TransferReviewing } from './transfer-reviewing';
import { OutboundACHFiles } from './outbound-ach-files';
import { IdentityDocumentList } from './identity-document-list';
import { IdentityDocumentReview } from './identity-document-review';
import { ManualTaskList } from 'src/components/manual-task-list';
import { ManualTaskDetail } from 'src/components/manual-task-detail';
import { ManualTaskRunDetail } from 'src/components/manual-task-run-detail';
import { Unit21Object } from './unit21-object';
import { CheckItemAllocating } from './check-item-allocating';
import { Search } from './search';
import { NotFound } from './not-found';
import { Layout } from './layout';
import { EntitySetupBeneficialOwnerSubmissionPage } from './entity-setup-beneficial-owner-submission-page';
import { Groups } from './list_pages/GroupListPage';
import { UserSessions } from './user-sessions';
import { CheckItemsToAllocate } from './check-items-to-allocate';
import { PlatformComplaintListSubmission } from './platform-complaint-list-submission';
import { AllFederalReserveLimits } from './all-federal-reserve-limits';
import { FederalReserveLimits } from './federal-reserve-limits';
import { Gates } from './gates';
import { PlatformVendorListSubmission } from './platform-vendor-list-submission';
import { PlatformComplianceMetricsSubmission } from './platform-compliance-metrics-submission';
import { PlatformComplianceSubmissions } from './platform-compliance-submissions';
import { CardDetailPage } from './detail_pages/CardDetailPage';
import { CardProfileImages } from './card-profile-images';
import { PhysicalCardImages } from './physical-card-images';
import { ACHOriginationVolumeMonitoring } from './ach-origination-volume-monitoring';
import { LateReturnRequestListPage } from './list_pages/LateReturnRequestListPage';
import { LateReturnRequestCreate } from './late-return-request-create';
import { TransferLookup } from './transfer-lookup';
import { BookkeepingAccountDetailPage } from './detail_pages/BookkeepingAccountDetailPage';
import { DeclinedTransactionDetail } from './declined-transaction-detail';
import { RealTimePaymentsPrefundedPosition } from './real-time-payments-prefunded-position';
import { GroupFeePlansPage, ProgramFeePlansPage } from './fee-plans-list';
import { GroupFeeStatementsList } from './group-fee-statements-list';
import { RoutingNumberList } from './routing-number-list';
import { PendingTransactionDetail } from './pending-transaction-detail';
import { ObligationDocuments } from './obligation-documents';
import { ObligationDocumentDetail } from './obligation-documents-detail';
import { Attestations } from './attestations';
import { AttestationDetail } from './attestation-detail';
import { TransferDetailPage } from './detail_pages/TransferDetailPage';
import { GroupCardPaymentDetail } from './group-card-payment-detail';
import { GroupCardDeclineDetail } from './group-card-decline-detail';
import { Flags } from './flags';
import { UnusualActivityReports } from './unusual-activity-reports';
import { DirectUnusualActivityReport } from './unusual-activity-report';
import { DirectComplaintEntries } from './complaint-entries';
import { ComplianceDocuments } from './compliance-documents';
import { LateReturnRequestDetailPage } from './detail_pages/LateReturnRequestDetailPage';
import { PlatformComplaintListTab } from './list_tabs/PlatformComplaintListTab';
import { FedwireInboundServiceMessageListTab } from './list_tabs/FedwireInboundServiceMessageListTab';
import { PlatformUnusualActivityReportListTab } from './list_tabs/PlatformUnusualActivityReportListTab';
import { PlatformUnusualActivityReportDetailPage } from './detail_pages/PlatformUnusualActivityReportDetailPage';
import { CardListPage } from './list_pages/CardListPage';
import { CardDisputeListPage } from './list_pages/CardDisputeListPage';
import { CompletedTransactionListPage } from './list_tabs/CompletedTransactionListTab';
import { AccountNumberListPage } from './list_pages/AccountNumberListPage';
import { AccountNumberDetailPage } from './detail_pages/AccountNumberDetailPage';
import { AccountListPage } from './list_pages/AccountListPage';
import { AccountDetailPage } from './detail_pages/AccountDetailPage';
import { BankProgramDetailPage } from './detail_pages/BankProgramDetailPage';
import { BankProgramListPage } from './list_pages/BankProgramListPage';
import { EntityListPage } from './list_pages/EntityListPage';
import { CustomerIdentificationProgramTestingBatchListPage } from './list_pages/CustomerIdentificationProgramTestingBatchListPage';
import { CustomerIdentificationProgramTestingEntryListPage } from './list_pages/CustomerIdentificationProgramTestingEntryListPage';
import { CustomerIdentificationProgramTestingEntryDetailPage } from './detail_pages/CustomerIdentificationProgramTestingEntryDetailPage';
import { RoleListPage } from './list_pages/RoleListPage';
import { GroupDetailPage } from './detail_pages/GroupDetailPage';
import { BookkeepingAccountListPage } from './list_pages/BookkeepingAccountListPage';
import { PlatformComplaintDetailPage } from './detail_pages/PlatformComplaintDetailPage';
import { UnusualActivityReportsWrapperPage } from './wrapper_pages/UnusualActivityReportsWrapperPage';
import { ComplaintsWrapperPage } from './wrapper_pages/ComplaintsWrapperPage';
import { ResultListPage } from './list_pages/ResultListPage';
import { ResultDetailPage } from './detail_pages/ResultDetailPage';
import { ProgramListPage } from './list_pages/ProgramListPage';
import { DocumentRequestListPage } from './list_pages/DocumentRequestListPage';
import { ProgramDetailPage } from './detail_pages/ProgramDetailPage';
import { PendingTransactionListPage } from './list_tabs/PendingTransactionListTab';
import { TransactionsWrapperPage } from './wrapper_pages/TransactionsWrapperPage';
import { CashReconciliation } from './cash-reconciliation';
import { ControlConfigurationsListPage } from './list_pages/ControlConfigurationsListPage';
import { ControlConfigurationDetailPage } from './detail_pages/ControlConfigurationDetailPage';
import { RampBusinessAccountOnboardingSurveyDetailPage } from './detail_pages/RampBusinessAccountOnboardingSurveyDetailPage';
import { ControlRecordDetailPage } from './detail_pages/ControlRecordDetailPage';
import { ControlRecordListPage } from './list_pages/ControlRecordListPage';
import { RampBusinessAccountOnboardingSurveyListPage } from './list_pages/RampBusinessAccountOnboardingSurveyListPage';
import { UserListPage } from './list_pages/UserListPage';
import { UserDetailPage } from './detail_pages/UserDetailPage';
import { PlatformComplaintListSubmissionListPage } from './list_pages/PlatformComplaintListSubmissionListPage';
import { PlatformComplianceMetricsSubmissionListPage } from './list_pages/PlatformComplianceMetricsSubmissionListPage';
import { PlatformVendorListSubmissionListPage } from './list_pages/PlatformVendorListSubmissionListPage';
import { CustomerIdentificationProgramTestingBatchDetailPage } from './detail_pages/CustomerIdentificationProgramTestingBatchDetailPage';
import { ROUTES, buildPath } from 'src/lib/routes';
import { InboundMailItemList } from './inbound-mail-item-list';
import { BankFeeStatementDetailPage } from './detail_pages/BankFeeStatementDetailPage';
import { ExternalAccountListPage } from './list_pages/ExternalAccountListPage';
import { ExternalAccountDetailPage } from './detail_pages/ExternalAccountDetailPage';
import { EntityClusterListPage } from './list_pages/EntityClusterListPage';
import { EntityClusterDetailPage } from './detail_pages/EntityClusterDetailPage';
import { OAuthApplicationDetailPage } from './detail_pages/OAuthApplicationDetailPage';
import { OAuthApplicationListPage } from './list_pages/OAuthApplicationListPage';
import { OAuthConnectionListPage } from './list_pages/OAuthConnectListPage';
import { VendorListPage } from './list_pages/PlatformVendorListPage';
import { CommericalOnboardingSurveyListPage } from './list_pages/CommericalOnboardingSurveyListPage';
import { ConsumerOnboardingSurveyListPage } from './list_pages/ConsumerOnboardingSurveyListPage';
import { ConsumerOnboardingSurveyDetailPage } from './detail_pages/ConsumerOnboardingSurveyDetailPage';
import { CommercialOnboardingSurveyDetailPage } from './detail_pages/CommercialOnboardingSurveyDetailPage';
import { OnboardingSurveysIndexPage } from './onboarding-survey';
import { OperatorManualQueueItems } from './operator-manual-queue-items';
import { ManagementInformationSystemWrapperPage } from './wrapper_pages/ManagementInformationSystemWrapperPage';
import { OverviewPage } from './management-information-system/OverviewPage';
import { CompliancePage } from './management-information-system/CompliancePage';
import { VolumesPage } from './management-information-system/VolumesPage';
import { AccountsPage } from './management-information-system/AccountsPage';
import { SanctionsScreeningTriggerWordsListPage } from './list_pages/SanctionsScreeningTriggerWordsListPage';
import { ConsolidatedScreeningListEntryPage } from './list_pages/ConsolidatedScreeningListEntryPage';
import { DocumentRequestDetailPage } from './detail_pages/DocumentRequestDetailPage';
import { SanctionsScreeningReviewDetailPage } from './queues/SanctionsScreeningReviewDetailPage';
import { SanctionsScreeningReviewsListPage } from './queues/SanctionsScreeningReviewsListPage';
import { DocumentRequestSchedulePage } from './detail_pages/DocumentRequestSchedulePage';
import { PlatformFinancialReportSubmission } from './platform-financial-report-submission';
import { FedwireInboundWrapperPage } from './wrapper_pages/FedwireInboundWrapperPage';
import { FedwireInboundServiceMessageDetailPage } from './detail_pages/FedwireInboundServiceMessageDetailPage';
import { FedwireInboundRequestForReversalDetailPage } from './detail_pages/FedwireInboundRequestForReversalDetailPage';
import { FedwireInboundRequestForReversalListTab } from './list_tabs/FedwireInboundRequestForReversalListTab';
import { TransactionAccountingEntriesForTrace } from './detail_pages/TransactionAccountingEntriesForTrace';
import { EntityAddressVerificationListPage } from './list_pages/EntityAddressVerificationListPage';
import { TaskStatusListPage } from './list_pages/TaskStatusListPage';
import { CheckTransferPayeeNameAnalysis } from './check-transfer-payee-name-analysis';
import { CheckTransferPayeeNamesToProcess } from './check-transfer-payee-names-to-process';
import { BookkeepingEntrySetDetailPage } from './detail_pages/BookeepingEntrySetDetailPage';
import { ReserveAccountOverview } from './reserve-accounts';
import { EntitySovosResponseDetailPage } from './detail_pages/EntitySovosResponseDetailPage';
import { EntityMicroBiltResultDetailPage } from './detail_pages/EntityMicroBiltResultDetailPage';
export const Application = () => {
  return <Routes data-sentry-element="Routes" data-sentry-component="Application" data-sentry-source-file="application.tsx">
      <Route element={<Layout />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
        <Route index element={<Navigate to={buildPath(ROUTES.BANK_PROGRAMS_LIST, {})} replace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OBJECT_VIEWER.path} element={<ObjectViewer />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.UNIT21_OBJECT_VIEWER.path} element={<Unit21Object />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.path} element={<ManagementInformationSystemWrapperPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
          <Route path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW.path} element={<OverviewPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.VOLUME.path} element={<VolumesPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.ACCOUNTS.path} element={<AccountsPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.COMPLIANCE.path} element={<CompliancePage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        </Route>
        <Route path={ROUTES.PARTNERS.path} element={<OperatorListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.SEARCH.path} element={<Search />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RESULTS.path} element={<Results />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RESULT_STATUS.path} element={<ResultDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RESULTS_V2.path} element={<ResultListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TASK_STATUS_LIST.path} element={<TaskStatusListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUPS_LIST.path} element={<Groups />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.USER_SESSIONS_LIST.path} element={<UserSessions />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY.path} element={<Entity />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY_ADDRESS_VERIFICATION.path} element={<EntityAddressVerificationListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY_CLUSTER_DETAIL.path} element={<EntityClusterDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY_SOVOS_RESPONSE.path} element={<EntitySovosResponseDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY_MICROBILT_RESULT.path} element={<EntityMicroBiltResultDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ADDRESS_REVIEWING.path} element={<Navigate to={buildPath(ROUTES.ENTITY_CLUSTERS_LIST, {}, {
        statuses: ['pending_reviewing'],
        categories: ['address_match'],
        count_or_greater: 10
      })} replace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITY_CLUSTERS_LIST.path} element={<EntityClusterListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BENEFICIAL_OWNER.path} element={<EntitySetupBeneficialOwnerSubmissionPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUP_GATES.path} element={<Gates />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.DOCUMENT_REQUESTS_LIST.path} element={<DocumentRequestListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.DOCUMENT_REQUEST_DETAIL.path} element={<DocumentRequestDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.DOCUMENT_REQUEST_SCHEDULE.path} element={<DocumentRequestSchedulePage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUP_FEE_PLANS.path} element={<GroupFeePlansPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUP_PROGRAM_FEE_PLANS.path} element={<ProgramFeePlansPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUP_FEE_STATEMENTS.path} element={<GroupFeeStatementsList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD.path} element={<CardDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD_DECLINE.path} element={<GroupCardDeclineDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD_PAYMENT.path} element={<GroupCardPaymentDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.MANUAL_TASKS_LIST.path} element={<ManualTaskList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.MANUAL_TASK.path} element={<ManualTaskDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.MANUAL_TASK_RUN.path} element={<ManualTaskRunDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD_PROFILE_IMAGES.path} element={<CardProfileImages />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD_PRINTER_CARD_PROFILE_IMAGES.path} element={<PhysicalCardImages />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_DEPOSIT_REVIEW_LIST.path} element={<CheckDepositReviewList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_DEPOSIT_REVIEW.path} element={<CheckDepositReview />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_ITEM_ALLOCATING_LIST.path} element={<CheckItemsToAllocate />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_ITEM_ALLOCATING.path} element={<CheckItemAllocating />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_TRANSFER_PAYEE_NAME_LIST.path} element={<CheckTransferPayeeNamesToProcess />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CHECK_TRANSFER_PAYEE_NAME_ANALYSIS.path} element={<CheckTransferPayeeNameAnalysis />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.IDENTITY_DOCUMENTS_LIST.path} element={<IdentityDocumentList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.IDENTITY_DOCUMENT_REVIEW.path} element={<IdentityDocumentReview />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.INBOUND_MAIL_ITEM_REVIEW_LIST.path} element={<InboundMailItemList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.QUEUES.path} element={<OperatorManualQueueItems />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUP_QUEUE.path} element={<GroupQueueItems />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TRANSACTION_DETAIL.path} element={<TransactionDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TRANSFER_DETAIL.path} element={<TransferDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FLAGS_LIST.path} element={<Flags />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.DECLINED_TRANSACTION_DETAIL.path} element={<DeclinedTransactionDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PENDING_TRANSACTION_DETAIL.path} element={<PendingTransactionDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST.path} element={<PlatformComplaintListSubmissionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL.path} element={<PlatformComplaintListSubmission />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST.path} element={<PlatformVendorListSubmissionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL.path} element={<PlatformVendorListSubmission />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST.path} element={<PlatformComplianceMetricsSubmissionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL.path} element={<PlatformComplianceMetricsSubmission />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL.path} element={<PlatformFinancialReportSubmission />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.path} element={<UnusualActivityReportsWrapperPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
          <Route index element={<Navigate to="platform" replace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM.path} element={<PlatformUnusualActivityReportListTab />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.path} element={<UnusualActivityReports />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        </Route>
        <Route path={ROUTES.UNUSUAL_ACTIVITY_REPORTS_FOR_PLATFORM_DETAIL.path} element={<PlatformUnusualActivityReportDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS.path} element={<DirectUnusualActivityReport />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACH_RETURN_RATES_DETAIL.path} element={<ACHReturnRate />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACH_RETURN_RATES_LIST.path} element={<ACHReturnRates />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BOOKKEEPING_ACCOUNTS_LIST.path} element={<BookkeepingAccountListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL.path} element={<BookkeepingAccountDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BOOKKEEPING_ENTRY_SETS_DETAIL.path} element={<BookkeepingEntrySetDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.LATE_RETURN_REQUESTS_LIST.path} element={<LateReturnRequestListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.LATE_RETURN_REQUESTS_DETAIL.path} element={<LateReturnRequestDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.LATE_RETURN_REQUESTS_CREATE.path} element={<LateReturnRequestCreate />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TRANSFER_LOOKUP.path} element={<TransferLookup />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TRANSFER_REVIEWING.path} element={<TransferReviewing />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.EXTERNAL_ACCOUNTS_LIST.path} element={<ExternalAccountListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.EXTERNAL_ACCOUNTS_DETAILS.path} element={<ExternalAccountDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OUTBOUND_ACH_FILES_LIST.path} element={<OutboundACHFiles />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACH_ORIGINATION_VOLUME_MONITORING.path} element={<ACHOriginationVolumeMonitoring />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION.path} element={<RealTimePaymentsPrefundedPosition />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CASH_RECONCILIATION.path} element={<CashReconciliation />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE.path} element={<TransactionAccountingEntriesForTrace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FEDWIRE_INBOUND.path} element={<FedwireInboundWrapperPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
          <Route index element={<Navigate to={buildPath(ROUTES.FEDWIRE_INBOUND.SERVICE_MESSAGES, {})} replace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.FEDWIRE_INBOUND.SERVICE_MESSAGES.path} element={<FedwireInboundServiceMessageListTab />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.FEDWIRE_INBOUND.REQUEST_FOR_REVERSALS.path} element={<FedwireInboundRequestForReversalListTab />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        </Route>
        <Route path={ROUTES.FEDWIRE_INBOUND_SERVICE_MESSAGES_DETAIL.path} element={<FedwireInboundServiceMessageDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FEDWIRE_INBOUND_REQUEST_FOR_REVERSALS_DETAIL.path} element={<FedwireInboundRequestForReversalDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST.path} element={<SanctionsScreeningReviewsListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL.path} element={<SanctionsScreeningReviewDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.SANCTIONS_SCREENING_TRIGGER_WORDS_LIST.path} element={<SanctionsScreeningTriggerWordsListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.SANCTIONS_SCREENING_ENTRY_DETAILS.path} element={<ConsolidatedScreeningListEntryPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_COMPLIANCE_SUBMISSIONS.path} element={<PlatformComplianceSubmissions />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ROUTING_NUMBERS.path} element={<RoutingNumberList />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OBLIGATION_DOCUMENTS.path} element={<ObligationDocuments />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OBLIGATION_DOCUMENTS_DETAILS.path} element={<ObligationDocumentDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ATTESTATIONS_LIST.path} element={<Attestations />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ATTESTATIONS_DETAIL.path} element={<AttestationDetail />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.COMPLIANCE_DOCUMENTS.path} element={<ComplianceDocuments />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.COMPLAINTS.path} element={<ComplaintsWrapperPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
          <Route index element={<Navigate to={buildPath(ROUTES.COMPLAINTS.PLATFORM_COMPLAINTS, {})} replace />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.COMPLAINTS.PLATFORM_COMPLAINTS.path} element={<PlatformComplaintListTab />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.COMPLAINTS.DIRECT_COMPLAINTS.path} element={<DirectComplaintEntries />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        </Route>
        <Route path={ROUTES.PLATFORM_COMPLAINTS_DETAIL.path} element={<PlatformComplaintDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PLATFORM_VENDORS.path} element={<VendorListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARDS_LIST.path} element={<CardListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CARD_DISPUTES_LIST.path} element={<CardDisputeListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route element={<TransactionsWrapperPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx">
          <Route path={ROUTES.TRANSACTIONS_LIST.path} element={<CompletedTransactionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
          <Route path={ROUTES.PENDING_TRANSACTIONS_LIST.path} element={<PendingTransactionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        </Route>
        <Route path={ROUTES.ACCOUNT_NUMBERS_LIST.path} element={<AccountNumberListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACCOUNT_NUMBERS_DETAILS.path} element={<AccountNumberDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACCOUNTS_LIST.path} element={<AccountListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ACCOUNTS_DETAIL.path} element={<AccountDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PROGRAMS_LIST.path} element={<ProgramListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.PROGRAMS_DETAIL.path} element={<ProgramDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BANK_PROGRAMS_LIST.path} element={<BankProgramListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BANK_PROGRAMS_DETAIL.path} element={<BankProgramDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.BANK_FEE_STATEMENTS_DETAIL.path} element={<BankFeeStatementDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ONBOARDING_SURVEYS_INDEX.path} element={<OnboardingSurveysIndexPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST.path} element={<RampBusinessAccountOnboardingSurveyListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_DETAILS.path} element={<RampBusinessAccountOnboardingSurveyDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST.path} element={<ConsumerOnboardingSurveyListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONSUMER_ONBOARDING_SURVEYS_DETAILS.path} element={<ConsumerOnboardingSurveyDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST.path} element={<CommericalOnboardingSurveyListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_DETAILS.path} element={<CommercialOnboardingSurveyDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONTROLS_LIST.path} element={<ControlConfigurationsListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONTROLS_DETAIL.path} element={<ControlConfigurationDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONTROL_RECORDS_LIST.path} element={<ControlRecordListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CONTROL_RECORDS_DETAILS.path} element={<ControlRecordDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_LIST.path} element={<CustomerIdentificationProgramTestingBatchListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS.path} element={<CustomerIdentificationProgramTestingBatchDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST.path} element={<CustomerIdentificationProgramTestingEntryListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS.path} element={<CustomerIdentificationProgramTestingEntryDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ENTITIES_LIST.path} element={<EntityListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.ROLES_LIST.path} element={<RoleListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.USERS_LIST.path} element={<UserListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.USERS_DETAIL.path} element={<UserDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.GROUPS_DETAIL.path} element={<GroupDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FEDERAL_RESERVE_LIMITS_LIST.path} element={<AllFederalReserveLimits />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS.path} element={<FederalReserveLimits />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS.path} element={<FederalReserveLimits />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OAUTH_APPLICATION_DETAIL.path} element={<OAuthApplicationDetailPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OAUTH_APPLICATION_LIST.path} element={<OAuthApplicationListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.OAUTH_CONNECTION_LIST.path} element={<OAuthConnectionListPage />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path={ROUTES.RESERVE_ACCOUNT_OVERVIEW.path} element={<ReserveAccountOverview />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
        <Route path="*" element={<NotFound />} data-sentry-element="Route" data-sentry-source-file="application.tsx" />
      </Route>
    </Routes>;
};