import { ManagementInformationSystemBalancesListResponseData, ManagementInformationSystemRevenuesListResponseData, ManagementInformationSystemRevenuesListResponseDataRevenuesItem, ManagementInformationSystemVolumesListPeriod, ManagementInformationSystemVolumesListResponseData } from 'src/build/operations';
import { Column, Table } from '../deprecated/Table';
import { PercentChange } from './PercentChange';
import { ProgramSummaryChart } from './program-summary-chart';
import { formatAmount } from '@increase/shared/lib/formatting';
import { CombinedDataRow, transformData, VolumeTableDataRow } from './helpers';
type Props = {
  balances: ManagementInformationSystemBalancesListResponseData;
  volumes: ManagementInformationSystemVolumesListResponseData;
  revenues: ManagementInformationSystemRevenuesListResponseData;
  renderRevenue: boolean;
  renderChart: boolean;
  currentPeriodDate: string;
  previousPeriodDate: string;
  period: ManagementInformationSystemVolumesListPeriod;
};
type RevenueTableDataRow = ManagementInformationSystemRevenuesListResponseDataRevenuesItem & {
  id: string;
};
export const ProgramSummary = ({
  balances,
  volumes,
  revenues,
  renderRevenue,
  renderChart = false,
  currentPeriodDate,
  previousPeriodDate,
  period
}: Props) => {
  const revenueMap = new Map<string, RevenueTableDataRow>(transformData(revenues.revenues, 'revenue'));
  const volumeMap = new Map<string, VolumeTableDataRow>(transformData(volumes.volumes, 'volume'));
  const tableData: CombinedDataRow[] = balances.balances.map(balance => {
    const revenueData = revenueMap.get(balance.program_id) || {
      revenue: 0,
      previous_revenue: 0
    };
    const volumeData = volumeMap.get(balance.program_id) || {
      total_volume: 0,
      lookback_total_volume: 0
    };
    return {
      ...balance,
      ...volumeData,
      ...revenueData,
      id: `${balance.program_id}-combined`
    };
  });
  tableData.push({
    id: 'total-combined',
    program_id: 'total',
    partner_name: 'Total',
    usd_balance: balances.totals.usd_balance,
    average_usd_balance: balances.totals.average_usd_balance,
    lookback_usd_balance: balances.totals.lookback_usd_balance,
    total_volume: volumes.totals.volume,
    lookback_total_volume: volumes.totals.lookback_volume,
    revenue: revenues.totals.revenue,
    previous_revenue: revenues.totals.previous_revenue
  });
  const columns: Column<CombinedDataRow>[] = [{
    key: 'partner_name',
    label: 'Name'
  }, {
    key: 'average_usd_balance',
    label: `Average Balance (${period})`,
    render: (record: CombinedDataRow) => formatAmount(record.average_usd_balance, 'USD'),
    align: 'right'
  }, {
    key: 'usd_balance',
    label: 'Total Balance',
    render: (record: CombinedDataRow) => formatAmount(record.usd_balance, 'USD'),
    align: 'right'
  }, {
    key: 'balance_percent_change',
    label: `Balance Change ${period}`,
    render: (record: CombinedDataRow) => <PercentChange previous={record.lookback_usd_balance} current={record.usd_balance} />,
    align: 'right'
  }, {
    key: 'total_volume',
    label: `Total Volume (${period})`,
    render: (record: CombinedDataRow) => formatAmount(record.total_volume, 'USD'),
    align: 'right'
  }, {
    key: 'volume_percent_change',
    label: `Volume Change ${period}`,
    render: (record: CombinedDataRow) => <PercentChange previous={record.lookback_total_volume} current={record.total_volume} />,
    align: 'right'
  }];
  return <div data-sentry-component="ProgramSummary" data-sentry-source-file="program-summary.tsx">
      {renderChart ? <ProgramSummaryChart currentPeriodDate={currentPeriodDate} previousPeriodDate={previousPeriodDate} data={tableData} /> : null}
      <Table data={tableData} columns={renderRevenue ? [...columns, {
      key: 'total_revenue',
      label: `Total Revenue (${period})`,
      render: (record: CombinedDataRow) => formatAmount(record.revenue, 'USD'),
      align: 'right'
    }, {
      key: 'revenue_percent_change',
      label: `Revenue Change ${period}`,
      render: (record: CombinedDataRow) => <PercentChange previous={record.previous_revenue} current={record.revenue} />,
      align: 'right'
    }] : columns} boldLastRow data-sentry-element="Table" data-sentry-source-file="program-summary.tsx" />
    </div>;
};