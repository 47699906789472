import Head from 'next/head';
import { Root } from 'src/components/root';
export default function Home() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const favicon = isDevelopment ? 'favicon-dev' : 'favicon';
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>Administration</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <link rel="icon" href={`/${favicon}.ico`} sizes="any" />
      </Head>
      <Root data-sentry-element="Root" data-sentry-source-file="index.tsx" />
    </>;
}