import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatYearMonth } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { CardListResponseDataItem, CardListResponseDataItemStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<CardListResponseDataItem> & {
  showGroup: boolean;
  showAccount: boolean;
};
const readableStatus = (status: CardListResponseDataItemStatus): string => ({
  active: 'Active',
  disabled: 'Disabled',
  canceled: 'Canceled'
})[status];
export const CardsTable = (props: Props) => {
  const {
    getRowProps,
    showAccount,
    showGroup,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<CardListResponseDataItem>>(() => {
    return [showGroup && {
      header: 'Group',
      contents: card => ({
        text: card.group_id,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: card.group_id
        })
      })
    }, showAccount && {
      header: 'Account',
      contents: card => ({
        text: card.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: card.account_id
        })
      })
    }, {
      header: 'Description',
      iconType: 'card',
      expand: 1,
      contents: card => ({
        text: card.description ?? 'Unnamed Card',
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Status',
      contents: card => ({
        text: readableStatus(card.status),
        badgeColor: card.status === 'active' ? 'green' : card.status === 'canceled' ? 'red' : 'gray'
      })
    }, {
      header: 'Expiration',
      contents: card => ({
        text: formatYearMonth({
          month: card.expiration_month,
          year: card.expiration_year
        })
      })
    }, {
      header: 'Last 4',
      align: 'right',
      contents: card => ({
        text: `•••• ${card.last4}`,
        textColor: 'secondary',
        textWeight: 'normal'
      })
    }];
  }, [showAccount, showGroup]);
  const defaultRowProps = (datum: CardListResponseDataItem) => ({
    href: buildPath(ROUTES.CARD, {
      cardID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<CardListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="CardsTable" data-sentry-source-file="CardsTable.tsx" />;
};