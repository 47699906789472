import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { ProgramGetResponse } from 'src/build/operations';
import { useProgramLimitsPatch } from 'src/hooks/reactQuery';
type ProgramTransactionLimitsPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const ProgramCardTransactionLimits = (props: ProgramTransactionLimitsPropertyListProps) => {
  const {
    program,
    ...rest
  } = props;
  const programLimitsPatch = useProgramLimitsPatch();
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'Authentication Attempted (3DS) Card Limit Rolling 24-Hour Volume Limit',
    value: program.usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit,
    currency: 'USD',
    onEdit: async (newValue: number) => {
      return programLimitsPatch.mutateAsync([program.id, {
        authentication_attempted_card_limit_rolling_24_hour_volume: newValue
      }]);
    }
  }, {
    label: 'Use Default 24-Hour Card Authorization Limit?',
    value: program.limit_rolling_24_hour_card_authorization_volume,
    onToggle: async (newValue: boolean) => {
      return programLimitsPatch.mutateAsync([program.id, {
        limit_rolling_24_hour_card_authorization_volume: newValue
      }]);
    }
  }])} data-sentry-element="PropertyList" data-sentry-component="ProgramCardTransactionLimits" data-sentry-source-file="ProgramCardTransactionLimits.tsx" />;
};