import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useControlRecordListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { ControlRecordGetResponseCategory, ControlRecordGetResponseResult } from 'src/build/operations';
import { ControlRecordsTable } from '../tables/ControlRecordsTable';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
export const ControlRecordListPage = () => {
  const [categories, setCategories] = useSearchParamsState(ROUTES.CONTROL_RECORDS_LIST, 'categories');
  const [results, setResults] = useSearchParamsState(ROUTES.CONTROL_RECORDS_LIST, 'results');
  const [controlConfigurationIds] = useSearchParamsState(ROUTES.CONTROL_RECORDS_LIST, 'control_configuration_ids');
  const [recordIds] = useSearchParamsState(ROUTES.CONTROL_RECORDS_LIST, 'record_ids');
  const filters: TableFilter[] = useMemo(() => compact([makeEnumFilter(results, setResults, 'Result', ControlRecordGetResponseResult, 'star'), makeEnumFilter(categories, setCategories, 'Category', ControlRecordGetResponseCategory, 'star')]), [results, setResults, categories, setCategories]);
  const listResult = useControlRecordListInfinite({
    results: results.length > 0 ? results : undefined,
    categories: categories.length > 0 ? categories : undefined,
    control_configuration_ids: controlConfigurationIds.length > 0 ? controlConfigurationIds : undefined,
    record_ids: recordIds.length > 0 ? recordIds : undefined
  });
  const uneditableFilters = controlConfigurationIds.concat(recordIds);
  const title = uneditableFilters.length > 0 ? `Control records • ${uneditableFilters.join(', ')}` : 'Control records';
  return <PageLayout headline={title} data-sentry-element="PageLayout" data-sentry-component="ControlRecordListPage" data-sentry-source-file="ControlRecordListPage.tsx">
      <TableStateWrapper table={ControlRecordsTable} showCategory={controlConfigurationIds.length !== 1} showObject={recordIds.length !== 1} style="primary" {...listResult} emptyTitle="No control records" emptySubtitle="There are no control records to display. Change your filters" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="ControlRecordListPage.tsx" />
    </PageLayout>;
};