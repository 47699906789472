import { ManagementInformationSystemAccountsListResponseData, ManagementInformationSystemAccountsListResponseDataAccountSummariesItem } from 'src/build/operations';
import { Table } from '../deprecated/Table';
import { formatInteger } from 'src/lib/format-string';
type AccountSummaryTableDataRow = ManagementInformationSystemAccountsListResponseDataAccountSummariesItem & {
  id: string;
};
type Props = {
  accountSummaries: ManagementInformationSystemAccountsListResponseData;
};
export const ProgramAccountSummary = ({
  accountSummaries
}: Props) => {
  const tableData: AccountSummaryTableDataRow[] = accountSummaries.account_summaries.map((summary, index) => ({
    ...summary,
    id: `${summary.program_id}-${index}-account-summary`
  }));
  tableData.push({
    id: 'total-account-summaries',
    program_id: 'total',
    partner_name: 'Total',
    count_accounts: accountSummaries.totals.count_accounts,
    count_open_accounts: accountSummaries.totals.count_open_accounts
  });
  return <div data-sentry-component="ProgramAccountSummary" data-sentry-source-file="program-account-summary.tsx">
      <Table data={tableData} columns={[{
      key: 'partner_name',
      label: 'Name'
    }, {
      key: 'count_accounts',
      label: 'Accounts',
      render: (record: AccountSummaryTableDataRow) => formatInteger(record.count_accounts)
    }, {
      key: 'count_open_accounts',
      label: 'Open Accounts',
      render: (record: AccountSummaryTableDataRow) => formatInteger(record.count_open_accounts)
    }]} boldLastRow data-sentry-element="Table" data-sentry-source-file="program-account-summary.tsx" />
    </div>;
};