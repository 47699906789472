import React, { useEffect } from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { ACHOriginationVolumeMonitoringGetBank } from 'src/build/operations';
import moment from 'moment';
import { useACHOriginationVolumeMonitoringGet, useInitialStateGet, useOperatorGet } from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';
import classNames from 'classnames';
import { formatAmount } from '@increase/shared/lib/formatting';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { Select, SelectSection } from '@increase/shared/components/Select';
const DEFAULT_BANK = ACHOriginationVolumeMonitoringGetBank.first_internet_bank;
const DAYS_TO_SHOW = 30;
const datesToShow = () => {
  const dates = [];
  for (let i = 0; i < DAYS_TO_SHOW; i++) {
    dates.push(moment.utc(new Date()).subtract(i, 'days').format('YYYY-MM-DD'));
  }
  return dates;
};
const VolumeAmount = ({
  amount,
  limit,
  warnThreshold
}: {
  amount: number;
  limit?: number;
  warnThreshold: number;
}) => {
  const exceed = limit && amount > limit;
  const warn = limit && amount > limit * warnThreshold && !exceed;
  return <span className={classNames(exceed && 'bg-danger text-danger', warn && 'bg-warning text-warning')} data-sentry-component="VolumeAmount" data-sentry-source-file="ach-origination-volume-monitoring.tsx">
      {formatAmount(amount, 'USD')}
    </span>;
};
const TableForDate = ({
  processingDate,
  bank,
  warnThreshold
}: {
  processingDate: string;
  bank: ACHOriginationVolumeMonitoringGetBank;
  warnThreshold: number;
}) => {
  const {
    data
  } = useACHOriginationVolumeMonitoringGet({
    bank: bank,
    processing_date: processingDate
  });
  if (!data) {
    return <div>Loading...</div>;
  }
  if (!data.is_effective_date) {
    return null;
  }
  return <div data-sentry-component="TableForDate" data-sentry-source-file="ach-origination-volume-monitoring.tsx">
      <b>{data.included_processing_dates.map(d => d.date).join(', ')}</b>
      <div>
        {data && <Table data={data.volume_by_company_id.map(row => ({
        ...row,
        id: row.company_id
      }))} columns={[{
        key: 'company_id',
        label: 'Company ID'
      }, {
        key: 'group',
        label: 'Group',
        render: row => <div>
                    {row.group && <span>
                        {row.group.id} ({row.group.friendly_nickname})
                      </span>}
                    {row.shared_company_id && <em>shared company ID</em>}
                  </div>
      }, {
        key: 'total_debit_amount',
        label: 'Debits',
        render: row => <VolumeAmount amount={row.volume.total_debit_amount} limit={row.limit?.total_debit_amount} warnThreshold={warnThreshold} />
      }, {
        key: 'total_credit_amount',
        label: 'Credits',
        render: row => <VolumeAmount amount={row.volume.total_credit_amount} limit={row.limit?.total_credit_amount} warnThreshold={warnThreshold} />
      }, {
        key: 'total_debit_limit',
        label: 'Debit Limit',
        render: row => row.limit ? formatAmount(row.limit.total_debit_amount, 'USD') : <em>none</em>
      }, {
        key: 'total_credit_limit',
        label: 'Credit Limit',
        render: row => row.limit ? formatAmount(row.limit.total_credit_amount, 'USD') : <em>none</em>
      }]} />}
        {!data && <div>Loading...</div>}
      </div>
    </div>;
};
export const ACHOriginationVolumeMonitoring = () => {
  const {
    data: initialState
  } = useInitialStateGet({});
  const [bank, setBank] = useSearchParamsState(ROUTES.ACH_ORIGINATION_VOLUME_MONITORING, 'bank');
  const {
    data: operator
  } = useOperatorGet({});
  useEffect(() => {
    if (!bank && operator) {
      setBank(operator.bank || DEFAULT_BANK);
    }
  }, [bank, setBank, operator]);
  if (!initialState || !bank || !operator) {
    return <div>Loading</div>;
  }
  const showBankPicker = !operator.bank;
  const warnThreshold = parseFloat(initialState.constants.ach_origination_volume_warn_threshold);
  const sections: SelectSection<ACHOriginationVolumeMonitoringGetBank>[] = [{
    options: Object.values(ACHOriginationVolumeMonitoringGetBank).map(b => ({
      label: b,
      value: b
    }))
  }];
  return <PageLayout headline="ACH Origination Volume" data-sentry-element="PageLayout" data-sentry-component="ACHOriginationVolumeMonitoring" data-sentry-source-file="ach-origination-volume-monitoring.tsx">
      <>
        <div>
          <h1>ACH Origination by Company ID</h1>
          <div>
            <span className="bg-warning text-warning">Yellow</span> is &gt;{' '}
            {Math.round(100 * warnThreshold)}% of limit,{' '}
            <span className="bg-danger text-danger">Red</span> is over it.
          </div>
          <div>
            To match the Risk Origination Monitoring Service, volume is
            aggregated from the start of one effective date until the start of
            the next one.
          </div>
          <div className="mt-2">
            <b>Bank:</b>
            {showBankPicker ? <Select value={bank} onUpdate={value => {
            setBank(value);
          }} sections={sections} /> : ' ' + bank}
          </div>
        </div>

        {datesToShow().map(d => <TableForDate key={d + '-' + bank} processingDate={d} bank={bank} warnThreshold={warnThreshold} />)}
      </>
    </PageLayout>;
};