import { usePlatformComplaintGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { PlatformComplaintPropertyList } from '../property-lists/PlatformComplaintPropertyList';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const PlatformComplaintDetailPage = () => {
  const {
    platformComplaintId
  } = useTypedParams(ROUTES.PLATFORM_COMPLAINTS_DETAIL);
  const {
    data: platformComplaint
  } = usePlatformComplaintGet(platformComplaintId);
  if (!platformComplaint) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline={'Platform Complaint'} data-sentry-element="PageLayout" data-sentry-component="PlatformComplaintDetailPage" data-sentry-source-file="PlatformComplaintDetailPage.tsx">
      <PlatformComplaintPropertyList platformComplaint={platformComplaint} data-sentry-element="PlatformComplaintPropertyList" data-sentry-source-file="PlatformComplaintDetailPage.tsx" />
      <OperatorNotes modelId={platformComplaint.id} data-sentry-element="OperatorNotes" data-sentry-source-file="PlatformComplaintDetailPage.tsx" />
    </PageLayout>;
};