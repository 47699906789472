import React from 'react';
import { Table } from './deprecated/Table';
import { useUnusualActivityReportsList } from 'src/hooks/reactQuery';
import { InternalOperatorOnly } from './internal-operator-only';
import { Section } from './deprecated/Section';
import { UnusualActivityReportListResponseDataItem } from 'src/build/operations';
import { StyledLink } from './deprecated/StyledLink';
import { buildPath, ROUTES } from 'src/lib/routes';
const renderUnusualActivityReports = (data: UnusualActivityReportListResponseDataItem[]) => {
  return <Table data={data} columns={[{
    key: 'group_id',
    label: 'Group',
    render: record => {
      return <div className="flex justify-start gap-x-1">
                <StyledLink to={buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: record.group_id
        })}>
                  {record.group_id}
                </StyledLink>
              </div>;
    }
  }, {
    key: 'id',
    label: 'id',
    render: record => {
      return <div className="flex justify-start gap-x-1">
                <StyledLink to={buildPath(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS, {
          unusualActivityReportId: record.id
        })}>
                  {record.id}
                </StyledLink>
              </div>;
    }
  }, {
    key: 'reference_number',
    label: 'Reference Number'
  }, {
    key: 'referral_channel',
    label: 'Referral Channel'
  }, {
    key: 'determined_on',
    label: 'Determined On'
  }, {
    key: 'submitted_on',
    label: 'Submitted On'
  }, {
    key: 'locked_on',
    label: 'Locked On'
  }, {
    key: 'status',
    label: 'Status'
  }]} href={record => buildPath(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS, {
    unusualActivityReportId: record.id
  })} data-sentry-element="Table" data-sentry-component="renderUnusualActivityReports" data-sentry-source-file="unusual-activity-reports.tsx" />;
};
export const UnusualActivityReports = () => {
  const {
    data
  } = useUnusualActivityReportsList({});
  if (!data) {
    return <h3>Loading</h3>;
  }
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="UnusualActivityReports" data-sentry-source-file="unusual-activity-reports.tsx">
      <Section data-sentry-element="Section" data-sentry-source-file="unusual-activity-reports.tsx">{renderUnusualActivityReports(data.data)}</Section>
    </InternalOperatorOnly>;
};