import classNames from 'classnames';
import { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import { Icon } from './Icon';
import { OptGroupWorkaround } from './OptGroupWorkaround';
import { Body, Caption } from './Text';
export type SelectSection<V extends string | null> = {
  label?: string;
  options: readonly {
    label: string;
    value: V;
    disabled?: boolean;
    sublabel?: string;
  }[];
};
export type SelectProps<V extends string | null> = {
  sections: SelectSection<V>[];
  hasError?: boolean;
  onUpdate?: (v: V) => void;
  hasPlaceholder?: boolean;
  placeholder?: string;
} & JSX.IntrinsicElements['select'] & {
  value?: V;
};
const GRID_ALIGNMENT = 'col-start-1 col-end-1 row-start-1 row-end-1';
const Select = <V extends string | null,>({
  sections,
  onFocus,
  onBlur,
  hasError,
  onUpdate,
  onChange,
  disabled,
  hasPlaceholder,
  placeholder,
  value: selectedValue,
  ...rest
}: SelectProps<V>) => {
  const [isFocused, setIsFocused] = useState(false);
  const selected = sections.flatMap(s => s.options).find(o => o.value === selectedValue);
  const onFocusOverride: FocusEventHandler<HTMLSelectElement> = e => {
    setIsFocused(true);
    onFocus?.(e);
  };
  const onBlurOverride: FocusEventHandler<HTMLSelectElement> = e => {
    setIsFocused(false);
    onBlur?.(e);
  };
  const onChangeOverride: ChangeEventHandler<HTMLSelectElement> = e => {
    onUpdate?.(e.target.value as V);
    onChange?.(e);
  };
  return <div className={classNames('input grid h-[32px] grid-cols-1 grid-rows-1 whitespace-nowrap', isFocused && 'z-10 outline-none ring-[3px]', isFocused && hasError ? 'input-focus-error z-10' : isFocused && !hasError ? 'input-focus' : '', hasError && 'input-error', disabled ? 'input-disabled' : '', rest.className)} data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      <div className={classNames(GRID_ALIGNMENT, 'flex flex-row items-center gap-2 px-3')}>
        <div className={'flex w-full min-w-0 flex-row items-center justify-between'}>
          <Body color={disabled || hasPlaceholder || selected?.label === '' ? 'disabled' : 'primary'} weight="medium" className={classNames('overflow-hidden text-ellipsis')} contents={selected?.label || placeholder || 'Select...'} data-sentry-element="Body" data-sentry-source-file="Select.tsx" />
          {selected?.sublabel && <Caption color="secondary" contents={selected.sublabel} />}
        </div>

        <Icon name="select" className="stroke-subtle" data-sentry-element="Icon" data-sentry-source-file="Select.tsx" />
      </div>
      <select value={selectedValue ?? ''} {...rest} className={classNames('focus:outline-none focus:ring-0', GRID_ALIGNMENT, 'text-sm opacity-0', disabled ? 'cursor-default' : 'cursor-pointer')} onFocus={onFocusOverride} onBlur={onBlurOverride} onChange={onChangeOverride} disabled={disabled}>
        {sections.map(({
        label: sectionLabel,
        options
      }) => {
        const opts = options.map(({
          value,
          label,
          disabled: sectionDisabled
        }) => <option key={value} value={value ?? undefined} disabled={sectionDisabled}>
                {label}
              </option>);
        if (sectionLabel) {
          return <OptGroupWorkaround key={sectionLabel} sectionLabel={sectionLabel} opts={opts} />;
        } else {
          return opts;
        }
      })}
      </select>
    </div>;
};
export { Select };