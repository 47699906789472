import { ImageDataView } from './image-data-view';
import { parseMICRwithOCR } from '../../lib/parseMICRwithOCR';
import { useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import { TextInput } from '../deprecated/TextInput';
export type MICRResult = {
  accountNumber: string;
  routingNumber: string;
  auxiliaryOnUs?: string;
  serialNumber?: string;
};
type Props = {
  micrPixels: ImageData;
  micrResult: MICRResult | null;
  onUpdate: (result: MICRResult) => void;
};
export const TranscribeMICR = ({
  micrPixels,
  micrResult,
  onUpdate
}: Props) => {
  const [auxiliaryOnUs, setAuxiliaryOnUs] = useState(micrResult?.auxiliaryOnUs ?? '');
  const [routingNumber, setRoutingNumber] = useState(micrResult?.routingNumber ?? '');
  const [accountNumber, setAccountNumber] = useState(micrResult?.accountNumber ?? '');
  const [serialNumber, setSerialNumber] = useState(micrResult?.serialNumber ?? '');
  const [ocrText, setOCRText] = useState<string | null>(null);
  const [ocrConfidence, setOCRConfidence] = useState<number | null>(null);
  useEffect(() => {
    parseMICRwithOCR(micrPixels).then(result => {
      setOCRText(result.text);
      setOCRConfidence(result.confidence);
      if (result.auxiliaryOnUs && auxiliaryOnUs === '') {
        setAuxiliaryOnUs(result.auxiliaryOnUs);
      }
      if (result.routingNumber && routingNumber === '') {
        setRoutingNumber(result.routingNumber);
      }
      if (result.accountNumber && accountNumber === '') {
        setAccountNumber(result.accountNumber);
      }
      if (result.serialNumber && serialNumber === '') {
        setSerialNumber(result.serialNumber);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useKeyPressEvent('Enter', () => {
    onUpdate({
      accountNumber,
      routingNumber,
      auxiliaryOnUs,
      serialNumber
    });
  });
  return <div data-sentry-component="TranscribeMICR" data-sentry-source-file="transcribe-micr.tsx">
      <h3>
        Transcribe MICR line{' '}
        <a href="https://paper.dropbox.com/doc/MICR-line-transcription--ByF3h1hSkFTVpbOVNliucAAWAQ-sPco5C0E67RANo4N9f1gb">
          (instructions)
        </a>
      </h3>
      <ImageDataView pixels={micrPixels} maxWidth="600px" data-sentry-element="ImageDataView" data-sentry-source-file="transcribe-micr.tsx" />
      <div className="flex items-center space-x-2">
        ⑈
        <TextInput name="auxiliaryOnUs" value={auxiliaryOnUs} onChange={e => setAuxiliaryOnUs(e)} placeholder="Auxiliary on-us" data-sentry-element="TextInput" data-sentry-source-file="transcribe-micr.tsx" />
        {'⑈ ⑆'}
        <TextInput placeholder="Routing number" name="routingNumber" value={routingNumber} onChange={e => setRoutingNumber(e)} data-sentry-element="TextInput" data-sentry-source-file="transcribe-micr.tsx" />
        ⑆
        <TextInput placeholder="Account number" name="accountNumber" value={accountNumber} onChange={e => setAccountNumber(e)} data-sentry-element="TextInput" data-sentry-source-file="transcribe-micr.tsx" />
        ⑈
        <TextInput placeholder="Serial number" name="serialNumber" value={serialNumber} onChange={e => setSerialNumber(e)} data-sentry-element="TextInput" data-sentry-source-file="transcribe-micr.tsx" />
      </div>
      {ocrText && <div className="select-auto">
          Raw OCR Results: {ocrText} ({ocrConfidence}% confidence)
        </div>}
    </div>;
};