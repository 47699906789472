import { ReactNode } from 'react';
import { Body, Subheading } from './Text';
import { Illustration, IllustrationType } from './Illustration';
export type EmptyStateStyle = 'expanded' | 'minimized';
type Props = {
  title: string;
  children: string | ReactNode;
  illustration?: IllustrationType;
  style?: EmptyStateStyle;
};
const EmptyState = ({
  title,
  children,
  illustration = 'searchResult',
  style = 'expanded'
}: Props) => {
  return style === 'minimized' ? <div className="border-main bg-strong flex w-full flex-col items-center gap-2 rounded border px-3 py-1 md:flex-row md:px-0" data-sentry-component="EmptyState" data-sentry-source-file="EmptyState.tsx">
      <Illustration name={illustration} className="scale-75" data-sentry-element="Illustration" data-sentry-source-file="EmptyState.tsx" />
      <div className="mb-5 max-w-[550px] text-center md:mb-0 md:text-start">
        <Body weight="bold" data-sentry-element="Body" data-sentry-source-file="EmptyState.tsx">{title}</Body>
        <Body color="secondary" data-sentry-element="Body" data-sentry-source-file="EmptyState.tsx">{children}</Body>
      </div>
    </div> : <div className="mt-24 flex w-full max-w-[300px] flex-col items-center space-y-6 md:max-w-[350px]" data-sentry-component="EmptyState" data-sentry-source-file="EmptyState.tsx">
      <div>
        <Illustration name={illustration} data-sentry-element="Illustration" data-sentry-source-file="EmptyState.tsx" />
      </div>
      <div className="flex flex-col space-y-2 text-center">
        <Subheading color="primary" size="medium" className="mb-1" data-sentry-element="Subheading" data-sentry-source-file="EmptyState.tsx">
          {title}
        </Subheading>
        <Body color="secondary" data-sentry-element="Body" data-sentry-source-file="EmptyState.tsx">{children}</Body>
      </div>
    </div>;
};
export { EmptyState };