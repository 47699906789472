import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useCommercialOnboardingSurveyListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeEntityFilter, makeProgramFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { CommercialOnboardingSurveyTable } from '../tables/CommercialOnboardingSurveyTable';
export const CommericalOnboardingSurveyListPage = () => {
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST);
  const [programs, setPrograms] = useSearchParamsState(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST, 'program');
  const [entities, setEntities] = useSearchParamsState(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST, 'entity');
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const listResult = useCommercialOnboardingSurveyListInfinite({
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    programs: programs.length > 0 ? programs : undefined,
    entities: entities.length > 0 ? entities : undefined
  });
  const filters: TableFilter[] = useMemo(() => [makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created'), makeProgramFilter(programs, setPrograms), makeEntityFilter(entities, setEntities)], [startAt, endAt, setTimeRange, programs, setPrograms, entities, setEntities]);
  return <PageLayout headline={'Commercial Onboarding Surveys'} data-sentry-element="PageLayout" data-sentry-component="CommericalOnboardingSurveyListPage" data-sentry-source-file="CommericalOnboardingSurveyListPage.tsx">
      <TableStateWrapper table={CommercialOnboardingSurveyTable} style="primary" {...listResult} emptyTitle="No Surveys" emptySubtitle="There are no surveys to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="CommericalOnboardingSurveyListPage.tsx" />
    </PageLayout>;
};