import type { AxiosRequestConfig } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useACHHistoricalOriginationForRoutingNumber, useOperatorGet } from 'src/hooks/reactQuery';
import { useState, useCallback } from 'react';
import { limitsGet, limitsChangeRequestList, limitsEventList, limitsStatisticsGet } from 'src/build/federalreservegateway/federalreservegateway';
import { LimitsChangeRequestListResponseLimitsChangeRequestsItem, LimitsEventListResponseLimitsEventsItem, LimitsGetResponse, LimitsListResponseLimitsItem } from 'src/build/federalreservegateway/federalreservegateway.schemas';
import { useServices } from '../hooks/use-services';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from '@increase/shared/components/Table';
import { Button } from '@increase/shared/components/Button';
import { useFederalReserveGatewayRequestLimitChange, useFederalReserveGatewayConfirmLimitChange, useFederalReserveGatewayCancelLimitChange, FederalReserveGatewayLimitsQueryKey, FederalReserveGatewayRequestLimitsQueryKey, FederalReserveGatewayLimitsEventsQueryKey } from 'src/hooks/reactQuery';
import { DateFormat, formatAmount, formatISO8601Timestamp, formatPercentage } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { Box } from '@increase/shared/components/Box';
import { Body, Caption, Heading, Subheading } from '@increase/shared/components/Text';
import { AlertList } from '@increase/shared/components/AlertList';
import { DrawerLayout } from '@increase/shared/components/DrawerLayout';
import { useDrawer } from '@increase/shared/components/Modal';
import { AmountInput } from '@increase/shared/components/AmountInput';
import { ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber, OperatorGetResponse } from 'src/build/operations';
import { TableColumns } from '@increase/shared/components/Table';
import { getDateFormatParameterForTooltip, getDateFormatParameterForXAxis } from '@increase/shared/components/charts/common';
import _ from 'lodash';
import { CartesianAxis, CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import classNames from 'classnames';
import { Badge } from '@increase/shared/components/Badge';
enum LimitRequestState {
  PENDING,
  CANCELED,
  CONFIRMED,
  EXPIRED,
}
type EventType = 'requested' | 'confirmed' | 'canceled' | 'expired';
type WhichLimit = 'ach_credit_forward_origination' | 'ach_debit_forward_origination' | 'fedwire';
function useAxiosOptions(): AxiosRequestConfig {
  const {
    federalreservegateway: baseURL
  } = useServices();
  return {
    baseURL
  };
}
const useLimitsChangeRequestsList = (limitsId: string, axiosOptions: AxiosRequestConfig) => {
  return useQuery([FederalReserveGatewayRequestLimitsQueryKey, limitsId], () => limitsChangeRequestList(limitsId, axiosOptions).then(result => {
    return result.data;
  }));
};
const useLimitsEventList = (limitsId: string, axiosOptions: AxiosRequestConfig) => {
  return useQuery([FederalReserveGatewayLimitsEventsQueryKey, limitsId], () => limitsEventList(limitsId, axiosOptions).then(result => {
    return result.data;
  }));
};
const useLimitsGet = (limitsId: string, axiosOptions: AxiosRequestConfig) => {
  return useQuery([FederalReserveGatewayLimitsQueryKey, limitsId], () => limitsGet(limitsId, axiosOptions).then(result => {
    return result.data;
  }));
};
const limitsStatisticsQueryKey = 'federalreservegateway/limit-statistics';
const useLimitsStatisticsGet = (limitsId: string, axiosOptions: AxiosRequestConfig) => {
  return useQuery([limitsStatisticsQueryKey, limitsId], () => limitsStatisticsGet(limitsId, axiosOptions).then(result => {
    return result.data;
  }));
};
function limitRequestState(limitRequest: LimitsChangeRequestListResponseLimitsChangeRequestsItem): LimitRequestState {
  if (limitRequest.confirmed_by) {
    return LimitRequestState.CONFIRMED;
  }
  if (limitRequest.canceled_by) {
    return LimitRequestState.CANCELED;
  }
  if (new Date() > new Date(limitRequest.expires_at)) {
    return LimitRequestState.EXPIRED;
  }
  return LimitRequestState.PENDING;
}
const canUpdateLimits = (operator: OperatorGetResponse) => {
  return operator.role === 'limit_approver';
};
const canConfirmRequest = (operator: OperatorGetResponse, request: LimitsChangeRequestListResponseLimitsChangeRequestsItem) => {
  return canUpdateLimits(operator) && request.requested_by !== operator.email;
};
const textForWhichLimit = (whichLimit: WhichLimit): string => {
  switch (whichLimit) {
    case 'ach_credit_forward_origination':
      return 'ACH credits';
    case 'ach_debit_forward_origination':
      return 'ACH debits';
    case 'fedwire':
      return 'Wires';
  }
};
const textForEvent = (eventType: EventType, whichLimit: WhichLimit): string => {
  let limitText = null;
  switch (whichLimit) {
    case 'ach_credit_forward_origination':
      limitText = 'ACH credit';
      break;
    case 'ach_debit_forward_origination':
      limitText = 'ACH debit';
      break;
    case 'fedwire':
      limitText = 'Wire';
      break;
  }
  switch (eventType) {
    case 'requested':
      return `${limitText} limit change requested`;
    case 'confirmed':
      return `${limitText} limit updated upon request confirmation`;
    case 'canceled':
      return `${limitText} limit change request canceled`;
    case 'expired':
      return `${limitText} limit change request expired`;
  }
};
const LimitsChangeRequestActions = ({
  limits,
  request,
  refetchLimits
}: {
  limits: LimitsListResponseLimitsItem;
  request: LimitsChangeRequestListResponseLimitsChangeRequestsItem;
  refetchLimits: () => void;
}) => {
  const axiosOptions = useAxiosOptions();
  const {
    data: operator
  } = useOperatorGet({});
  const cancelLimitChange = useFederalReserveGatewayCancelLimitChange();
  const confirmLimitChange = useFederalReserveGatewayConfirmLimitChange();
  const cancelRequest = useCallback(async (limitsId: string, requestId: string) => await cancelLimitChange.mutateAsync([limitsId, requestId, axiosOptions]), [cancelLimitChange, axiosOptions]);
  const confirmRequest = useCallback(async (limitsId: string, requestId: string) => {
    await confirmLimitChange.mutateAsync([limitsId, requestId, axiosOptions]);
    refetchLimits();
  }, [confirmLimitChange, axiosOptions, refetchLimits]);
  const canSubmit = !(cancelLimitChange.isLoading || confirmLimitChange.isLoading);
  if (limitRequestState(request) !== LimitRequestState.PENDING) {
    return null;
  }
  const error = cancelLimitChange.error ?? confirmLimitChange.error;
  return <Box data-sentry-element="Box" data-sentry-component="LimitsChangeRequestActions" data-sentry-source-file="federal-reserve-limits.tsx">
      <Box flex={true} data-sentry-element="Box" data-sentry-source-file="federal-reserve-limits.tsx">
        {operator && canUpdateLimits(operator) && <Button size="small" onClick={() => cancelRequest(limits.id, request.id)} disabled={!canSubmit} text="Cancel" style="warning" />}
        {operator && canConfirmRequest(operator, request) && <Button size="small" disabled={!canSubmit} onClick={() => confirmRequest(limits.id, request.id)} text="Confirm" />}
      </Box>
      {error && <AlertList tasks={[{
      icon: 'info',
      title: 'Error',
      body: error.response?.data.message,
      key: '0',
      style: 'error'
    }]} />}
    </Box>;
};
const LimitsChangeRequestsTable = ({
  limits,
  refetchLimits
}: {
  limits: LimitsListResponseLimitsItem;
  refetchLimits: () => void;
}) => {
  const axiosOptions = useAxiosOptions();
  const {
    data
  } = useLimitsChangeRequestsList(limits.id, axiosOptions);
  const tableColumns: TableColumns<LimitsChangeRequestListResponseLimitsChangeRequestsItem> = [{
    header: 'Limit',
    contents: r => ({
      text: textForWhichLimit(r.which_limit as WhichLimit),
      textWeight: 'medium',
      textColor: 'emphasis'
    }),
    mobileContents: r => ({
      text: textForWhichLimit(r.which_limit as WhichLimit),
      caption: formatAmount(r.amount, 'USD')
    })
  }, {
    header: 'Amount',
    contents: r => ({
      text: formatAmount(r.amount, 'USD'),
      textWeight: 'medium',
      textColor: 'emphasis'
    })
  }, {
    header: 'Requested by',
    contents: r => ({
      text: r.requested_by
    })
  }, {
    header: 'Requested at',
    contents: r => ({
      text: formatISO8601Timestamp(r.created_at, 'month-day-year-hour-minute-second')
    })
  }, {
    header: 'Actions',
    align: 'right',
    CellComponent: ({
      datum
    }) => <LimitsChangeRequestActions refetchLimits={refetchLimits} limits={limits} request={datum} />
  }];
  const pending = data && data.limits_change_requests.filter(r => limitRequestState(r) === LimitRequestState.PENDING);
  if (pending && pending.length === 0) {
    return null;
  }
  return <div data-sentry-component="LimitsChangeRequestsTable" data-sentry-source-file="federal-reserve-limits.tsx">
      {pending && <>
          <Heading className="mb-4" contents="Pending limit change requests" />
          <Table columns={tableColumns} data={pending} />
        </>}
    </div>;
};
const LimitsEventTable = ({
  limits
}: {
  limits: LimitsListResponseLimitsItem;
}) => {
  const axiosOptions = useAxiosOptions();
  const {
    data
  } = useLimitsEventList(limits.id, axiosOptions);
  if (!data) {
    return null;
  }
  const tableColumns: TableColumns<LimitsEventListResponseLimitsEventsItem & {
    id: string;
  }> = [{
    header: 'Event',
    contents: r => ({
      text: textForEvent(r.event_type as EventType, r.which_limit as WhichLimit),
      textWeight: 'medium',
      textColor: 'emphasis'
    }),
    mobileContents: r => ({
      text: textForEvent(r.event_type as EventType, r.which_limit as WhichLimit),
      caption: formatAmount(r.amount, 'USD')
    })
  }, {
    header: 'Amount',
    contents: r => ({
      text: formatAmount(r.amount, 'USD'),
      textWeight: 'medium',
      textColor: 'emphasis'
    })
  }, {
    header: 'Operator',
    contents: r => ({
      text: r.event_operator_email || ''
    })
  }, {
    header: 'Time',
    align: 'right',
    contents: r => ({
      text: formatISO8601Timestamp(r.event_time, 'month-day-year-hour-minute-second')
    }),
    mobileContents: r => ({
      text: r.event_operator_email || '',
      caption: formatISO8601Timestamp(r.event_time, 'month-day-year-hour-minute-second')
    })
  }];
  const dataWithID: (LimitsEventListResponseLimitsEventsItem & {
    id: string;
  })[] = [];
  data.limits_events.forEach(d => {
    dataWithID.push({
      ...d,
      id: `${d.limit_change_request_id}-${d.event_type}`
    });
  });
  return <div data-sentry-component="LimitsEventTable" data-sentry-source-file="federal-reserve-limits.tsx">
      <Heading className="mb-4" contents="Audit log" data-sentry-element="Heading" data-sentry-source-file="federal-reserve-limits.tsx" />
      <Table columns={tableColumns} data={dataWithID} data-sentry-element="Table" data-sentry-source-file="federal-reserve-limits.tsx" />
    </div>;
};
const USDInputWithLabel = ({
  amount,
  setAmount,
  label
}: {
  amount: number | undefined;
  setAmount: (n: number | undefined) => void;
  label: string;
}) => {
  return <Box gap="1" data-sentry-element="Box" data-sentry-component="USDInputWithLabel" data-sentry-source-file="federal-reserve-limits.tsx">
      <Body color="primary" weight="medium" data-sentry-element="Body" data-sentry-source-file="federal-reserve-limits.tsx">
        {label}
      </Body>
      <AmountInput value={amount !== undefined ? amount / 100.0 : undefined} placeholder="$0.00" onValueChange={e => setAmount(e.floatValue !== undefined ? e.floatValue * 100 : undefined)} data-sentry-element="AmountInput" data-sentry-source-file="federal-reserve-limits.tsx" />
    </Box>;
};
type HistoricalDataPoint = {
  volume: number;
  utilization: number;
  breach: boolean;
  timestamp: string;
};
const utilizationPercent = (volume: number, limit: number): number => {
  if (limit === 0) {
    if (volume === 0) {
      return 0;
    } else {
      return Infinity;
    }
  } else {
    return volume / limit * 100;
  }
};
const useStatistics = (limits: LimitsGetResponse): {
  historical: {
    wires: HistoricalDataPoint[];
    ach_debit: HistoricalDataPoint[];
    ach_credit: HistoricalDataPoint[];
  };
  today: {
    wires: HistoricalDataPoint;
    ach_debit: HistoricalDataPoint;
    ach_credit: HistoricalDataPoint;
  };
} | null => {
  const axiosOptions = useAxiosOptions();
  const {
    data: wireStatistics
  } = useLimitsStatisticsGet(limits.id, axiosOptions);
  const {
    data: achStatistics
  } = useACHHistoricalOriginationForRoutingNumber({
    routing_number: limits.routing_number as ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber
  });
  if (!wireStatistics || !achStatistics) {
    return null;
  }
  if (wireStatistics.fedwire_cycle_dates.length === 0) {
    return null;
  }
  const wireDataPoints = wireStatistics.fedwire_cycle_dates.map(e => {
    return {
      date: new Date(e.date),
      volume: e.amount,
      utilization: utilizationPercent(e.amount, limits.fedwire_cycle_date_limit_usd),
      breach: e.amount > limits.fedwire_cycle_date_limit_usd,
      timestamp: e.date
    };
  });
  const achDebitDataPoints = achStatistics.data.map(e => {
    return {
      date: new Date(e.date),
      volume: e.total_debit_amount,
      utilization: utilizationPercent(e.total_debit_amount, limits.ach_debit_forward_origination_processing_date_limit_usd),
      breach: e.total_debit_amount > limits.ach_debit_forward_origination_processing_date_limit_usd,
      timestamp: e.date
    };
  });
  const achCreditDataPoints = achStatistics.data.map(e => {
    return {
      date: new Date(e.date),
      volume: e.total_credit_amount,
      utilization: utilizationPercent(e.total_credit_amount, limits.ach_credit_forward_origination_processing_date_limit_usd),
      breach: e.total_credit_amount > limits.ach_credit_forward_origination_processing_date_limit_usd,
      timestamp: e.date
    };
  });
  return {
    historical: {
      wires: wireDataPoints,
      ach_debit: achDebitDataPoints,
      ach_credit: achCreditDataPoints
    },
    today: {
      wires: _.maxBy(wireDataPoints, e => e.date) as HistoricalDataPoint,
      ach_debit: _.maxBy(achDebitDataPoints, e => e.date) as HistoricalDataPoint,
      ach_credit: _.maxBy(achCreditDataPoints, e => e.date) as HistoricalDataPoint
    }
  };
};
type Colors = {
  color1: string;
  color2: string;
  color3: string;
};
function addCommasToRGB(rgbValue: string): string {
  return rgbValue.split(' ').join(',');
}
const getCSSVariableValue = (variableName: string) => getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
function getChartColors() {
  const color1 = addCommasToRGB(getCSSVariableValue('--color-chart-accent1'));
  const color2 = addCommasToRGB(getCSSVariableValue('--color-chart-accent2'));
  const color3 = addCommasToRGB(getCSSVariableValue('--color-chart-accent3'));
  const colorGrid = addCommasToRGB(getCSSVariableValue('--color-border-strong'));
  const colorLabel = addCommasToRGB(getCSSVariableValue('--color-content-disabled'));
  const colorAxis = addCommasToRGB(getCSSVariableValue('--color-border-main'));
  const colorCursor = addCommasToRGB(getCSSVariableValue('--color-border-strong'));
  const colorDrag = addCommasToRGB(getCSSVariableValue('--color-border-stronger'));
  return {
    color1,
    color2,
    color3,
    colorGrid,
    colorLabel,
    colorAxis,
    colorCursor,
    colorDrag
  };
}
const formatUtilization = (value: number) => {
  if (value === 0) {
    return '0%';
  } else if (value < 1) {
    return '<1%';
  } else if (value > 100) {
    return '>100%';
  } else {
    return formatPercentage(value / 100.0);
  }
};
const formatYTick = (value: number) => value !== 0 ? formatPercentage(value / 100.0) : '';
type ChartEntry = {
  value: number;
  utilization: number;
  volume: number;
  breach: boolean;
};
const CustomTooltip = (props: {
  dateFormat: DateFormat;
  sortedLabels: string[];
  formatLabel: (label: string) => JSX.Element;
  payload?: {
    payload?: Record<string, string | ChartEntry>;
  }[];
}) => {
  const {
    dateFormat,
    sortedLabels
  } = props;
  const values = {
    ...props.payload?.[0]?.payload
  };
  const {
    timestamp,
    ...rest
  } = values;
  if (typeof timestamp !== 'string') {
    return null;
  }
  const colors = getChartColors();
  return <div className="mx-4 space-y-1 rounded border border-main bg-subtle p-3 shadow-md" data-sentry-component="CustomTooltip" data-sentry-source-file="federal-reserve-limits.tsx">
      <Caption color="emphasis" weight="medium" data-sentry-element="Caption" data-sentry-source-file="federal-reserve-limits.tsx">
        {formatISO8601Timestamp(timestamp, dateFormat, 'UTC')}
      </Caption>
      <div>
        {sortedLabels.map((key, index) => {
        const entry = rest[key] as ChartEntry | undefined;
        if (!entry) {
          return null;
        }
        return <div key={key} className="flex items-center justify-between">
              <div className="flex items-center gap-2 pr-4">
                <div className="h-2 w-2 rounded-[2px]" style={{
              backgroundColor: `rgba(${colors[`color${index + 1}` as keyof Colors]}, 1)`
            }} />
                <Caption color="secondary">{props.formatLabel(key)}</Caption>
              </div>
              <Caption color="primary" weight="medium">
                {formatUtilization(entry.utilization)} (
                {formatAmount(entry.volume, 'USD')})
              </Caption>
            </div>;
      })}
      </div>
    </div>;
};
const UtilizationChart = ({
  limits
}: {
  limits: LimitsListResponseLimitsItem;
}) => {
  const stats = useStatistics(limits);
  const entries = stats ? _.sortBy(stats.historical.wires.map(e => {
    return {
      label: 'Wires',
      value: Math.min(e.utilization, 100),
      ...e
    };
  }).concat(stats.historical.ach_debit.map(e => {
    return {
      label: 'ACH debits',
      value: Math.min(e.utilization, 100),
      ...e
    };
  })).concat(stats.historical.ach_credit.map(e => {
    return {
      label: 'ACH credits',
      value: Math.min(e.utilization, 100),
      ...e
    };
  })), 'timestamp') : null;
  const colors = getChartColors();
  const formatLabel = (l: string) => <div data-sentry-component="formatLabel" data-sentry-source-file="federal-reserve-limits.tsx">{l}</div>;
  const allUniqueLabels = ['Wires', 'ACH debits', 'ACH credits'];
  const groupedByTimestamp = entries ? _.groupBy(entries, 'timestamp') : {};
  const dateFormatForXAxis = getDateFormatParameterForXAxis('day');
  const dateFormatForTooltip = getDateFormatParameterForTooltip('day');
  const data = Object.keys(groupedByTimestamp).map(timestamp => {
    const values = groupedByTimestamp[timestamp];
    const entry: Record<string, string | ChartEntry> = {
      timestamp
    };
    for (const value of values) {
      entry[value.label] = value;
    }
    return entry;
  });
  const customTooltip = <Tooltip content={<CustomTooltip sortedLabels={allUniqueLabels} dateFormat={dateFormatForTooltip} formatLabel={formatLabel} />} animationDuration={50} wrapperStyle={{
    outline: 'none'
  }} cursor={{
    stroke: `rgba(${colors['colorCursor']}, 1)`,
    strokeWidth: 1.5
  }} />;
  const styledGrid = <CartesianGrid stroke={`rgba(${colors['colorGrid']}, 1)`} strokeWidth={0.5} strokeDasharray="3 3" />;
  const styledReferenceLine = <ReferenceLine y={100} stroke="red" strokeDasharray="3 3" />;
  const styledReferenceArea = <ReferenceArea y1={75} y2={100} fill="red" fillOpacity={0.03} />;
  const styledXAxis = <XAxis dataKey="timestamp" tickLine={false} stroke={`rgba(${colors['colorAxis']}, 1)`} interval="preserveEnd" minTickGap={25} tick={{
    fontFamily: 'TT Interphases Pro',
    fontSize: 12,
    fill: `rgba(${colors['colorLabel']}, 1)`
  }} padding="no-gap" tickFormatter={timestamp => {
    return formatISO8601Timestamp(timestamp, dateFormatForXAxis, 'UTC');
  }} />;
  const styledYAxis = <YAxis type="number" hide={false} domain={[0, 100]} stroke={`rgba(${colors['colorAxis']}, 1)`} tickCount={5} tickLine={false} axisLine={true} allowDecimals={false} interval={0} tickFormatter={formatYTick} dy={10} dx={-5} tick={{
    fontFamily: 'TT Interphases Pro',
    fontSize: 12,
    fill: `rgba(${colors['colorLabel']}, 1)`
  }} orientation="left" mirror={true} />;
  const [focusedLabel, setFocusedLabel] = useState<string | null>(null);
  const noData = !stats;
  return <div className={classNames('mb-3 transition-all duration-500')} data-sentry-component="UtilizationChart" data-sentry-source-file="federal-reserve-limits.tsx">
      <div className="flex w-full flex-col items-start gap-2 py-2 md:flex-row md:items-center md:justify-between">
        <div className="flex gap-2">
          {noData && <div className="flex w-full cursor-default justify-between rounded-sm px-1 md:w-auto md:max-w-[175px] md:flex-col md:justify-center md:py-1">
              <div className="flex items-center gap-x-1">
                <div className={classNames('h-2 w-2 shrink-0 rounded-[2px] bg-disabled-strong', 'block')} />
                <Caption color="disabled" className="truncate">
                  No data
                </Caption>
              </div>
            </div>}
          {!noData && allUniqueLabels.map((label, index) => <div key={label} className={classNames('flex w-full cursor-pointer justify-between rounded-sm px-1 transition-opacity hover:bg-main-hover md:w-auto md:max-w-[175px] md:flex-col md:justify-start md:py-1', focusedLabel && focusedLabel !== label ? 'opacity-30' : 'opacity-100')} onMouseEnter={() => setFocusedLabel(label)} onMouseLeave={() => setFocusedLabel(null)}>
                <div className="flex items-center gap-x-1">
                  <div className={classNames('h-2 w-2 shrink-0 rounded-[2px]', 'block')} style={{
              backgroundColor: `rgba(${colors[`color${index + 1}` as keyof Colors]}, 1)`
            }} />
                  <Caption color="secondary" className="truncate">
                    {formatLabel(label)}
                  </Caption>
                </div>
              </div>)}
        </div>
      </div>

      {noData && <div className="relative flex h-48 items-center justify-center px-1 pb-8 pt-1">
          <div className="absolute inset-x-1 bottom-8 h-[2px] bg-gradient-to-l from-disabled-strong to-disabled-strong/20"></div>
          <div className="h-full w-full" style={{
        background: `
                conic-gradient(at 3px 0.5px, rgba(${colors['colorGrid']}, 1) 25%,#0000 0)
                calc((39px / 6 - 3px + 0.5px) / 2) 0 /
                calc(39px / 6) 39px,
                conic-gradient(from 180deg at 0.5px 3px, rgba(${colors['colorGrid']}, 1) 25%,#0000 0)
                0 calc((39px / 6 - 3px + 0.5px) / 2) /
                39px calc(39px / 6)
              `
      }}></div>
        </div>}

      {!noData && <div className={classNames('relative h-48 w-full')}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{
          top: 10,
          right: 0,
          bottom: 0,
          left: 0
        }}>
              <defs>
                <linearGradient id="lineColor1" x1="0" y1="0" x2="1" y2="0">
                  <stop offset={'0%'} stopColor={`rgba(${colors['color1']}, 0)`} />
                  <stop offset={'40%'} stopColor={`rgba(${colors['color1']}, 1)`} />
                </linearGradient>
                <linearGradient id="lineColor2" x1="0" y1="0" x2="1" y2="0">
                  <stop offset={'0%'} stopColor={`rgba(${colors['color2']}, 0)`} />
                  <stop offset={'40%'} stopColor={`rgba(${colors['color2']}, 1)`} />
                </linearGradient>
                <linearGradient id="lineColor3" x1="0" y1="0" x2="1" y2="0">
                  <stop offset={'0%'} stopColor={`rgba(${colors['color3']}, 0)`} />
                  <stop offset={'40%'} stopColor={`rgba(${colors['color3']}, 1)`} />
                </linearGradient>
              </defs>
              <CartesianAxis axisLine={true} />
              {styledGrid}
              {styledXAxis}
              {styledYAxis}
              {customTooltip}
              {styledReferenceLine}
              {styledReferenceArea}
              {allUniqueLabels.map((label, index) => <Line key={label} dataKey={entry => entry[label]?.value} strokeWidth={2} stroke={`url(#lineColor${index + 1})`} dot={false} opacity={focusedLabel && focusedLabel !== label ? 0.3 : 1} />)}
            </LineChart>
          </ResponsiveContainer>
        </div>}
    </div>;
};
const Drawer = (props: {
  limitsID: string;
  onClose: () => void;
}) => {
  const axiosOptions = useAxiosOptions();
  const mutate = useFederalReserveGatewayRequestLimitChange();
  const [fedwire, setFedwire] = useState<number | undefined>();
  const [achDebit, setACHDebit] = useState<number | undefined>();
  const [achCredit, setACHCredit] = useState<number | undefined>();
  return <DrawerLayout title="Create limit change request" body={<Box>
          {mutate.error && <AlertList tasks={[{
      icon: 'info',
      title: 'Error',
      body: mutate.error.response?.data.message,
      key: '0',
      style: 'error'
    }]} />}

          <Body color="secondary">
            Create a request to update the daily Federal Reserve origination
            limits. Leave a field blank to leave a particular limit unchanged.
          </Body>

          <USDInputWithLabel amount={fedwire} setAmount={setFedwire} label="Wires" />

          <USDInputWithLabel amount={achDebit} setAmount={setACHDebit} label="ACH debits" />

          <USDInputWithLabel amount={achCredit} setAmount={setACHCredit} label="ACH credits" />
        </Box>} rightButton={<Button icon="upload" text="Submit request" onClick={async () => {
    await mutate.mutateAsync([props.limitsID, {
      fedwire_cycle_date_limit_usd: fedwire,
      ach_debit_forward_origination_processing_date_limit_usd: achDebit,
      ach_credit_forward_origination_processing_date_limit_usd: achCredit
    }, axiosOptions]);
    props.onClose();
  }} style="primary" disabled={mutate.isLoading} />} data-sentry-element="DrawerLayout" data-sentry-component="Drawer" data-sentry-source-file="federal-reserve-limits.tsx" />;
};
const LimitHeader = ({
  name,
  amount,
  amountToday
}: {
  name: string;
  amount: number;
  amountToday: number | null;
}) => {
  return <Box gap={'0'} data-sentry-element="Box" data-sentry-component="LimitHeader" data-sentry-source-file="federal-reserve-limits.tsx">
      <Body color="secondary" data-sentry-element="Body" data-sentry-source-file="federal-reserve-limits.tsx">{name}</Body>
      <Box gap={'2'} flex={true} className="sm:flex-col" data-sentry-element="Box" data-sentry-source-file="federal-reserve-limits.tsx">
        <Subheading size="small" data-sentry-element="Subheading" data-sentry-source-file="federal-reserve-limits.tsx">{formatAmount(amount, 'USD')}</Subheading>
        {amountToday !== null && <Box flex={true}>
            <Badge label={formatAmount(amountToday, 'USD') + ' today'} color="gray" />
          </Box>}
      </Box>
    </Box>;
};
const LimitsHeaders = ({
  limits
}: {
  limits: LimitsGetResponse;
}) => {
  const stats = useStatistics(limits);
  return <Box className="sm:flex-row" gap="6" data-sentry-element="Box" data-sentry-component="LimitsHeaders" data-sentry-source-file="federal-reserve-limits.tsx">
      <LimitHeader name="Wires" amount={limits.fedwire_cycle_date_limit_usd} amountToday={stats && stats.today.wires.volume} data-sentry-element="LimitHeader" data-sentry-source-file="federal-reserve-limits.tsx" />
      <div className="hidden border-l border-main sm:block" />
      <LimitHeader name="ACH debits" amount={limits.ach_debit_forward_origination_processing_date_limit_usd} amountToday={stats && stats.today.ach_debit.volume} data-sentry-element="LimitHeader" data-sentry-source-file="federal-reserve-limits.tsx" />
      <div className="hidden border-l border-main sm:block" />
      <LimitHeader name="ACH credits" amount={limits.ach_credit_forward_origination_processing_date_limit_usd} amountToday={stats && stats.today.ach_credit.volume} data-sentry-element="LimitHeader" data-sentry-source-file="federal-reserve-limits.tsx" />
    </Box>;
};
export const FederalReserveLimits = () => {
  const {
    limitsId
  } = useTypedParams(ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS);
  const axiosOptions = useAxiosOptions();
  const {
    data: limits,
    refetch: refetchLimits
  } = useLimitsGet(limitsId, axiosOptions);
  const {
    data: operator
  } = useOperatorGet({});
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  if (!limits || !operator) {
    return <div>Loading...</div>;
  }
  return <PageLayout id="federal-reserve-limits" headline={'Federal Reserve Origination Limits'} action={operator && canUpdateLimits(operator) && <Button icon="settings" text="Edit limits" style="secondary" onClick={() => showDrawer(<Drawer limitsID={limits.id} onClose={closeDrawer} />)} />} data-sentry-element="PageLayout" data-sentry-component="FederalReserveLimits" data-sentry-source-file="federal-reserve-limits.tsx">
      <Box className="mb-10" gap="2" data-sentry-element="Box" data-sentry-source-file="federal-reserve-limits.tsx">
        <Heading data-sentry-element="Heading" data-sentry-source-file="federal-reserve-limits.tsx">Routing number: {limits.routing_number}</Heading>
        <LimitsHeaders limits={limits} data-sentry-element="LimitsHeaders" data-sentry-source-file="federal-reserve-limits.tsx" />
      </Box>

      <Box gap="10" data-sentry-element="Box" data-sentry-source-file="federal-reserve-limits.tsx">
        <div>
          <Heading contents="Limit utilization" data-sentry-element="Heading" data-sentry-source-file="federal-reserve-limits.tsx" />
          <UtilizationChart limits={limits} data-sentry-element="UtilizationChart" data-sentry-source-file="federal-reserve-limits.tsx" />
        </div>

        <LimitsChangeRequestsTable limits={limits} refetchLimits={refetchLimits} data-sentry-element="LimitsChangeRequestsTable" data-sentry-source-file="federal-reserve-limits.tsx" />

        <LimitsEventTable limits={limits} data-sentry-element="LimitsEventTable" data-sentry-source-file="federal-reserve-limits.tsx" />
      </Box>
    </PageLayout>;
};