import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { BookkeepingEntrySetGetResponseEntriesItem } from 'src/build/operations';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<BookkeepingEntrySetGetResponseEntriesItem>;
export const BookkeepingEntriesForOneEntrySetTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BookkeepingEntrySetGetResponseEntriesItem>>(() => {
    return [{
      header: 'Account',
      contents: record => ({
        text: record.bookkeeping_account_name,
        href: buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL, {
          bookkeepingAccountID: record.bookkeeping_account_id
        })
      })
    }, {
      header: 'Account type',
      contents: record => ({
        text: record.bookkeeping_account_compliance_category ? humanize(record.bookkeeping_account_compliance_category) : 'None'
      })
    }, {
      header: '(-) Amount',
      contents: record => ({
        text: record.amount < 0 ? formatAmount(record.amount, 'USD') : '',
        textColor: 'emphasis',
        textWeight: 'bold'
      })
    }, {
      header: '(+) Amount',
      contents: record => ({
        text: record.amount > 0 ? formatAmount(record.amount, 'USD') : '',
        textColor: 'emphasis',
        textWeight: 'bold'
      })
    }];
  }, []);
  const defaultRowProps = () => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<BookkeepingEntrySetGetResponseEntriesItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="BookkeepingEntriesForOneEntrySetTable" data-sentry-source-file="BookkeepingEntriesForOneEntrySetTable.tsx" />;
};