import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAmount, formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { PendingTransactionGetResponse } from 'src/build/operations';
import { useAccountGet } from 'src/hooks/reactQuery';
import { useAccountPropertyRow, useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';
type PendingTransactionPropertyListProps = {
  pendingTransaction: PendingTransactionGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const PendingTransactionPropertyList = (props: PendingTransactionPropertyListProps) => {
  const {
    pendingTransaction,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(pendingTransaction.group_id);
  const {
    data: account
  } = useAccountGet(pendingTransaction.account_id);
  const programRow = useProgramPropertyRow(account?.program_id);
  const accountRow = useAccountPropertyRow(pendingTransaction.account_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: pendingTransaction.id,
    copyable: pendingTransaction.id
  }, {
    label: 'Status',
    value: humanize(pendingTransaction.status),
    badgeColor: pendingTransaction.status === 'pending' ? 'yellow' : 'gray'
  }, pendingTransaction.completed_at && {
    label: 'Completed at',
    value: formatISO8601Timestamp(pendingTransaction.completed_at, 'full')
  }, {
    label: 'Type',
    value: pendingTransaction.source_type.replaceAll('API::', '')
  }, {
    label: 'Amount',
    value: formatAmount(pendingTransaction.amount, pendingTransaction.currency)
  }, {
    label: 'Sign',
    value: pendingTransaction.amount > 0 ? 'Positive. Account balance increases.' : 'Negative. Account balance decreases.'
  }, {
    label: 'Date',
    value: formatISO8601Timestamp(pendingTransaction.date, 'full')
  }, {
    label: 'Created at',
    value: formatISO8601Timestamp(pendingTransaction.created_at, 'full')
  }, groupRow, accountRow, programRow, pendingTransaction.route_id && pendingTransaction.route_type === 'API::Card' ? {
    label: 'Card',
    value: pendingTransaction.route_id,
    href: buildPath(ROUTES.CARD, {
      cardID: pendingTransaction.route_id
    })
  } : undefined])} data-sentry-element="PropertyList" data-sentry-component="PendingTransactionPropertyList" data-sentry-source-file="PendingTransactionPropertyList.tsx" />;
};