import { TableColumns, Table, PrestyledTableProps, Contents } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { formatBank } from 'src/lib/formatEnums';
import { OperatorListResponseDataItem, PartnerInvitationListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { compact } from 'lodash';
import { useOperatorDelete, usePartnerInvitationDelete } from 'src/hooks/reactQuery';
type OperatorOrInvite = OperatorListResponseDataItem | PartnerInvitationListResponseDataItem;
type Props = PrestyledTableProps<OperatorOrInvite> & {
  showActions: boolean;
};
const isInvite = (operatorOrInvite: OperatorOrInvite): operatorOrInvite is PartnerInvitationListResponseDataItem => {
  return 'inviter_id' in operatorOrInvite;
};
const getOperatorOrInviteStatus = (operatorOrInvite: OperatorOrInvite): Contents => {
  if (isInvite(operatorOrInvite)) {
    return {
      text: 'Invited',
      badgeColor: 'yellow'
    };
  }
  return {
    text: 'Active',
    badgeColor: 'none'
  };
};
const prettyTitle: Record<OperatorOrInvite['role'], string> = {
  internal: 'Increase Internal',
  check_reviewer: 'Check reviewing contractor',
  transaction_monitor: 'Transaction monitoring contractor',
  card_printer: 'Card printing contractor',
  partner_read_write: 'Read-write',
  partner_read_only: 'Read-only',
  partner_administrator: 'Administrator',
  partner_examiner_jan_2025: 'Examiner',
  limit_approver: 'Limit approver'
};
export const OperatorAndInvitesTable = (props: Props) => {
  const {
    getRowProps,
    showActions,
    ...rest
  } = props;
  const deleteOperator = useOperatorDelete();
  const deleteInvite = usePartnerInvitationDelete();
  const columns = useMemo<TableColumns<OperatorOrInvite>>(() => [{
    header: 'Email',
    expand: 1,
    contents: operator => ({
      text: operator.email,
      textWeight: 'medium',
      textColor: 'emphasis'
    })
  }, {
    header: 'Status',
    contents: operator => getOperatorOrInviteStatus(operator)
  }, {
    header: 'Title',
    contents: operator => ({
      text: prettyTitle[operator.role]
    })
  }, {
    header: 'Bank',
    contents: operator => ({
      text: operator.bank ? formatBank(operator.bank) : ''
    })
  }, {
    header: 'Created',
    contents: operator => ({
      text: formatISO8601Timestamp(operator.created_at, 'day-month-year')
    })
  }, showActions && {
    header: 'Actions',
    align: 'right',
    contents: operator => ({
      menuItems: compact([!isInvite(operator) && {
        title: 'Remove',
        key: 'delete',
        textColor: 'red',
        onClick: () => {
          deleteOperator.mutate([operator.id]);
        }
      }, isInvite(operator) && {
        title: 'Cancel invitation',
        key: 'delete',
        textColor: 'red',
        onClick: () => {
          deleteInvite.mutate([operator.id]);
        }
      }])
    })
  }], [deleteInvite, deleteOperator, showActions]);
  return <Table<OperatorOrInvite> columns={columns} getRowProps={getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="OperatorAndInvitesTable" data-sentry-source-file="OperatorAndInvitesTable.tsx" />;
};