import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { FedwireInboundServiceMessageGetResponse } from 'src/build/operations';
import { splitOnFedwireTags } from 'src/lib/fedwire-utility';
type FedwireServiceMessagePropertyListProps = {
  fedwireServiceMessage: FedwireInboundServiceMessageGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const FedwireServiceMessagePropertyList = (props: FedwireServiceMessagePropertyListProps) => {
  const {
    fedwireServiceMessage,
    ...rest
  } = props;
  return <PropertyList {...rest} layoutHorizontallyWhenPossible={false} items={compact<ListItem>([{
    label: 'ID',
    value: fedwireServiceMessage.id,
    copyable: fedwireServiceMessage.id
  }, {
    label: 'Body',
    valueNode: <div className="flex bg-strong p-2">
              <pre className="grow overflow-scroll font-mono text-sm">
                {splitOnFedwireTags(fedwireServiceMessage.message_body)}
              </pre>
            </div>
  }, {
    label: 'Status',
    value: fedwireServiceMessage.review_status
  }, {
    label: 'Received On',
    value: formatISO8601Timestamp(fedwireServiceMessage.received_on, 'medium')
  }])} data-sentry-element="PropertyList" data-sentry-component="FedwireServiceMessagePropertyList" data-sentry-source-file="FedwireServiceMessagePropertyList.tsx" />;
};