import { PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { ProgramGetResponse } from 'src/build/operations';
type ProgramPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const ProgramTransferSettingsPropertyList = (props: ProgramPropertyListProps) => {
  const {
    program,
    ...rest
  } = props;
  return <PropertyList {...rest} items={[{
    label: 'API transfers require approval',
    value: program.api_transfers_require_approval ? 'true' : 'false'
  }, {
    label: 'Dashboard Transfers require approval',
    value: program.dashboard_transfers_require_approval ? 'true' : 'false'
  }, {
    label: 'Transfers approvable by creator',
    value: program.transfers_approvable_by_creator ? 'true' : 'false'
  }]} data-sentry-element="PropertyList" data-sentry-component="ProgramTransferSettingsPropertyList" data-sentry-source-file="ProgramTransferSettingsPropertyList.tsx" />;
};