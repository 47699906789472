import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useLateReturnRequestListInfinite } from 'src/hooks/reactQuery';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { LateReturnRequestsTable } from '../tables/LateReturnRequestsTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeGroupFilter, makeKeywordFilter } from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES, buildPath } from 'src/lib/routes';
export const LateReturnRequestListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.LATE_RETURN_REQUESTS_LIST, 'group');
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(ROUTES.LATE_RETURN_REQUESTS_LIST, 'keyword');
  const filters: TableFilter[] = useMemo(() => compact([makeGroupFilter(groupFilter, setGroupFilter), makeKeywordFilter(keywordFilter, setKeywordFilter)]), [groupFilter, keywordFilter, setGroupFilter, setKeywordFilter]);
  const listResult = useLateReturnRequestListInfinite({
    group_ids: groupFilter.length > 0 ? groupFilter : undefined,
    keyword: keywordFilter && keywordFilter.length > 0 ? keywordFilter : undefined
  });
  return <PageLayout headline={'Late Return Requests'} action={<StyledLink href={buildPath(ROUTES.LATE_RETURN_REQUESTS_CREATE, {})} style="underline">
          Create late return request
        </StyledLink>} data-sentry-element="PageLayout" data-sentry-component="LateReturnRequestListPage" data-sentry-source-file="LateReturnRequestListPage.tsx">
      <TableStateWrapper {...listResult} table={LateReturnRequestsTable} style="primary" emptyTitle="No late return requests found" emptySubtitle="Late return requests will appear here when they are created" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="LateReturnRequestListPage.tsx" />
    </PageLayout>;
};