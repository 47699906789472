import dynamic from 'next/dynamic';
import { OperatorNotes } from './operator-notes';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useObjectGet } from 'src/hooks/reactQuery';
import { useEffect } from 'react';
import { AssociatedManualTasks } from './associated-manual-tasks';
import { PageLayout } from './deprecated/PageLayout';
import { PropertyList, PropertyListItem } from './deprecated/PropertyList';
import { Section } from './deprecated/Section';
import { CopyButton } from './deprecated/CopyButton';
import { StyledLink } from './deprecated/StyledLink';
import { groupByNicknameGet } from 'src/build/operations';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
const JSONViewer = dynamic(() => import('react-json-view'), {
  ssr: false
});
export const administrationRedirectUrl = (rubyClassName: string, objectID: string) => {
  switch (rubyClassName) {
    case 'Context::SharedModel::Group':
      return buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: objectID
      });
    case 'API::Entity':
      return buildPath(ROUTES.ENTITY, {
        entityId: objectID
      });
    case 'API::Account':
      return buildPath(ROUTES.ACCOUNTS_DETAIL, {
        accountId: objectID
      });
    case 'API::Card':
      return buildPath(ROUTES.CARD, {
        cardID: objectID
      });
    case 'API::Transaction':
      return buildPath(ROUTES.TRANSACTION_DETAIL, {
        transactionID: objectID
      });
    case 'API::Program':
      return buildPath(ROUTES.PROGRAMS_DETAIL, {
        programId: objectID
      });
    case 'API::AccountTransfer':
    case 'API::ACHTransfer':
    case 'API::CheckDeposit':
    case 'API::CheckTransfer':
    case 'API::InboundACHTransfer':
    case 'API::RealTimePaymentsTransfer':
    case 'API::WireTransfer':
    case 'API::Transfer':
      return buildPath(ROUTES.TRANSFER_DETAIL, {
        transferID: objectID
      });
    case 'API::PendingTransaction':
      return buildPath(ROUTES.PENDING_TRANSACTION_DETAIL, {
        pendingTransactionID: objectID
      });
    case 'API::DeclinedTransaction':
      return buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
        declinedTransactionID: objectID
      });
    case 'API::PlatformComplaintListSubmission':
      return buildPath(ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL, {
        submissionID: objectID
      });
    case 'API::PlatformComplianceMetricsSubmission':
      return buildPath(ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL, {
        submissionID: objectID
      });
    case 'API::PlatformVendorListSubmission':
      return buildPath(ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL, {
        submissionID: objectID
      });
    default:
      return null;
  }
};
const urlForObjectId = (value: string) => buildPath(ROUTES.OBJECT_VIEWER, {
  objectID: value
});
function redirectUrl(key: string, value: unknown) {
  if (key.endsWith('_id') && typeof value === 'string' && value.includes('_')) {
    return urlForObjectId(value);
  }
  if (typeof value === 'string' && value.startsWith('https://')) {
    return value;
  }
}
function fileDownloadUrl(objectID: string) {
  const chunks = objectID.split('_');
  const prefix = chunks.slice(0, chunks.length - 1).join('_');
  switch (prefix) {
    case 'file':
    case 'api_file':
      return `https://operations.increase.com/api_files/${objectID}/view`;
    case 'operator_file':
      return `https://operations.increase.com/operator-files/${objectID}/view`;
    case 'internal_file':
      return `https://operations.increase.com/internal-files/${objectID}/view`;
    case 'outbound_file':
      return `https://operations.increase.com/outbound-files/${objectID}/view`;
    default:
      return null;
  }
}
function stringify(value: unknown): string {
  if (typeof value === 'boolean' || typeof value === 'number') {
    return value + '';
  }
  return String(value);
}
const ParameterRow = (props: {
  title: string;
  value: unknown;
}) => {
  const navigate = useNavigate();
  const {
    value
  } = props;
  const url = redirectUrl(props.title, value);
  if (value == null) {
    return <PropertyListItem label={props.title}>
        <div className="italic">null</div>
      </PropertyListItem>;
  }
  return <PropertyListItem label={props.title} data-sentry-element="PropertyListItem" data-sentry-component="ParameterRow" data-sentry-source-file="object-viewer.tsx">
      <div className="flex w-full flex-row justify-between">
        {typeof value === 'object' ? <JSONViewer name={null} displayDataTypes={false} indentWidth={2} displayObjectSize={false} quotesOnKeys={false} collapseStringsAfterLength={30} onSelect={({
        value: newValue
      }) => {
        const valueUrl = redirectUrl('id', newValue);
        if (valueUrl) {
          navigate(valueUrl);
        }
      }} src={value} /> : url ? <StyledLink to={url}>{stringify(value)}</StyledLink> : stringify(value)}
        {typeof value == 'string' && <CopyButton text={stringify(value)} />}
      </div>
    </PropertyListItem>;
};
const HasMany = (props: {
  name: string;
  value: string[] | number;
}) => <PropertyListItem label={`Has many ${props.name}`} data-sentry-element="PropertyListItem" data-sentry-component="HasMany" data-sentry-source-file="object-viewer.tsx">
    {Array.isArray(props.value) ? <>
        {props.value.length === 0 && <i>None.</i>}
        <div className="flex w-full flex-col gap-y-1">
          {props.value.map(e => <div className="flex w-full flex-row justify-between" key={e}>
              <StyledLink to={urlForObjectId(e)}>{e}</StyledLink>
              <CopyButton text={e} />
            </div>)}
        </div>
      </> : <i>Too many to show ({props.value})</i>}
  </PropertyListItem>;
const NotFound = ({
  objectID
}: {
  objectID: string;
}) => <PageLayout headline="Could not find object" data-sentry-element="PageLayout" data-sentry-component="NotFound" data-sentry-source-file="object-viewer.tsx">
    <div>{objectID}</div>
  </PageLayout>;
const ErrorMessage = ({
  objectID,
  error
}: {
  objectID: string;
  error: Error | undefined;
}) => <PageLayout headline="Received an error when loading object" data-sentry-element="PageLayout" data-sentry-component="ErrorMessage" data-sentry-source-file="object-viewer.tsx">
    <div>{objectID}</div>
    <pre className="font-mono text-sm">{JSON.stringify(error, null, 2)}</pre>
  </PageLayout>;
const CodeBlock = (props: {
  objectID: string;
  rubyClassName: string;
}) => {
  const variableName = props.objectID.substring(0, props.objectID.lastIndexOf('_'));
  const code = `${variableName} = ${props.rubyClassName}.find("${props.objectID}")`;
  return <Section header="Explore in Pry" data-sentry-element="Section" data-sentry-component="CodeBlock" data-sentry-source-file="object-viewer.tsx">
      <div className="flex bg-strong p-2">
        <pre className="grow font-mono text-sm">{code}</pre>
        <CopyButton keyboardShortcut="⌘." text={code} data-sentry-element="CopyButton" data-sentry-source-file="object-viewer.tsx" />
      </div>
    </Section>;
};
const cleanupRawId = (id: string) => id.replace(/(^['"])|(['"]$)/g, '').toLowerCase();
const FRIENDLY_NICKNAME_REGEX = /([a-z]+_){1,2}([a-z]+)/g;
export const ObjectViewer = () => {
  const {
    objectID: rawId
  } = useTypedParams(ROUTES.OBJECT_VIEWER);
  const objectID = cleanupRawId(rawId);
  const [queryParams] = useSearchParams();
  const isRaw = queryParams.get('raw');
  const navigate = useNavigate();
  const {
    data: object,
    isLoading,
    error
  } = useObjectGet(objectID);
  useEffect(() => {
    const url = object?.meta?.class_name && administrationRedirectUrl(object.meta.class_name, objectID);
    if (isRaw) {
      return;
    }
    if (url) {
      navigate(url, {
        replace: true
      });
    }
    const attemptGroupRedirect = async () => {
      const {
        data
      } = await groupByNicknameGet({
        nickname: objectID
      });
      navigate(buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: data.id
      }), {
        replace: true
      });
    };
    if (objectID.match(FRIENDLY_NICKNAME_REGEX)) {
      attemptGroupRedirect();
    }
  }, [object?.meta?.class_name, objectID, isRaw, navigate]);
  if (isLoading) {
    return <></>;
  }
  if (error && error.response?.status !== 404) {
    return <ErrorMessage objectID={objectID} error={error.response?.data} />;
  }
  if (!object) {
    return <NotFound objectID={objectID} />;
  }
  const downloadUrl = fileDownloadUrl(objectID);
  const data = object.data as Record<string, unknown>;
  const metadata = object.meta;
  const additional = metadata.additional as Record<string, unknown>;
  const hasMany = metadata.has_many as Record<string, unknown>;
  return <PageLayout headline={metadata.class_name} data-sentry-element="PageLayout" data-sentry-component="ObjectViewer" data-sentry-source-file="object-viewer.tsx">
      <div>
        {downloadUrl && <StyledLink target="_blank" to={downloadUrl}>
            View
          </StyledLink>}
      </div>
      <PropertyList data-sentry-element="PropertyList" data-sentry-source-file="object-viewer.tsx">
        {Object.entries(data).map(([key, value]) => <ParameterRow key={key} title={key} value={value} />)}
        {Object.entries(additional).map(([key, value]) => <ParameterRow key={key} title={`${key} (additional)`} value={value} />)}
        <ParameterRow title={'Class Name'} value={metadata['class_name']} data-sentry-element="ParameterRow" data-sentry-source-file="object-viewer.tsx" />
        {Object.entries(hasMany).map(([key, value]) => <HasMany key={key} name={key} value={value as number | string[]} />)}
      </PropertyList>
      {metadata.operator_notable && <OperatorNotes modelId={objectID} />}
      {metadata.transaction_accounting_trace_id && <Section header="Transaction Accounting">
          <PropertyList>
            <PropertyListItem label="Trace">
              <div className="flex w-full flex-row justify-between">
                <StyledLink to={buildPath(ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE, {
              traceID: metadata.transaction_accounting_trace_id
            })}>
                  {metadata.transaction_accounting_trace_id}
                </StyledLink>
                <CopyButton text={metadata.transaction_accounting_trace_id} />
              </div>
            </PropertyListItem>
          </PropertyList>
        </Section>}
      <AssociatedManualTasks objectId={String(data.id)} data-sentry-element="AssociatedManualTasks" data-sentry-source-file="object-viewer.tsx" />
      <CodeBlock rubyClassName={object.meta.class_name} objectID={objectID} data-sentry-element="CodeBlock" data-sentry-source-file="object-viewer.tsx" />
    </PageLayout>;
};