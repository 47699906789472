import React from 'react';
import { Application } from './application';
import { ConfirmOneTimePassword } from './confirm-one-time-password';
import { SignIn } from './sign-in';
import { Register } from 'src/components/register';
import { useSessionGet } from 'src/hooks/reactQuery';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const Authentication = () => {
  const [queryParams] = useTypedSearchParams(ROUTES.ROOT);
  const invitationCode = queryParams.i;
  const {
    data: session,
    isLoading
  } = useSessionGet({}, {
    refetchOnWindowFocus: false
  });
  if (isLoading) {
    return <div id="loading" />;
  } else if (session) {
    return session.one_time_password_status === 'confirmed' ? <Application /> : <ConfirmOneTimePassword />;
  } else if (invitationCode) {
    return <Register invitationCode={invitationCode} />;
  } else {
    return <SignIn />;
  }
};