import { BadgeColor } from '@increase/shared/components/Badge';
import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { UserListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { ROUTES, buildPath } from 'src/lib/routes';
type Props = PrestyledTableProps<UserListResponseDataItem>;
const UserEmailVerificationStatusBadgeColors: Record<UserListResponseDataItem['email_verification_status'], BadgeColor> = {
  pending_verification: 'yellow',
  requires_attention: 'yellow',
  ignored: 'yellow',
  verified: 'green'
};
export const UsersTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<UserListResponseDataItem>>(() => [{
    header: 'Email',
    expand: 1,
    contents: user => ({
      text: user.email,
      textWeight: 'medium',
      textColor: 'emphasis'
    })
  }, {
    header: 'Email verification',
    contents: User => ({
      text: humanize(User.email_verification_status),
      badgeColor: UserEmailVerificationStatusBadgeColors[User.email_verification_status]
    })
  }, {
    header: 'Created',
    contents: user => ({
      text: formatISO8601Timestamp(user.created_at, 'medium')
    })
  }], []);
  const defaultRowProps = (datum: UserListResponseDataItem) => ({
    href: buildPath(ROUTES.USERS_DETAIL, {
      userId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<UserListResponseDataItem> columns={columns} getRowProps={getRowProps || defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="UsersTable" data-sentry-source-file="UsersTable.tsx" />;
};