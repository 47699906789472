import { compact } from 'lodash';
import { PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { GroupGetResponse, GroupGetResponseRiskRating } from 'src/build/operations';
import { groupStatusBadgeColors } from '../tables/GroupsTable';
import { BadgeColor } from '@increase/shared/components/Badge';
import { formatAmount, formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { useGroupPatch, useOperatorGet } from 'src/hooks/reactQuery';
import { ROUTES, buildPath } from 'src/lib/routes';
type GroupPropertyListProps = {
  group: GroupGetResponse;
  showBanks: boolean;
} & Omit<PropertyListProps, 'items'>;
const groupRiskRatingBadgeColors: Record<GroupGetResponseRiskRating, BadgeColor> = {
  low: 'green',
  medium: 'yellow',
  high: 'red'
};
export const GroupPropertyList = (props: GroupPropertyListProps) => {
  const {
    group,
    showBanks,
    ...rest
  } = props;
  const {
    data: operator
  } = useOperatorGet({});
  const groupPatch = useGroupPatch();
  const items: PropertyListProps['items'] = compact([{
    label: 'ID',
    value: group.id,
    copyable: group.id
  }, {
    label: 'Status',
    value: group.status,
    badgeColor: groupStatusBadgeColors[group.status]
  }, {
    label: 'Signed up',
    value: formatISO8601Timestamp(group.created_at, 'medium')
  }, showBanks && {
    label: 'Banks',
    value: group.banks.map(humanize).join(', ')
  }, {
    label: 'ACH Company ID',
    value: group.ach_company_id === '9806373283' ? `${group.ach_company_id} (Increase Default)` : group.ach_company_id
  }, group.reserve_account_current_balance && group.reserve_account_id && {
    label: 'Reserve Account Balance',
    value: formatAmount(group.reserve_account_current_balance, 'USD'),
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: group.reserve_account_id
    })
  }, {
    label: 'Risk Rating',
    value: group.risk_rating,
    badgeColor: groupRiskRatingBadgeColors[group.risk_rating]
  }, {
    label: 'Facilitates Investment',
    value: group.facilitates_investment,
    disabled: operator?.role !== 'internal',
    onToggle: (newValue: boolean) => groupPatch.mutateAsync([group.id, {
      facilitates_investment: newValue
    }])
  }, {
    label: 'Facilitates Money Services',
    value: group.facilitates_money_services_business,
    disabled: operator?.role !== 'internal',
    onToggle: (newValue: boolean) => groupPatch.mutateAsync([group.id, {
      facilitates_money_services_business: newValue
    }])
  }, {
    label: 'Needs Diligence Periodically Refreshed',
    value: group.needs_diligence_periodically_refreshed,
    disabled: operator?.role !== 'internal',
    onToggle: (newValue: boolean) => groupPatch.mutateAsync([group.id, {
      needs_diligence_periodically_refreshed: newValue
    }])
  }]);
  return <PropertyList items={items} {...rest} data-sentry-element="PropertyList" data-sentry-component="GroupPropertyList" data-sentry-source-file="GroupPropertyList.tsx" />;
};