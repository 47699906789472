import React from 'react';
import { useUnusualActivityReportsGet } from 'src/hooks/reactQuery';
import { OperatorNotes } from './operator-notes';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { InternalOperatorOnly } from './internal-operator-only';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { compact } from 'lodash';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
export const DirectUnusualActivityReport = () => {
  const {
    unusualActivityReportId
  } = useTypedParams(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS);
  const {
    data
  } = useUnusualActivityReportsGet(unusualActivityReportId);
  const groupRow = useGroupPropertyRow(data?.group_id);
  if (!data) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline="Unusual Activity Report" data-sentry-element="PageLayout" data-sentry-component="DirectUnusualActivityReport" data-sentry-source-file="unusual-activity-report.tsx">
      <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-source-file="unusual-activity-report.tsx">
        <Section header="Unusual Activity Report" data-sentry-element="Section" data-sentry-source-file="unusual-activity-report.tsx">
          <PropertyList items={compact<ListItem>([{
          label: 'ID',
          value: data.id
        }, {
          label: 'Created at',
          value: formatISO8601Timestamp(data.created_at, 'full')
        }, groupRow, data.account_id && {
          label: 'Account ID',
          value: data.account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: data.account_id
          })
        }, {
          label: 'Reference Number',
          value: data.reference_number
        }, {
          label: 'Referral Channel',
          value: data.referral_channel
        }, {
          label: 'Determined On',
          value: data.determined_on
        }, {
          label: 'Group Locked On',
          value: data.locked_on || ''
        }, {
          label: '90 day lookback submission',
          value: data.ninety_day_look_back_submission || ''
        }, {
          label: '180 day lookback submission',
          value: data.one_eighty_day_look_back_submission || ''
        }, {
          label: '270 day lookback submission',
          value: data.two_seventy_day_look_back_submission || ''
        }, {
          label: '90 day lookback status',
          value: data.ninety_day_look_back_status || ''
        }, {
          label: 'Internal Duplicates',
          value: data.internal_duplicates || ''
        }])} data-sentry-element="PropertyList" data-sentry-source-file="unusual-activity-report.tsx" />
        </Section>
        <Section header="Notes" data-sentry-element="Section" data-sentry-source-file="unusual-activity-report.tsx">
          <OperatorNotes modelId={data.id} data-sentry-element="OperatorNotes" data-sentry-source-file="unusual-activity-report.tsx" />
        </Section>
      </InternalOperatorOnly>
    </PageLayout>;
};