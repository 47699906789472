import React, { useCallback, useEffect, useState } from 'react';
import { InternalOperatorOnly } from './internal-operator-only';
import { PageLayout } from './deprecated/PageLayout';
import { CashReconciliationOverviewGetAccount, CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem, CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItem } from 'src/build/operations';
import { useSearchParams } from 'react-router-dom';
import { useCashReconciliationOverviewGet, useCashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet, useCashReconciliationUnreconciledExpectedCashTransactionTupleList } from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';
import { Section } from './deprecated/Section';
import { formatAmount, formatISO8601TimestampAsISODate } from '@increase/shared/lib/formatting';
import { DateRangePicker } from './deprecated/DateRangePicker';
import moment from 'moment';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { CheckboxInput } from '@increase/shared/components/CheckboxInput';
import { Button } from './deprecated/Button';
import { ROUTES, buildPath } from 'src/lib/routes';
import { Note } from '@increase/shared/components/Note';
import { Select, SelectSection } from '@increase/shared/components/Select';
const DEFAULT_ACCOUNT = CashReconciliationOverviewGetAccount.first_internet_bank_federal_reserve;
const INITIAL_LOOKBACK_DAYS = 7;
type DenormalizedUnreconciledExpectedCashTransactionTupleDetail = CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItem & {
  account: CashReconciliationOverviewGetAccount;
  settlementDate: string;
  transactionCode: string;
};
const Details = ({
  entry
}: {
  entry: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem | CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItem;
}) => {
  if (entry.ach_details) {
    return entry.ach_details.processing_date ? <>
        <p>
          ACH processing date:{' '}
          <b>
            {formatISO8601TimestampAsISODate(entry.ach_details.processing_date)}
          </b>
        </p>
        <p>
          Debit / Credit indicator:{' '}
          <b>{entry.ach_details.debit_credit_indicator}</b>
        </p>
      </> : null;
  } else if (entry.check21_details) {
    return <>
        <p>
          Check21 posting time:{' '}
          <b>{entry.check21_details.daylight_overdraft_posting_time}</b>
        </p>
        {entry.check21_details.original_cash_letter_amount && <p>
            Original cash letter amount{' '}
            <b>
              {formatAmount(entry.check21_details.original_cash_letter_amount, 'USD')}
            </b>
          </p>}
        {entry.check21_details.grouping_key && <p>
            Grouping key{' '}
            <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: entry.check21_details.grouping_key
        })}>
              {entry.check21_details.grouping_key}
            </StyledLink>
          </p>}
      </>;
  } else if (entry.wire_details) {
    return <p>
        Wire IMAD: <b>{entry.wire_details.input_message_accountability_data}</b>
      </p>;
  } else {
    return null;
  }
};
const ActionLinkForDetailEntry = ({
  entry,
  selectedUnreconciledExpectations
}: {
  entry: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem;
  selectedUnreconciledExpectations: DenormalizedUnreconciledExpectedCashTransactionTupleDetail[];
}) => {
  const links = [];
  if (entry.action === 'create_inbound_check_deposit_adjustment_from_financial_institution_reconcilement_data_detail_entry') {
    links.push({
      text: 'Create Expectation',
      href: `/manual_tasks/Operations::ManualTask::CreateInboundCheckDepositAdjustmentFromFinancialInstitutionReconcilementDataDetailEntry?detail_entry_id=${entry.id}`
    });
  }
  if (selectedUnreconciledExpectations.length === 1) {
    const expectation = selectedUnreconciledExpectations[0];
    if (entry.ach_details && entry.ach_details.processing_date && expectation.ach_details && expectation.ach_details.processing_date && entry.ach_details.debit_credit_indicator === expectation.ach_details.debit_credit_indicator) {
      const params = new URLSearchParams({
        account: expectation.account,
        debit_credit_indicator: expectation.ach_details.debit_credit_indicator,
        expected_settlement_date: expectation.settlementDate,
        expected_transaction_code: expectation.transactionCode,
        expected_ach_processing_date: expectation.ach_details.processing_date,
        actual_settlement_date: entry.settlement_date,
        actual_transaction_code: entry.parsed_transaction_code,
        actual_ach_processing_date: entry.ach_details.processing_date
      });
      links.push({
        text: 'Shift some of selected ACH expectation to this cash entry',
        href: '/manual_tasks/Operations::ManualTask::CreateACHCashExpectationAdjustments?' + params.toString()
      });
    }
  }
  if (links.length === 0) {
    return null;
  }
  return <div data-sentry-component="ActionLinkForDetailEntry" data-sentry-source-file="cash-reconciliation.tsx">
      {links.map(link => <div key={link.href}>
          <StyledLink style="underline" href={link.href} target="_blank">
            <Button>{link.text}</Button>
          </StyledLink>
        </div>)}
    </div>;
};
const TupleDetail = ({
  account,
  transactionCode,
  settlementDate,
  selected,
  onSelectChange
}: {
  account: CashReconciliationOverviewGetAccount;
  transactionCode: string;
  settlementDate: string;
  selected: DenormalizedUnreconciledExpectedCashTransactionTupleDetail[];
  onSelectChange: (detail: DenormalizedUnreconciledExpectedCashTransactionTupleDetail, selected: boolean) => void;
}) => {
  const {
    data
  } = useCashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet({
    account: account,
    financial_institution_reconcilement_data_settlement_date: settlementDate,
    financial_institution_reconcilement_data_transaction_code: transactionCode
  });
  return data ? <div className="p-5" data-sentry-component="TupleDetail" data-sentry-source-file="cash-reconciliation.tsx">
      <Table data={data.details.map(d => {
      return {
        id: d.arbitrary_example_id,
        ...d
      };
    })} columns={[{
      key: 'select',
      label: '',
      render: e => <CheckboxInput checked={selected.map(d => d.arbitrary_example_id).includes(e.arbitrary_example_id)} onChange={cb => onSelectChange({
        account: account,
        settlementDate: settlementDate,
        transactionCode: transactionCode,
        ...e
      }, cb.target.checked)} label="" />
    }, {
      key: 'details',
      label: 'Details',
      render: e => <Details entry={e} />
    }, {
      key: 'count',
      label: 'Count'
    }, {
      key: 'amount',
      label: 'Sum(amount)',
      render: t => formatAmount(t.sum, 'USD')
    }, {
      key: 'arbitrary_example_id',
      label: 'Example',
      render: t => {
        return <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: t.arbitrary_example_id
        })}>
                  {t.arbitrary_example_id}
                </StyledLink>;
      }
    }]} data-sentry-element="Table" data-sentry-source-file="cash-reconciliation.tsx" />
    </div> : <div data-sentry-component="TupleDetail" data-sentry-source-file="cash-reconciliation.tsx">Loading...</div>;
};
export const CashReconciliation = () => {
  const [queryParams, setQueryParams] = useSearchParams();
  const [account, setAccount] = React.useState<CashReconciliationOverviewGetAccount>(queryParams.get('account') as CashReconciliationOverviewGetAccount || DEFAULT_ACCOUNT);
  const yesterday = moment().subtract(1, 'days');
  const [unreconciledExpectedCashTransactionTupleListEndDate, setUnreconciledExpectedCashTransactionTupleListEndDate] = useState<string>(queryParams.get('unreconciledExpectedCashTransactionTupleListEndDate') ?? formatISO8601TimestampAsISODate(yesterday.toISOString()));
  const [unreconciledExpectedCashTransactionTupleListStartDate, setUnreconciledExpectedCashTransactionTupleListStartDate] = useState<string>(queryParams.get('unreconciledExpectedCashTransactionTupleListStartDate') ?? formatISO8601TimestampAsISODate(yesterday.subtract(INITIAL_LOOKBACK_DAYS, 'days').toISOString()));
  const [selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries, setSelectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries] = useState<CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem[]>([]);
  const [selectedUnreconciledExpectedCashTransactionTupleDetails, setSelectedUnreconciledExpectedCashTransactionTupleDetails] = useState<DenormalizedUnreconciledExpectedCashTransactionTupleDetail[]>([]);
  const updateCashSelectState = useCallback((entry: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem, selected: boolean) => {
    const newSelected = [];
    for (let i = 0; i < selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries.length; i++) {
      if (selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries[i].id !== entry.id) {
        newSelected.push(selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries[i]);
      }
    }
    if (selected) {
      newSelected.push(entry);
    }
    setSelectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries(newSelected);
  }, [selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries]);
  const updateExpectationSelectState = useCallback((entry: DenormalizedUnreconciledExpectedCashTransactionTupleDetail, selected: boolean) => {
    const newSelected = [];
    for (let i = 0; i < selectedUnreconciledExpectedCashTransactionTupleDetails.length; i++) {
      if (selectedUnreconciledExpectedCashTransactionTupleDetails[i].arbitrary_example_id !== entry.arbitrary_example_id) {
        newSelected.push(selectedUnreconciledExpectedCashTransactionTupleDetails[i]);
      }
    }
    if (selected) {
      newSelected.push(entry);
    }
    setSelectedUnreconciledExpectedCashTransactionTupleDetails(newSelected);
  }, [selectedUnreconciledExpectedCashTransactionTupleDetails]);
  const deselectAll = useCallback(() => {
    setSelectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries([]);
    setSelectedUnreconciledExpectedCashTransactionTupleDetails([]);
  }, []);
  const selectedUnreconciledCashSum = selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries.reduce((acc, entry) => acc + entry.amount, 0);
  const selectedUnreconciledExpectationSum = selectedUnreconciledExpectedCashTransactionTupleDetails.reduce((acc, entry) => acc + entry.sum, 0);
  useEffect(() => {
    let anySet = false;
    if (!queryParams.get('unreconciledExpectedCashTransactionTupleListStartDate')) {
      queryParams.set('unreconciledExpectedCashTransactionTupleListStartDate', unreconciledExpectedCashTransactionTupleListStartDate);
      anySet = true;
    }
    if (!queryParams.get('unreconciledExpectedCashTransactionTupleListEndDate')) {
      queryParams.set('unreconciledExpectedCashTransactionTupleListEndDate', unreconciledExpectedCashTransactionTupleListEndDate);
      anySet = true;
    }
    if (!queryParams.get('account')) {
      queryParams.set('account', account);
      anySet = true;
    }
    if (anySet) {
      setQueryParams(queryParams, {
        replace: true
      });
    }
  }, [queryParams, setQueryParams, unreconciledExpectedCashTransactionTupleListStartDate, unreconciledExpectedCashTransactionTupleListEndDate, account]);
  const {
    data
  } = useCashReconciliationOverviewGet({
    account: account
  });
  const {
    data: unreconciledExpectedCashTransactionTuples
  } = useCashReconciliationUnreconciledExpectedCashTransactionTupleList({
    account: account,
    start_date: unreconciledExpectedCashTransactionTupleListStartDate,
    end_date: unreconciledExpectedCashTransactionTupleListEndDate
  });
  if (!data) {
    return <div>Loading</div>;
  }
  const sections: SelectSection<CashReconciliationOverviewGetAccount>[] = [{
    options: Object.values(CashReconciliationOverviewGetAccount).map(b => ({
      label: b,
      value: b
    }))
  }];
  return <PageLayout headline="Cash reconciliation" data-sentry-element="PageLayout" data-sentry-component="CashReconciliation" data-sentry-source-file="cash-reconciliation.tsx">
      <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-source-file="cash-reconciliation.tsx">
        <div>
          <b>Account:</b>
          <Select value={account || ''} onUpdate={value => {
          setAccount(value);
          setQueryParams({
            ...queryParams,
            account: value
          });
        }} sections={sections} data-sentry-element="Select" data-sentry-source-file="cash-reconciliation.tsx" />
        </div>

        {!data.configured_for_account && <Note>Account not configured for cash reconciliation.</Note>}

        <Section header="Selected" data-sentry-element="Section" data-sentry-source-file="cash-reconciliation.tsx">
          <ul>
            <li>
              Unreconciled cash:{' '}
              {selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries.length}
              , sum: {formatAmount(selectedUnreconciledCashSum, 'USD')}
            </li>
            <li>
              Unreconciled expectations:{' '}
              {selectedUnreconciledExpectedCashTransactionTupleDetails.length},
              sum: {formatAmount(selectedUnreconciledExpectationSum, 'USD')}
            </li>
            <li>
              Difference:{' '}
              {formatAmount(Math.abs(selectedUnreconciledCashSum - selectedUnreconciledExpectationSum), 'USD')}
            </li>
          </ul>
          {(selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries.length > 0 || selectedUnreconciledExpectedCashTransactionTupleDetails.length > 0) && <Button onClick={deselectAll}>Deselect all</Button>}
        </Section>

        <Section header="Unreconciled financial institution reconcilement data detail entries" data-sentry-element="Section" data-sentry-source-file="cash-reconciliation.tsx">
          <Table data={data.unreconciled_financial_institution_reconcilement_data_detail_entries} columns={[{
          key: 'select',
          label: '',
          render: e => <CheckboxInput label="" onChange={cb => updateCashSelectState(e, cb.target.checked)} checked={selectedUnreconciledFinancialInstitutionReconcilementDataDetailEntries.map(entry => entry.id).includes(e.id)} />
        }, {
          key: 'id',
          label: 'Id',
          render: e => <>
                    <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
              objectID: e.id
            })}>
                      {e.id}
                    </StyledLink>
                    <ActionLinkForDetailEntry entry={e} selectedUnreconciledExpectations={selectedUnreconciledExpectedCashTransactionTupleDetails} />
                  </>
        }, {
          key: 'settlement-date',
          label: 'Settlement date',
          render: e => formatISO8601TimestampAsISODate(e.settlement_date)
        }, {
          key: 'amount',
          label: 'Amount',
          render: e => formatAmount(e.amount, 'USD')
        }, {
          key: 'transaction-code',
          label: 'Transaction code',
          render: e => `${e.transaction_code} (${e.parsed_transaction_code})`
        }, {
          key: 'details',
          label: 'Details',
          render: e => <Details entry={e} />
        }]} data-sentry-element="Table" data-sentry-source-file="cash-reconciliation.tsx" />
        </Section>

        <Section header="(Transaction code, date) tuples for which unreconciled expected cash transaction contributions do not sum to zero" data-sentry-element="Section" data-sentry-source-file="cash-reconciliation.tsx">
          <div className="justify-start">
            <DateRangePicker startDate={unreconciledExpectedCashTransactionTupleListStartDate} endDate={unreconciledExpectedCashTransactionTupleListEndDate} onChange={({
            startDate,
            endDate
          }) => {
            setUnreconciledExpectedCashTransactionTupleListStartDate(startDate);
            setUnreconciledExpectedCashTransactionTupleListEndDate(endDate);
            setQueryParams({
              ...queryParams,
              unreconciledExpectedCashTransactionTupleListStartDate: startDate,
              unreconciledExpectedCashTransactionTupleListEndDate: endDate
            }, {
              replace: true
            });
          }} data-sentry-element="DateRangePicker" data-sentry-source-file="cash-reconciliation.tsx" />
          </div>
          {unreconciledExpectedCashTransactionTuples && unreconciledExpectedCashTransactionTuples.timed_out && <div>Timed out, try again...</div>}
          {unreconciledExpectedCashTransactionTuples ? <Table data={unreconciledExpectedCashTransactionTuples.unreconciled_expected_cash_transaction_tuples.map(r => {
          return {
            id: r.financial_institution_reconcilement_data_transaction_code + '-' + r.financial_institution_reconcilement_data_settlement_date,
            ...r
          };
        })} columns={[{
          key: 'financial_institution_reconcilement_data_settlement_date',
          label: 'Settlement date'
        }, {
          key: 'financial_institution_reconcilement_data_transaction_code',
          label: 'Transaction code'
        }, {
          key: 'count',
          label: 'Count'
        }, {
          key: 'amount',
          label: 'Sum(amount)',
          render: t => formatAmount(t.sum, 'USD')
        }, {
          key: 'arbitrary_example_id',
          label: 'Example',
          render: t => {
            return <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
              objectID: t.arbitrary_example_id
            })}>
                        {t.arbitrary_example_id}
                      </StyledLink>;
          }
        }]} canExpandRows renderExpandedRow={row => <TupleDetail account={account} selected={selectedUnreconciledExpectedCashTransactionTupleDetails} onSelectChange={updateExpectationSelectState} transactionCode={row.financial_institution_reconcilement_data_transaction_code} settlementDate={row.financial_institution_reconcilement_data_settlement_date} />} /> : <div>Loading...</div>}
        </Section>
      </InternalOperatorOnly>
    </PageLayout>;
};