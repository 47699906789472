import React, { useMemo, useState } from 'react';
import { PartnerInvitationPostParametersBank, PartnerInvitationPostParametersRole } from 'src/build/operations';
import { useOperatorGet, usePartnerInvitationListInfinite, usePartnerInvitationPost, useOperatorListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { OperatorAndInvitesTable } from '../tables/OperatorAndInvitesTable';
import { TextInput } from '@increase/shared/components/TextInput';
import { Button } from '@increase/shared/components/Button';
import { Select } from '@increase/shared/components/Select';
import { Box } from '@increase/shared/components/Box';
import { Body } from '@increase/shared/components/Text';
import { compact, keys } from 'lodash';
import { humanize } from '@increase/shared/lib/formatting';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { makeBankFilter, makeEmailFilter, makeOperatorRoleFilter } from 'src/lib/tableFilterHelpers';
const InvitationForm = (props: {
  bank?: PartnerInvitationPostParametersBank;
}) => {
  const [email, setEmail] = useState('');
  const [bank, setBank] = useState<PartnerInvitationPostParametersBank>(props.bank || 'first_internet_bank');
  const [role, setRole] = useState<PartnerInvitationPostParametersRole>('partner_read_only');
  const partnerInvitationPost = usePartnerInvitationPost();
  return <>
      <Box gap="2" data-sentry-element="Box" data-sentry-source-file="OperatorListPage.tsx">
        <Body color="secondary" weight="medium" data-sentry-element="Body" data-sentry-source-file="OperatorListPage.tsx">
          Invite a new partner
        </Body>
        <form onSubmit={async submitEvent => {
        submitEvent.preventDefault();
        return await partnerInvitationPost.mutateAsync([{
          email,
          bank,
          role
        }]);
      }} className="space-y-2">
          <div className="flex items-center space-x-2">
            <TextInput type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Partner email" className="max-w-[300px] grow rounded border border-stronger py-0.5 text-sm shadow-sm" data-sentry-element="TextInput" data-sentry-source-file="OperatorListPage.tsx" />
            <Select name="bank" disabled={!!props.bank} value={bank} onChange={e => {
            setBank(e.target.value as PartnerInvitationPostParametersBank);
          }} sections={[{
            options: keys(PartnerInvitationPostParametersBank).map(option => ({
              value: option,
              label: humanize(option)
            }))
          }]} data-sentry-element="Select" data-sentry-source-file="OperatorListPage.tsx" />
            <Select name="role" value={role} onChange={e => {
            setRole(e.target.value as PartnerInvitationPostParametersRole);
          }} sections={[{
            options: compact([{
              label: 'Read-only',
              value: PartnerInvitationPostParametersRole.partner_read_only
            }, {
              label: 'Read/write',
              value: PartnerInvitationPostParametersRole.partner_read_write
            }, {
              label: 'Administrator',
              value: PartnerInvitationPostParametersRole.partner_administrator
            }, props?.bank === 'first_internet_bank' && {
              label: 'Examiner',
              value: PartnerInvitationPostParametersRole.partner_examiner_jan_2025
            }])
          }]} data-sentry-element="Select" data-sentry-source-file="OperatorListPage.tsx" />

            <Button type="submit" text="Invite partner" data-sentry-element="Button" data-sentry-source-file="OperatorListPage.tsx" />
          </div>
        </form>
        {partnerInvitationPost.error && <div className="text-sm text-danger">
            {partnerInvitationPost.error.response?.data.message}
          </div>}
      </Box>
    </>;
};
export const OperatorListPage = () => {
  const {
    data: operator
  } = useOperatorGet({});
  const isInternal = operator && operator.role === 'internal';
  const [bankFilter, setBankFilter] = useSearchParamsState(ROUTES.PARTNERS, 'bank');
  const [roleFilter, setRoleFilter] = useSearchParamsState(ROUTES.PARTNERS, 'role');
  const [emailFilter, setEmailFilter] = useSearchParamsState(ROUTES.PARTNERS, 'email');
  const filters = useMemo(() => compact([isInternal && makeBankFilter(bankFilter, setBankFilter), isInternal && makeOperatorRoleFilter(roleFilter, setRoleFilter), makeEmailFilter(emailFilter, setEmailFilter)]), [bankFilter, emailFilter, isInternal, roleFilter, setBankFilter, setEmailFilter, setRoleFilter]);
  const {
    data: operatorPages,
    fetchNextPage,
    error: operatorError
  } = useOperatorListInfinite({
    bank: bankFilter.length > 0 ? bankFilter : undefined,
    role: roleFilter.length > 0 ? roleFilter : undefined,
    email: emailFilter
  });
  const {
    data: partnerInvitationPages,
    error: partnerInvitationError
  } = usePartnerInvitationListInfinite({
    bank: bankFilter.length > 0 ? bankFilter : undefined,
    role: roleFilter.length > 0 ? roleFilter : undefined,
    email: emailFilter
  });
  const partnerInvitations = partnerInvitationPages?.pages.flatMap(page => page.data) || [];
  const operators = operatorPages?.pages.flatMap(page => page.data) || [];
  const operatorsAndInvites = [...partnerInvitations, ...operators];
  if (!operator) {
    return <>Loading...</>;
  }
  const allowUpdates = operator.role === 'partner_administrator' || operator.role === 'internal';
  return <PageLayout id="partnersList" headline="Partners" data-sentry-element="PageLayout" data-sentry-component="OperatorListPage" data-sentry-source-file="OperatorListPage.tsx">
      {allowUpdates && <InvitationForm bank={operator.bank || undefined} />}

      <TableStateWrapper data={operatorsAndInvites} fetchNextPage={fetchNextPage} error={operatorError || partnerInvitationError} table={OperatorAndInvitesTable} showActions={allowUpdates} style="primary" filters={filters} emptyTitle="" emptySubtitle="" data-sentry-element="TableStateWrapper" data-sentry-source-file="OperatorListPage.tsx" />
    </PageLayout>;
};