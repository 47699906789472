import React, { useState } from 'react';
import { useCheckItemAllocationInformationGet } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { MultiImageTool } from './check-workflow/image-tool';
type Props = {
  checkItemID: string;
  onPixelsExtracted?: (pixels: ImageData) => void;
};
export const CheckItemImageView = ({
  checkItemID,
  onPixelsExtracted
}: Props) => {
  const {
    data: checkItemAllocationInformation,
    error
  } = useCheckItemAllocationInformationGet(checkItemID);
  const {
    operations
  } = useServices();
  const [showingImageIndex, setShowingImageIndex] = useState<number>(0);
  const incrementShowingImageIndex = () => {
    setShowingImageIndex(showingImageIndex + 1);
  };
  const decrementShowingImageIndex = () => {
    setShowingImageIndex(showingImageIndex - 1);
  };
  if (error) {
    return <span data-testid="getInformationError">{error.message}</span>;
  }
  if (!checkItemAllocationInformation) {
    return <span>Loading...</span>;
  }
  const showingImageView = checkItemAllocationInformation.image_views[showingImageIndex];
  const imageArgs = onPixelsExtracted ? {
    onPixelsExtracted,
    selectionMode: 'area'
  } as const : {
    selectionMode: 'none'
  } as const;
  return <div data-sentry-component="CheckItemImageView" data-sentry-source-file="check-item-image-view.tsx">
      <p>
        Image {showingImageIndex + 1} of{' '}
        {checkItemAllocationInformation.image_views.length} (
        <span>
          {showingImageView.view_side_indicator},{' '}
          <span>{showingImageView.image_view_data_id}</span>
        </span>
        )
        <span className="ml-1">
          {showingImageIndex > 0 && <button data-testid="previousImage" onClick={decrementShowingImageIndex}>
              ⬅︎
            </button>}
          {showingImageIndex < checkItemAllocationInformation.image_views.length - 1 && <button data-testid="nextImage" onClick={incrementShowingImageIndex}>
              ➡︎
            </button>}
        </span>
      </p>

      <MultiImageTool key={showingImageView.image_view_data_id} url={`${operations}/check_item_image_view_data/${showingImageView.image_view_data_id}/view`} {...imageArgs} data-sentry-element="MultiImageTool" data-sentry-source-file="check-item-image-view.tsx" />
    </div>;
};