import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAddress } from '@increase/shared/lib/formatting';
import { EntityGetResponseSetupSubmissionStructureTrust } from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
type EntityPropertyListProps = {
  trust: NonNullable<EntityGetResponseSetupSubmissionStructureTrust>;
} & Omit<PropertyListProps, 'items'>;
export const EntityTrustPropertyList = (props: EntityPropertyListProps) => {
  const {
    trust,
    ...rest
  } = props;
  const categoricals = useCategoricals();
  if (!categoricals) {
    return null;
  }
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'Name',
    value: trust.submitted_name
  }, {
    label: 'Category',
    value: trust.submitted_category
  }, {
    label: 'Tax ID',
    value: trust.submitted_tax_id ?? ''
  }, {
    label: 'Address',
    value: formatAddress(trust.submitted_address)
  }])} data-sentry-element="PropertyList" data-sentry-component="EntityTrustPropertyList" data-sentry-source-file="EntityTrustPropertyList.tsx" />;
};