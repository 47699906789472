type Props = {
  previous: number | null;
  current: number;
  percentStyle?: string;
};
export const PercentChange = ({
  previous,
  current,
  percentStyle
}: Props) => {
  if (previous === 0 || previous === null) {
    return <span>-</span>;
  } else {
    const percentChange = (current - previous) / previous * 100;
    return <span className={percentStyle || (percentChange > 0 ? 'text-success' : 'text-danger')}>
        {percentChange > 0 ? '+' : '-'}
        {Math.abs(percentChange).toFixed(2)}%
      </span>;
  }
};