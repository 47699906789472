import classNames from 'classnames';
import { Icon, IconType } from './Icon';
import { StyledLink } from './StyledLink';
import { Body } from './Text';
import { TextButton } from './TextButton';
export type AlertStyle = 'success' | 'warning' | 'informational' | 'error' | 'default';
export type AlertListProps = {
  tasks: {
    icon: IconType;
    title: string;
    body?: string;
    link?: string;
    key: string;
    style?: AlertStyle;
    actionText?: string;
    actionStyle?: 'default' | 'warning';
    actionDisabled?: boolean;
    onClick?: () => void;
  }[];
};
const STYLE_CLASSES_ALERT: Record<AlertStyle, string> = {
  success: 'bg-gradient-to-r from-success-subtle via-main to-main before:bg-success-subtle',
  warning: 'bg-gradient-to-r from-warning-subtle via-main to-main before:bg-warning-subtle',
  informational: 'bg-gradient-to-r from-info-subtle via-main to-main before:bg-info-subtle',
  error: 'bg-gradient-to-r from-danger-subtle via-main to-main before:bg-danger-subtle',
  default: 'bg-gradient-to-r from-subtle via-main to-main before:bg-strong'
};
const STYLE_CLASSES_ICON: Record<AlertStyle, string> = {
  success: 'stroke-success',
  warning: 'stroke-warning',
  informational: 'stroke-info',
  error: 'stroke-danger',
  default: 'stroke-subtle'
};
export const AlertList = ({
  tasks
}: AlertListProps) => <div className="card-xs divide-main divide-y overflow-hidden rounded-lg" data-sentry-component="AlertList" data-sentry-source-file="AlertList.tsx">
    {tasks.map(({
    icon,
    title,
    body,
    link,
    key,
    actionText,
    actionStyle,
    onClick,
    actionDisabled = false,
    style = 'default'
  }) => {
    const content = <div className="flex flex-col justify-between gap-1 p-3 sm:flex-row sm:items-center sm:gap-3">
            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-3">
              <div className="rounded-full p-1.5 sm:p-1">
                <Icon name={icon} className={classNames('flex-none', STYLE_CLASSES_ICON[style])} />
              </div>
              <div className="p-1.5 sm:p-0">
                <Body weight="medium" color="primary">
                  {title}
                </Body>
                <Body weight="normal" color="secondary">
                  {body}
                </Body>
              </div>
            </div>
            {link && <Icon name="caret_right" className="stroke-subtle hidden flex-none sm:block" />}
            {actionText && !link && <TextButton text={actionText} style={actionStyle} size="medium" onClick={onClick} disabled={actionDisabled} className="flex-none" />}
          </div>;
    return <div key={key} className={classNames('focus-within:bg-main-hover relative before:absolute before:inset-0 before:z-0 before:opacity-0 before:transition-opacity before:duration-200', link && 'cursor-pointer hover:before:opacity-100', STYLE_CLASSES_ALERT[style])}>
            {link ? <StyledLink href={link || ''} suppressFocus className="relative z-10">
                {content}
              </StyledLink> : <div className="relative z-10">{content}</div>}
          </div>;
  })}
  </div>;