import { ReactElement, ReactNode } from 'react';
import { Box } from './Box';
import { Body } from './Text';
type Props = {
  title?: string | ReactElement;
  body: ReactNode;
};
export const ModalLayout = ({
  title,
  body
}: Props) => {
  const titleElement = typeof title === 'string' ? <Body color="secondary" contents={title} /> : title || <div />;
  return <Box padding="6" data-sentry-element="Box" data-sentry-component="ModalLayout" data-sentry-source-file="ModalLayout.tsx">
      <div className="flex items-center justify-between gap-2">
        {titleElement}
      </div>
      {body}
    </Box>;
};