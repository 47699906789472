import React from 'react';
import { useCardPaymentGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { entries } from 'lodash';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
export const GroupCardPaymentDetail = () => {
  const {
    cardPaymentID
  } = useTypedParams(ROUTES.CARD_PAYMENT);
  const {
    data: cardPayment
  } = useCardPaymentGet(cardPaymentID);
  if (!cardPayment) {
    return <div>Loading...</div>;
  }
  return <PageLayout headline={cardPayment.id} data-sentry-element="PageLayout" data-sentry-component="GroupCardPaymentDetail" data-sentry-source-file="group-card-payment-detail.tsx">
      <Section header="Details" data-sentry-element="Section" data-sentry-source-file="group-card-payment-detail.tsx">
        <PropertyList items={[{
        label: 'Account',
        value: cardPayment.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: cardPayment.account_id
        })
      }, {
        label: 'Card',
        value: cardPayment.card_id,
        href: buildPath(ROUTES.CARD, {
          cardID: cardPayment.card_id
        })
      }, {
        label: 'Created at',
        value: formatISO8601Timestamp(cardPayment.created_at, 'full')
      }]} data-sentry-element="PropertyList" data-sentry-source-file="group-card-payment-detail.tsx" />
      </Section>
      <Section header="State" data-sentry-element="Section" data-sentry-source-file="group-card-payment-detail.tsx">
        <PropertyList items={entries(cardPayment.state).map(([key, amount]) => ({
        value: formatAmount(amount, 'USD'),
        label: key
      }))} data-sentry-element="PropertyList" data-sentry-source-file="group-card-payment-detail.tsx" />
      </Section>
    </PageLayout>;
};