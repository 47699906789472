import { useCallback, useState } from 'react';
import { useSessionPost } from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';
export const SignIn = () => {
  const sessionPost = useSessionPost();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const signIn = useCallback(() => {
    sessionPost.mutate([{
      email,
      password,
      one_time_password: oneTimePassword === '' ? undefined : oneTimePassword
    }]);
  }, [sessionPost, email, password, oneTimePassword]);
  return <div className="flex h-screen w-full items-center justify-center p-3" data-sentry-component="SignIn" data-sentry-source-file="sign-in.tsx">
      <div className="w-full max-w-md space-y-4 text-center">
        <>
          <h1 className="text-xl">Administration Welcome!</h1>
          <p className="text-lg">Log in to your account to continue</p>
          {sessionPost.error && <div className="text-sm text-danger">
              {sessionPost.error.response?.data.message}
            </div>}
          <form method="post" onSubmit={submitEvent => {
          submitEvent.preventDefault();
          signIn();
        }} className="space-y-2">
            <TextInput type="email" name="email" placeholder="Email address" value={email} onChange={e => setEmail(e)} data-sentry-element="TextInput" data-sentry-source-file="sign-in.tsx" />
            <TextInput type="password" name="password" placeholder="Password" value={password} onChange={e => setPassword(e)} data-sentry-element="TextInput" data-sentry-source-file="sign-in.tsx" />
            <TextInput name="oneTimePassword" placeholder="Time-based one-time password" value={oneTimePassword} onChange={e => setOneTimePassword(e)} data-sentry-element="TextInput" data-sentry-source-file="sign-in.tsx" />
            <button className="w-full rounded bg-info-strong p-1 text-inverse shadow-sm hover:opacity-80" type="submit">
              Log in
            </button>
          </form>
        </>
      </div>
    </div>;
};