import React from 'react';
import { useUserSessionList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
export const UserSessions = () => {
  const {
    data: userSessions
  } = useUserSessionList({});
  if (!userSessions) {
    return <></>;
  }
  return <PageLayout headline="User sessions" data-sentry-element="PageLayout" data-sentry-component="UserSessions" data-sentry-source-file="user-sessions.tsx">
      <ItemList items={userSessions.data.map(userSession => ({
      id: userSession.id,
      text: userSession.user_email,
      detail: userSession.created_at,
      rightText: userSession.group_id
    }))} data-sentry-element="ItemList" data-sentry-source-file="user-sessions.tsx" />
    </PageLayout>;
};