import React, { cloneElement, ReactElement, useState } from 'react';
import { Body, Caption } from './Text';
import { isString, uniqueId } from 'lodash';
import classNames from 'classnames';
import { must } from '../lib/must';
type FormRowStylingProps = {
  label?: string;
  detailRight?: React.ReactNode | string;
  field: ReactElement;
  error?: string;
  labelPlacement?: 'above' | 'adjacent';
  className?: string;
  tooltip?: React.ReactNode;
};
export const FormRowStyling = ({
  label,
  detailRight,
  field,
  error,
  labelPlacement = 'adjacent',
  className,
  tooltip
}: FormRowStylingProps) => {
  const [id] = useState(uniqueId());
  const cloned = cloneElement(field, {
    id
  });
  return <div className={classNames('grid', labelPlacement === 'adjacent' && 'grid-cols-3 items-start gap-2', labelPlacement === 'above' && 'gap-1', className)} data-sentry-component="FormRowStyling" data-sentry-source-file="FormRowStyling.tsx">
      {label && <label htmlFor={id} className={classNames(labelPlacement === 'adjacent' && 'col-span-1 flex h-[32px] items-center', labelPlacement === 'above' && 'col-span-2')}>
          {detailRight ? <div className="flex justify-between gap-1">
              <span className="flex items-center gap-1">
                <Body color="primary" contents={must(label)} weight="medium" />
                {tooltip}
              </span>
              {isString(detailRight) ? <Caption color="secondary" contents={detailRight} className="font-[420]" /> : detailRight}
            </div> : <span className="flex items-center gap-1">
              <Body color="primary" contents={label} weight="medium" />
              {tooltip}
            </span>}
        </label>}

      <div className={label ? 'col-span-2' : 'col-span-3'}>
        {cloned}
        {error && <Caption color="red" contents={error} weight="normal" className="pt-1" />}
      </div>
    </div>;
};