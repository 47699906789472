import { useAccountNumberGet, useCompletedTransactionListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { AccountNumberPropertyList } from '../property-lists/AccountNumberPropertyList';
import { Loading } from '@increase/shared/components/Loading';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
export const AccountNumberDetailPage = () => {
  const {
    accountNumberId
  } = useTypedParams(ROUTES.ACCOUNT_NUMBERS_DETAILS);
  const {
    data: accountNumber
  } = useAccountNumberGet(accountNumberId);
  const completedTransactionList = useCompletedTransactionListInfinite({
    account_numbers: [accountNumberId]
  });
  if (!accountNumber) {
    return <Loading />;
  }
  return <PageLayout headline={'Account Number'} data-sentry-element="PageLayout" data-sentry-component="AccountNumberDetailPage" data-sentry-source-file="AccountNumberDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<AccountNumberPropertyList accountNumber={accountNumber} layoutHorizontallyWhenPossible={false} title="Summary" />} left={<TableStateWrapper {...completedTransactionList} table={CompletedTransactionsTable} style="detail" title="Transactions" emptyTitle="No transactions" emptySubtitle="There are no transactions." showAccount={false} showGroup={false} showRoute={false} viewMoreHref={buildPath(ROUTES.TRANSACTIONS_LIST, {}, {
      account_number: [accountNumberId]
    })} />} data-sentry-element="SideBySide" data-sentry-source-file="AccountNumberDetailPage.tsx" />
    </PageLayout>;
};