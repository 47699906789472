import React from 'react';
import classNames from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
const INPUT_CLASSES = classNames('border-none p-0 text-center relative [background:none] text-[14px] font-medium text-subtle focus:border-none focus:shadow-none focus:ring-0');
type DateRangeProps = {
  startDate: string;
  endDate: string;
  onChange: (v: {
    startDate: string;
    endDate: string;
  }) => void;
};
export const DateRangePicker = ({
  startDate,
  endDate,
  onChange
}: DateRangeProps) => {
  return <div className="flex items-center stroke-subtle text-subtle" data-sentry-component="DateRangePicker" data-sentry-source-file="DateRangePicker.tsx">
      <input className={INPUT_CLASSES} type="date" value={startDate} onChange={e => onChange({
      startDate: e.target.value,
      endDate
    })} required />
      <ChevronRightIcon className="h-4 w-4" data-sentry-element="ChevronRightIcon" data-sentry-source-file="DateRangePicker.tsx" />
      <input className={INPUT_CLASSES} type="date" value={endDate} onChange={e => onChange({
      startDate,
      endDate: e.target.value
    })} required />
    </div>;
};