import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Body, Heading } from '@increase/shared/components/Text';
import { useSovosResponseGet } from 'src/hooks/reactQuery';
import { ROUTES } from 'src/lib/routes';
import { PageLayout } from '../deprecated/PageLayout';
import { Box } from 'shared/components/Box';
export const EntitySovosResponseDetailPage = () => {
  const {
    sovosResponseId
  } = useTypedParams(ROUTES.ENTITY_SOVOS_RESPONSE);
  const {
    data
  } = useSovosResponseGet(sovosResponseId);
  if (!data) {
    return <></>;
  }
  return <PageLayout headline={`Sovos Response`} data-sentry-element="PageLayout" data-sentry-component="EntitySovosResponseDetailPage" data-sentry-source-file="EntitySovosResponseDetailPage.tsx">
      <Heading data-sentry-element="Heading" data-sentry-source-file="EntitySovosResponseDetailPage.tsx">Raw Response</Heading>
      <Box className="border p-4" data-sentry-element="Box" data-sentry-source-file="EntitySovosResponseDetailPage.tsx">
        <Body weight="mono" data-sentry-element="Body" data-sentry-source-file="EntitySovosResponseDetailPage.tsx">{data.raw_response_body}</Body>
      </Box>
    </PageLayout>;
};