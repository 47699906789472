import classNames from 'classnames';
import { Body } from './Text';
import { isMobileSafari } from 'react-device-detect';
export type CheckboxInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  hasError?: boolean;
  label: string;
};
export const CheckboxInput = ({
  className,
  hasError,
  label,
  value,
  ...rest
}: CheckboxInputProps) => {
  return <div data-sentry-component="CheckboxInput" data-sentry-source-file="CheckboxInput.tsx">
      <label className="flex flex-row items-center gap-3">
        <input className={classNames('bg-main text-brand rounded-sm', 'border-strong ring-offset-main focus:ring-main cursor-pointer shadow-sm transition-colors focus:ring-[2px] focus:ring-offset-0', className, hasError && 'bg-main ring-danger ring-offset-main border-0 outline-0 ring-[2px] focus:ring-offset-0', hasError && isMobileSafari && 'ring-1', isMobileSafari && 'focus:ring-1')} type="checkbox" value={value ?? 'false'} checked={Boolean(value)} {...rest} />

        <Body color="primary" weight="medium" className="cursor-pointer select-none" data-sentry-element="Body" data-sentry-source-file="CheckboxInput.tsx">
          {label}
        </Body>
      </label>
    </div>;
};