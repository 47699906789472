import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { BadgeColor } from 'shared/components/Badge';
import { ControlEvaluationListResponseDataItem } from 'src/build/operations';
type Props = PrestyledTableProps<ControlEvaluationListResponseDataItem>;
const resultBadgeColors: Record<ControlEvaluationListResponseDataItem['result'], BadgeColor> = {
  success: 'green',
  failure: 'red',
  pending: 'yellow'
};
export const ControlEvaluationsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<ControlEvaluationListResponseDataItem>>(() => {
    return [{
      header: 'Status',
      contents: evaluation => ({
        text: humanize(evaluation.result),
        badgeColor: resultBadgeColors[evaluation.result]
      })
    }, {
      header: 'Failure reason',
      expand: 1,
      contents: evaluation => ({
        text: evaluation.failure_message || '-'
      })
    }, {
      header: 'Evaluated at',
      contents: evaluation => ({
        text: formatISO8601Timestamp(evaluation.created_at, 'full')
      })
    }];
  }, []);
  return <Table<ControlEvaluationListResponseDataItem> columns={columns} getRowProps={getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ControlEvaluationsTable" data-sentry-source-file="ControlEvaluationsTable.tsx" />;
};