import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { ResultEvaluationListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
type ResultsStatusTableProps = PrestyledTableProps<ResultEvaluationListResponseDataItem>;
export const ResultEvaluationsTable = ({
  ...rest
}: ResultsStatusTableProps) => {
  const columns = useMemo<TableColumns<ResultEvaluationListResponseDataItem>>(() => [{
    header: 'Created at',
    contents: result => ({
      text: formatISO8601Timestamp(result.created_at, 'full')
    }),
    mobileContents: result => ({
      text: formatISO8601Timestamp(result.created_at, 'month-day-hour-minute-second')
    })
  }, {
    header: 'Status',
    contents: result => ({
      text: result.success ? 'Succeeded' : 'Failed'
    }),
    mobileContents: result => ({
      text: result.success ? 'Succeeded' : 'Failed',
      textColor: result.success ? 'primary' : 'red',
      caption: result.duration_milliseconds == null ? undefined : Intl.NumberFormat('en-US').format(result.duration_milliseconds / 1000) + 's'
    })
  }], []);
  const getRowProps = (_datum: ResultEvaluationListResponseDataItem) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<ResultEvaluationListResponseDataItem> columns={columns} getRowProps={getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ResultEvaluationsTable" data-sentry-source-file="ResultEvaluationsTable.tsx" />;
};