import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { Outlet } from 'react-router';
export const ComplaintsWrapperPage = () => {
  const segments = [{
    slug: '/complaints/platform',
    title: 'Platform'
  }, {
    slug: '/complaints/direct',
    title: 'Direct'
  }] as const;
  const segmentIndex = location.pathname.includes('platform') ? 0 : 1;
  const cleanSegments = segments.map(({
    title,
    slug
  }, i) => ({
    title,
    href: slug,
    selected: i === segmentIndex
  }));
  return <PageLayout headline={'Complaints'} segmentedControl={<SegmentedControl segments={cleanSegments} />} data-sentry-element="PageLayout" data-sentry-component="ComplaintsWrapperPage" data-sentry-source-file="ComplaintsWrapperPage.tsx">
      <Outlet data-sentry-element="Outlet" data-sentry-source-file="ComplaintsWrapperPage.tsx" />
    </PageLayout>;
};