import React from 'react';
import { useCheckTransferPayeeNameAnalysisGet, useCheckTransferPayeeNameAnalysisReviewPost } from 'src/hooks/reactQuery';
import { InternalOperatorOnly } from './internal-operator-only';
import { PageLayout } from './deprecated/PageLayout';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { Heading } from '@increase/shared/components/Text';
import { Button } from '@increase/shared/components/Button';
import { CheckItemImageView } from './check-item-image-view';
import { Box } from '@increase/shared/components/Box';
import { StyledLink } from '@increase/shared/components/StyledLink';
export const CheckTransferPayeeNameAnalysis = () => {
  const {
    analysisID
  } = useTypedParams(ROUTES.CHECK_TRANSFER_PAYEE_NAME_ANALYSIS);
  const {
    data
  } = useCheckTransferPayeeNameAnalysisGet(analysisID);
  const mutate = useCheckTransferPayeeNameAnalysisReviewPost();
  if (!data) {
    return <span>Loading...</span>;
  }
  const reviewed = data.status !== 'pending_operator_review';
  return <PageLayout headline={`Determine match`} data-sentry-element="PageLayout" data-sentry-component="CheckTransferPayeeNameAnalysis" data-sentry-source-file="check-transfer-payee-name-analysis.tsx">
      <InternalOperatorOnly additionalRoles={['check_reviewer']} data-sentry-element="InternalOperatorOnly" data-sentry-source-file="check-transfer-payee-name-analysis.tsx">
        <Heading data-sentry-element="Heading" data-sentry-source-file="check-transfer-payee-name-analysis.tsx">{data.check_transfer_recipient_name}</Heading>

        <Box flex className="w-full" data-sentry-element="Box" data-sentry-source-file="check-transfer-payee-name-analysis.tsx">
          <Button text={`Matches ${data.check_transfer_recipient_name}`} className="flex-1" disabled={!!(mutate.isLoading || reviewed)} onClick={() => mutate.mutateAsync([analysisID, {
          outcome: 'name_match'
        }])} data-sentry-element="Button" data-sentry-source-file="check-transfer-payee-name-analysis.tsx" />

          <Button text="No match" style="warning" disabled={!!(mutate.isLoading || reviewed)} onClick={() => mutate.mutateAsync([analysisID, {
          outcome: 'name_mismatch'
        }])} data-sentry-element="Button" data-sentry-source-file="check-transfer-payee-name-analysis.tsx" />
        </Box>

        {reviewed && <StyledLink href={buildPath(ROUTES.CHECK_TRANSFER_PAYEE_NAME_LIST, {})} style="underline">
            Back to queue
          </StyledLink>}

        <CheckItemImageView checkItemID={data.check_item_id} data-sentry-element="CheckItemImageView" data-sentry-source-file="check-transfer-payee-name-analysis.tsx" />

        <PropertyList title="Debug information" items={[{
        label: 'Computed outcome',
        value: data.outcome || 'Pending'
      }, {
        label: 'Status',
        value: data.status
      }, {
        label: 'Check Transfer ID',
        value: data.check_transfer_id,
        href: buildPath(ROUTES.TRANSFER_DETAIL, {
          transferID: data.check_transfer_id
        })
      }, {
        label: 'Check Item ID',
        value: data.check_item_id
      }, {
        label: 'Computed guess',
        value: data.best_guess
      }, {
        label: 'Computed target',
        value: data.best_target
      }, {
        label: 'Score > Threshold',
        value: `${data.best_score} > ${data.best_threshold} (${data.best_algorithm})`
      }]} data-sentry-element="PropertyList" data-sentry-source-file="check-transfer-payee-name-analysis.tsx" />
      </InternalOperatorOnly>
    </PageLayout>;
};