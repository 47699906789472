import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useCustomerIdentificationProgramTestingEntryListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CustomerIdentificationProgramTestingEntryTable } from '../tables/CustomerIdentificationProgramTestingEntryTable';
import { makeBankProgramFilter, makeEntityFilter, makeModelIdFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { CustomerIdentificationProgramTestingEntryGetResponseTestingStatus } from 'src/build/operations';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const CustomerIdentificationProgramTestingEntryListPage = () => {
  const [batchIds, setBatchIds] = useSearchParamsState(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, 'batch_id');
  const [bankPrograms, setBankPrograms] = useSearchParamsState(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, 'bank_program');
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, 'status');
  const [entities, setEntities] = useSearchParamsState(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, 'entity');
  const entryList = useCustomerIdentificationProgramTestingEntryListInfinite({
    batch_ids: batchIds.length > 0 ? batchIds : undefined,
    bank_program_ids: bankPrograms.length > 0 ? bankPrograms : undefined,
    statuses: statuses.length > 0 ? statuses : undefined,
    entity_ids: entities.length > 0 ? entities : undefined
  });
  const filters = useMemo(() => [makeModelIdFilter(batchIds, setBatchIds, 'Batch Id', 'globe'), makeBankProgramFilter(bankPrograms, setBankPrograms), makeStatusFilter(statuses, setStatuses, CustomerIdentificationProgramTestingEntryGetResponseTestingStatus), makeEntityFilter(entities, setEntities)], [batchIds, setBatchIds, bankPrograms, setBankPrograms, statuses, setStatuses, entities, setEntities]);
  return <PageLayout headline={'Customer Identification Program Entries'} data-sentry-element="PageLayout" data-sentry-component="CustomerIdentificationProgramTestingEntryListPage" data-sentry-source-file="CustomerIdentificationProgramTestingEntryListPage.tsx">
      <TableStateWrapper table={CustomerIdentificationProgramTestingEntryTable} style="primary" {...entryList} emptyTitle="No entities" emptySubtitle="There are no entities to display" filters={filters} showBatch={batchIds.length !== 1} data-sentry-element="TableStateWrapper" data-sentry-source-file="CustomerIdentificationProgramTestingEntryListPage.tsx" />
    </PageLayout>;
};