import { useCustomerIdentificationProgramTestingEntryGet, useGroupGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { evaluationColors, testingStatusColors } from '../tables/CustomerIdentificationProgramTestingEntryTable';
import { Button } from '@increase/shared/components/Button';
import { useNavigate } from 'react-router';
import { compact } from 'lodash';
import { humanize } from '@increase/shared/lib/formatting';
import { InternalOperatorOnly } from '../internal-operator-only';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
export const CustomerIdentificationProgramTestingEntryDetailPage = () => {
  const {
    entryId
  } = useTypedParams(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS);
  const {
    data: entry
  } = useCustomerIdentificationProgramTestingEntryGet(entryId);
  const {
    data: group
  } = useGroupGet(entry?.entity_group_id || '', {
    enabled: !!entry
  });
  const navigate = useNavigate();
  if (!entry || !group) {
    return <Loading />;
  }
  const operatorReview = ['reviewed_without_remediations', 'reviewed_with_remediations'].includes(entry.testing_status) ? 'valid' : 'indeterminate';
  return <PageLayout headline={entry.entity_name} data-sentry-element="PageLayout" data-sentry-component="CustomerIdentificationProgramTestingEntryDetailPage" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx">
      <SideBySide mode="centered" left={<PropertyList title="Information" items={[{
      label: 'Program',
      value: entry.bank_program_name
    }, {
      label: 'Group',
      value: group.name || group.friendly_nickname,
      href: '/groups/' + entry.entity_group_id
    }, {
      label: 'Entity name',
      value: entry.entity_name
    }, {
      label: 'Entity id',
      value: entry.entity_id,
      href: buildPath(ROUTES.ENTITY, {
        entityId: entry.entity_id
      })
    }, {
      label: 'Testing status',
      value: humanize(entry.testing_status),
      badgeColor: testingStatusColors[entry.testing_status]
    }, {
      label: 'Automated evaluation',
      value: humanize(entry.original_automated_evaluation),
      badgeColor: evaluationColors[entry.original_automated_evaluation]
    }]} />} right={<div className="flex flex-col gap-3">
            {entry.testing_status !== 'pending_reviewing' && <PropertyList title="Review results" items={compact<ListItem>([entry.reviewer_email && {
        label: 'Reviewer',
        value: entry.reviewer_email
      }, {
        label: 'Their review',
        value: humanize(operatorReview),
        badgeColor: evaluationColors[operatorReview]
      }, entry.final_automated_evaluation && {
        label: 'Final automated evaluation',
        value: humanize(entry.final_automated_evaluation),
        badgeColor: evaluationColors[entry.original_automated_evaluation]
      }, entry.note && {
        label: 'Note',
        value: entry.note
      }])} />}
            <div>
              <InternalOperatorOnly>
                <Button text="Action this entity" onClick={() => navigate(`/manual_tasks/Operations::ManualTask::UpdateCustomerIdentificationProgramTestingEntry?entry_id=${entryId}`)} />
              </InternalOperatorOnly>
            </div>
          </div>} data-sentry-element="SideBySide" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
    </PageLayout>;
};