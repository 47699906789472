import { useTransactionAccountingEntriesForTraceList, useTransactionAccountingEntriesForTraceListMultiple } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
import { TransactionAccountingEntryForTraceListResponseDataItem } from 'src/build/operations';
import _ from 'lodash';
import { Body, Caption, Heading } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
import { DesktopColumn, MobileColumn, Table, TableColumns } from '@increase/shared/components/Table';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Icon } from '@increase/shared/components/Icon';
import { formatAmount } from '@increase/shared/lib/formatting';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { Button } from '@increase/shared/components/Button';
import { useCallback, useState } from 'react';
const accountSortBy = (account: string): string => {
  if (account === 'user_transactions') {
    return 'a';
  } else if (account === 'internal_source_settlement') {
    return 'b';
  } else if (account.includes('pending')) {
    return `c-${account}`;
  } else {
    return `d-${account}`;
  }
};
const accountsFor = (entries: TransactionAccountingEntryForTraceListResponseDataItem[]) => {
  return _.sortBy(_.uniq(_.flatMap(entries, entry => [entry.credit_account, entry.debit_account])), accountSortBy);
};
const formatSignedAmount = (amount: number): string => {
  if (amount < 0) {
    return `(${formatAmount(amount * -1, 'USD')})`;
  } else {
    return formatAmount(amount, 'USD');
  }
};
type Row = {
  id: string;
  trace_id: string;
  first_item_for_trace: boolean;
  source_id: string;
  source_type: string;
  date: string;
  bank: string;
  transaction_source_type: string | null;
  cells: string[];
};
const rowsFor = (traceID: string, entries: TransactionAccountingEntryForTraceListResponseDataItem[], allAccounts: string[]): Row[] => {
  return entries.map((entry, i) => {
    const cells = allAccounts.map(account => {
      if (entry.credit_account === entry.debit_account) {
        return formatSignedAmount(0);
      } else if (entry.credit_account === account) {
        return formatSignedAmount(entry.amount * -1);
      } else if (entry.debit_account === account) {
        return formatSignedAmount(entry.amount);
      } else {
        return '';
      }
    });
    return {
      id: entry.id,
      trace_id: traceID,
      first_item_for_trace: i === 0,
      source_id: entry.source_id,
      source_type: entry.source_type,
      date: entry.date,
      bank: entry.bank,
      transaction_source_type: entry.transaction_source_type,
      cells
    };
  });
};
const totalsRow = (traceID: string, entries: TransactionAccountingEntryForTraceListResponseDataItem[], allAccounts: string[]): Row => {
  return {
    id: 'totals-' + traceID,
    trace_id: traceID,
    first_item_for_trace: false,
    source_id: '',
    source_type: '',
    date: '',
    bank: '',
    transaction_source_type: null,
    cells: allAccounts.map(account => formatSignedAmount(_.sum(entries.map(entry => {
      if (entry.credit_account === entry.debit_account) {
        return 0;
      } else if (entry.credit_account === account) {
        return entry.amount * -1;
      } else if (entry.debit_account === account) {
        return entry.amount;
      } else {
        return 0;
      }
    }))))
  };
};
const SummaryCell = ({
  datum: row
}: {
  datum: Row;
}) => {
  if (row.id.startsWith('totals-')) {
    return null;
  }
  return <Box gap="2" data-sentry-element="Box" data-sentry-component="SummaryCell" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
      <Box gap="0" data-sentry-element="Box" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
        <Body color="emphasis" weight="medium" data-sentry-element="Body" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
          {row.source_type}{' '}
          {row.transaction_source_type && <>({row.transaction_source_type})</>}
        </Body>
        <Body data-sentry-element="Body" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
          <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: row.source_id
        })} data-sentry-element="StyledLink" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
            {row.source_id}
          </StyledLink>
        </Body>
      </Box>

      <Caption data-sentry-element="Caption" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
        <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
        objectID: row.id
      })} data-sentry-element="StyledLink" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
          {row.id}
        </StyledLink>{' '}
        — {row.bank}
      </Caption>
    </Box>;
};
const renderHeaderForRow = (primaryTraceID: string, onRemoveLinkedTraceID: (linkedTraceID: string) => void) => {
  // eslint-disable-next-line react/display-name
  return (row: Row) => {
    if (row.id === 'totals-combined') {
      return <div className="mt-10 flex h-10 items-center space-x-1 bg-info pl-2">
          <Icon name="plus_circle" className="-ml-1 text-strong" />
          <Body color="emphasis" weight="medium">
            Combined Totals
          </Body>
        </div>;
    }
    if (row.id.startsWith('totals-')) {
      return <div className="flex h-10 items-center space-x-1 bg-info-subtle pl-2">
          <Icon name="plus_circle" className="-ml-1 text-strong" />
          <Body color="emphasis" weight="medium">
            Totals
          </Body>
        </div>;
    }
    if (row.trace_id !== primaryTraceID && row.first_item_for_trace) {
      return <div className="mt-10 flex h-10 items-center space-x-1 bg-warning-subtle pl-2">
          <Icon name="link" className="-ml-1 text-strong" />
          <Body color="emphasis" weight="medium">
            Linked Trace ID:{' '}
            <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
            objectID: row.trace_id
          })}>
              {row.trace_id}
            </StyledLink>
          </Body>

          <div className="ml-auto">
            <Button icon="close" style="transparent" size="small" onClick={() => onRemoveLinkedTraceID(row.trace_id)} />
          </div>
        </div>;
    }
  };
};
const AddLinkedTraceIDInput = ({
  onSubmit
}: {
  onSubmit: (linkedTraceID: string) => void;
}) => {
  const [addLinkedTraceIDInput, setAddLinkedTraceIDInput] = useState('');
  const submit = useCallback(() => {
    onSubmit(addLinkedTraceIDInput);
    setAddLinkedTraceIDInput('');
  }, [onSubmit, addLinkedTraceIDInput, setAddLinkedTraceIDInput]);
  return <div data-sentry-component="AddLinkedTraceIDInput" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
      <input className="rounded border border-stronger px-1 py-0.5 text-sm" name="value" placeholder="trace id" value={addLinkedTraceIDInput} onChange={e => setAddLinkedTraceIDInput(e.target.value)} />
      <Button icon="link" text="Include another Trace ID" size="small" onClick={submit} data-sentry-element="Button" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx" />
    </div>;
};
export const TransactionAccountingEntriesForTrace = () => {
  const {
    traceID
  } = useTypedParams(ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE);
  const [linkedTraceIDsParam, setLinkedTraceIDSParam] = useSearchParamsState(ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE, 'linkedTraceID');
  const linkedTraceIDs = _.uniq(_.compact(linkedTraceIDsParam));
  const {
    data: response
  } = useTransactionAccountingEntriesForTraceList({
    trace_id: traceID
  });
  const addLinkedTraceID = useCallback((linkedTraceID: string) => {
    setLinkedTraceIDSParam([...linkedTraceIDs, linkedTraceID]);
  }, [setLinkedTraceIDSParam, linkedTraceIDs]);
  const removeLinkedTraceID = useCallback((linkedTraceID: string) => {
    setLinkedTraceIDSParam(_.without(linkedTraceIDs, linkedTraceID));
  }, [setLinkedTraceIDSParam, linkedTraceIDs]);
  const linkedTraceIDQueries = useTransactionAccountingEntriesForTraceListMultiple(linkedTraceIDs.map(id => {
    return {
      trace_id: id
    };
  }));
  const linkedTracesLoaded = linkedTraceIDQueries.every(q => q.data);
  if (!response || !linkedTracesLoaded) {
    return <h3>Loading</h3>;
  }
  let allEntries = response.data;
  linkedTraceIDQueries.forEach(q => {
    if (q.data) {
      allEntries = allEntries.concat(q.data.data);
    }
  });
  const accounts = accountsFor(allEntries);
  const columns: TableColumns<Row> = [{
    header: 'Date',
    contents: e => ({
      text: e.date,
      textWeight: 'bold',
      textColor: 'emphasis'
    })
  }, {
    header: 'Event',
    CellComponent: SummaryCell
  }, ...accounts.map((account, i): DesktopColumn<Row> & MobileColumn<Row> => {
    return {
      header: account,
      contents: e => ({
        text: e.cells[i]
      })
    };
  }), false];
  let rows = rowsFor(traceID, response.data, accounts);
  rows.push(totalsRow(traceID, response.data, accounts));
  _.zip(linkedTraceIDs, linkedTraceIDQueries).forEach(([linkedTraceID, query]) => {
    if (query?.data && linkedTraceID) {
      rows = rows.concat(rowsFor(linkedTraceID, query.data.data, accounts));
      rows.push(totalsRow(linkedTraceID, query.data.data, accounts));
    }
  });
  if (linkedTraceIDs.length > 0) {
    rows.push(totalsRow('combined', allEntries, accounts));
  }
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="TransactionAccountingEntriesForTrace" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
      <PageLayout headline={'Transaction Accounting Entries'} data-sentry-element="PageLayout" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
          <Box flex={true} data-sentry-element="Box" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
            <Heading data-sentry-element="Heading" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
              Trace ID:{' '}
              <StyledLink style="underline" href={buildPath(ROUTES.OBJECT_VIEWER, {
              objectID: traceID
            })} data-sentry-element="StyledLink" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx">
                {traceID}
              </StyledLink>
            </Heading>

            <div className="ml-auto">
              <AddLinkedTraceIDInput onSubmit={addLinkedTraceID} data-sentry-element="AddLinkedTraceIDInput" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx" />
            </div>
          </Box>

          <Table columns={columns} data={rows} renderHeaderForRow={renderHeaderForRow(traceID, removeLinkedTraceID)} data-sentry-element="Table" data-sentry-source-file="TransactionAccountingEntriesForTrace.tsx" />
        </Box>
      </PageLayout>
    </InternalOperatorOnly>;
};