import { useState } from 'react';
import { applyThreshold } from '../../lib/image-utility';
import { ImageDataView } from './image-data-view';
import { useKeyPressEvent } from 'react-use';
type Props = {
  pixels: ImageData;
  initialThreshold: number;
  onLevelUpdated: (imageData: ImageData, level: number) => void;
};
export const ImageThreshold = ({
  pixels,
  onLevelUpdated,
  initialThreshold
}: Props) => {
  const [level, setLevel] = useState(initialThreshold);
  const thresholdedPixels = applyThreshold(pixels, level);
  useKeyPressEvent('Enter', () => {
    onLevelUpdated(thresholdedPixels, level);
  });
  useKeyPressEvent('ArrowUp', e => {
    setLevel(level + (e.shiftKey ? 1 : 10));
  });
  useKeyPressEvent('ArrowDown', e => {
    setLevel(level - (e.shiftKey ? 1 : 10));
  });
  return <div data-sentry-component="ImageThreshold" data-sentry-source-file="image-threshold.tsx">
      <div>Adjust levels with the up and down keys</div>
      <div>Level: {level}</div>
      <ImageDataView pixels={thresholdedPixels} data-sentry-element="ImageDataView" data-sentry-source-file="image-threshold.tsx" />
    </div>;
};