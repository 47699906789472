import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Body } from '@increase/shared/components/Text';
import { formatAddress, humanize } from '@increase/shared/lib/formatting';
import { EntityGetResponseSetupSubmissionStructureNaturalPerson, EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus, EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus } from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
type EntityPropertyListProps = {
  naturalPerson: NonNullable<EntityGetResponseSetupSubmissionStructureNaturalPerson>;
  entityId?: string | null;
} & Omit<PropertyListProps, 'items'>;
const identityVerificationStatusBadgeColor: Record<EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus, BadgeColor> = {
  pending_verification: 'blue',
  update_pending_verification: 'blue',
  verified: 'green',
  falsified: 'yellow',
  no_match: 'yellow',
  verifying: 'blue',
  requires_attention: 'yellow',
  not_performed: 'gray'
};
const taxIdValidationStatusBadgeColor: Record<EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus, BadgeColor> = {
  pending_validating: 'blue',
  update_pending_validating: 'blue',
  requires_attention: 'yellow',
  failed: 'yellow',
  validated: 'green',
  not_provided: 'gray',
  skipped: 'gray'
};
export const EntityNaturalPersonPropertyList = (props: EntityPropertyListProps) => {
  const {
    naturalPerson,
    ...rest
  } = props;
  const categoricals = useCategoricals();
  if (!categoricals) {
    return null;
  }
  return <PropertyList {...rest} action={<InternalOperatorOnly>
          <StyledLink style="underline" href={buildPath(ROUTES.ENTITY_ADDRESS_VERIFICATION, {
      addressableId: naturalPerson.id
    })}>
            <Body>Address Verification</Body>
          </StyledLink>
        </InternalOperatorOnly>} items={compact<ListItem>([{
    label: 'Name',
    value: naturalPerson.submitted_name
  }, {
    label: 'Date of Birth',
    value: naturalPerson.submitted_date_of_birth
  }, {
    label: 'Address',
    value: formatAddress(naturalPerson.submitted_address)
  }, {
    label: 'Verification Status',
    value: humanize(naturalPerson.identity_verification_status),
    badgeColor: identityVerificationStatusBadgeColor[naturalPerson.identity_verification_status]
  }, {
    label: 'Identification Number Kind',
    value: humanize(naturalPerson.submitted_identification_method)
  }, {
    label: 'Identification Number Last 4',
    value: naturalPerson.submitted_identification_number_last4
  }, {
    label: 'Sovos SSN Validation Return Code',
    value: humanize(naturalPerson.tax_id_validation_status),
    badgeColor: taxIdValidationStatusBadgeColor[naturalPerson.tax_id_validation_status]
  }, {
    label: 'Sovos Requested At',
    value: naturalPerson.identity_submitted_at
  }, naturalPerson.submission_sovos_response_id && {
    label: 'Sovos Raw Response',
    href: buildPath(ROUTES.ENTITY_SOVOS_RESPONSE, {
      sovosResponseId: naturalPerson.submission_sovos_response_id
    }),
    value: 'View'
  }, props.entityId && {
    label: 'Entity ID',
    value: props.entityId,
    href: buildPath(ROUTES.ENTITY, {
      entityId: props.entityId
    })
  }])} data-sentry-element="PropertyList" data-sentry-component="EntityNaturalPersonPropertyList" data-sentry-source-file="EntityNaturalPersonPropertyList.tsx" />;
};