import React, { useState } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useSanctionsScreeningTriggerWordsListInfinite, useSanctionsScreeningTriggerWordsPost } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { SanctionsScreeningTriggerWordsTable } from '../tables/SanctionsScreeningTriggerWordsTable';
import { Button } from '@increase/shared/components/Button';
import { useDrawer } from '@increase/shared/components/Modal';
import { DrawerLayout } from '@increase/shared/components/DrawerLayout';
import { Box } from '@increase/shared/components/Box';
import { Body } from '@increase/shared/components/Text';
import { TextInput } from '@increase/shared/components/TextInput';
const Drawer = (props: {
  onClose: () => void;
}) => {
  const mutate = useSanctionsScreeningTriggerWordsPost();
  const [token, setToken] = useState('');
  return <DrawerLayout title="Create new word or phrase" body={<Box>
          <Body color="secondary">
            Adding a word or phrase to the OFAC triggering words list will cause
            future screening to stop on any transaction that contains the word
            or phrase. Capitalization does not matter.
          </Body>

          <TextInput value={token} onChange={e => setToken(e.target.value)} />
        </Box>} rightButton={<Button icon="create" text="Save" onClick={async () => {
    await mutate.mutateAsync([{
      token
    }]);
    props.onClose();
  }} style="warning" disabled={mutate.isLoading || token.length === 0} />} data-sentry-element="DrawerLayout" data-sentry-component="Drawer" data-sentry-source-file="SanctionsScreeningTriggerWordsListPage.tsx" />;
};
export const SanctionsScreeningTriggerWordsListPage = () => {
  const listResult = useSanctionsScreeningTriggerWordsListInfinite({});
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  return <PageLayout headline={'OFAC Triggering Words'} data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningTriggerWordsListPage" data-sentry-source-file="SanctionsScreeningTriggerWordsListPage.tsx">
      <TableStateWrapper action={<Button style="primary" text="Create" icon="edit" onClick={() => showDrawer(<Drawer onClose={closeDrawer} />)} />} table={SanctionsScreeningTriggerWordsTable} style="primary" {...listResult} emptyTitle="No OFAC triggering words" emptySubtitle="Configure some words!" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningTriggerWordsListPage.tsx" />
    </PageLayout>;
};