import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { BookkeepingAccountListResponseDataItem, BookkeepingAccountListResponseDataItemComplianceCategory } from 'src/build/operations';
import { BadgeColor } from '@increase/shared/components/Badge';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<BookkeepingAccountListResponseDataItem>;
export const complianceCategoriesBadgeColors: Record<NonNullable<BookkeepingAccountListResponseDataItemComplianceCategory>, BadgeColor> = {
  commingled_cash: 'blue',
  customer_balance: 'green'
};
export const BookkeepingAccountsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BookkeepingAccountListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      iconType: 'account',
      expand: 1,
      contents: account => ({
        text: account.name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    } as const, {
      header: 'Entity',
      expand: 1,
      contents: account => ({
        text: account.entity_name ?? '',
        href: account.entity_id ? buildPath(ROUTES.ENTITY, {
          entityId: account.entity_id
        }) : undefined
      })
    } as const, {
      header: 'Cash Account',
      expand: 1,
      contents: account => ({
        text: account.account_name ?? '',
        href: account.account_id ? buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: account.account_id
        }) : undefined
      })
    } as const, {
      header: 'Compliance Category',
      contents: account => ({
        text: account.compliance_category ? humanize(account.compliance_category) : '',
        badgeColor: account.compliance_category ? complianceCategoriesBadgeColors[account.compliance_category] : undefined
      })
    }, {
      header: 'Cached Balance',
      align: 'right',
      contents: account => ({
        text: formatAmount(account.balance, 'USD'),
        textWeight: 'bold',
        textColor: 'emphasis'
      })
    }];
  }, []);
  const defaultRowProps = (datum: BookkeepingAccountListResponseDataItem) => ({
    href: buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL, {
      bookkeepingAccountID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<BookkeepingAccountListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="BookkeepingAccountsTable" data-sentry-source-file="BookkeepingAccountsTable.tsx" />;
};