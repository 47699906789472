import React from 'react';
import { useCheckDepositGet, useInboundCheckGet, useTransactionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { useServices } from 'src/hooks/use-services';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { TransactionPropertyList } from './property-lists/TransactionPropertyList';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { Note } from '@increase/shared/components/Note';
import { Body } from '@increase/shared/components/Text';
import { Icon } from '@increase/shared/components/Icon';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { OperatorNotes } from './operator-notes';
import { Loading } from '@increase/shared/components/Loading';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { extractSourceFields } from 'src/lib/sourceHelpers';
import { isSubHashAnyOf } from '@increase/shared/lib/typeHelpers';
type CheckDepositProps = {
  checkDepositID: string;
};
const CheckDeposit = ({
  checkDepositID
}: CheckDepositProps) => {
  const {
    data
  } = useCheckDepositGet(checkDepositID);
  const {
    operations
  } = useServices();
  if (!data) {
    return <Loading />;
  }
  const {
    front_image_file_id: frontImage,
    back_image_file_id: backImage
  } = data;
  return data ? <PropertyList title="Check Deposit details" items={compact<ListItem>([{
    label: 'Front Image',
    value: frontImage,
    href: operations + '/api_files/' + frontImage + '/view'
  }, backImage && {
    label: 'Back Image',
    value: backImage,
    href: operations + '/api_files/' + backImage + '/view'
  }])} data-sentry-element="PropertyList" data-sentry-component="CheckDeposit" data-sentry-source-file="transaction-detail.tsx" /> : <div data-sentry-component="CheckDeposit" data-sentry-source-file="transaction-detail.tsx" />;
};
type InboundCheckDetailsProps = {
  inboundCheckID: string;
};
const InboundCheckDetails = ({
  inboundCheckID
}: InboundCheckDetailsProps) => {
  const {
    data
  } = useInboundCheckGet(inboundCheckID);
  const {
    operations
  } = useServices();
  return data ? <PropertyList title="Inbound Check details" items={data.images.map(({
    api_file_id,
    view_side
  }) => ({
    label: `${humanize(view_side)} image`,
    value: api_file_id,
    href: operations + '/api_files/' + api_file_id + '/view'
  }))} data-sentry-element="PropertyList" data-sentry-component="InboundCheckDetails" data-sentry-source-file="transaction-detail.tsx" /> : <div data-sentry-component="InboundCheckDetails" data-sentry-source-file="transaction-detail.tsx" />;
};
export const TransactionDetail = () => {
  const {
    transactionID
  } = useTypedParams(ROUTES.TRANSACTION_DETAIL);
  const {
    data: transaction
  } = useTransactionGet(transactionID);
  const {
    operations
  } = useServices();
  if (!transaction) {
    return <>Loading...</>;
  }
  const source = transaction.source as unknown as Record<string, string> | undefined;
  const details = source && isSubHashAnyOf(source as unknown as {
    [x: string]: Record<string, string>;
  } & {
    category: string;
  }) ? source[source.category] as unknown as Record<string, string> : source;
  return <PageLayout id="application.transactionDetails" headline={`${transaction.source_type} • ${formatAmount(transaction.amount, transaction.currency)}`} data-sentry-element="PageLayout" data-sentry-component="TransactionDetail" data-sentry-source-file="transaction-detail.tsx">
      <SideBySide right={<TransactionPropertyList title="Basics" transaction={transaction} layoutHorizontallyWhenPossible={false} />} left={<>
            {transaction.source_documentation && <Note>
                <Box gap="2">
                  <Box flex center>
                    <Icon name="info" />
                    <Body weight="bold">{transaction.source_type}</Body>
                  </Box>

                  <Body>{transaction.source_documentation}</Body>
                </Box>
              </Note>}

            {source && <PropertyList title="Details" items={compact<ListItem>([{
        label: 'Description',
        value: transaction.description
      }]).concat(extractSourceFields({
        object: details || {},
        operations
      }))} />}

            {transaction.source_type === 'API::CheckDepositAcceptance' && source && <CheckDeposit checkDepositID={source.check_deposit_id} />}
            {transaction.source_type === 'API::InboundCheck' && <InboundCheckDetails inboundCheckID={transaction.source_id} />}

            <OperatorNotes modelId={transactionID} />
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="transaction-detail.tsx" />
    </PageLayout>;
};